import style from './tradedepartment.module.scss';
import { Person } from '../../components';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import KaanImage from '../../assets/img/main/kaan.png';
import PartnerData from './PartnerData';
import LightData from './LightData';
import HomeData from './HomeData';
import OfficeData from './OfficeData';
import { useState } from 'react';
import { ReceptionData, DecorationData } from './PublicSpaceData';
import { MassageRoomData, WellnessData, FitnessData } from './SpaData';
import {
  RestaurantBarsData,
  OpenBuffetData,
  RestaurantLoungeData,
  TableData,
  RestaurantChairData,
} from './RestaurantBarData';
import {
  HouseKeepingData,
  LuggageData,
  BanquetData,
  KitchenData,
} from './BackofTheHouseData';
import {
  DrywallData,
  FlooringWallData,
  DoorsFurnituresData,
  WindowData,
} from './ConstructionMaterialData';
import {
  FurnitureFabricsData,
  LinensData,
  CarpetData,
  CurtainsData,
} from './TextileData';
import {
  HotelStorageData,
  HotelTablesChairsData,
  HotelBedData,
  HotelEquipmentsData,
  BathroomData,
} from './HotelRoomsData';
import { useSpring, animated } from 'react-spring';

import CarpetBottomImage from '../../assets/img/trade-department/products/textile/carpets/carpet-bottom-image.png';

import HotelBedBottomImage from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/bed-bottom-image.png';

// PRODUCTS
import FurnitureCover from '../../assets/img/trade-department/products/cover/furniture-cover.png';
import LightsCover from '../../assets/img/trade-department/products/cover/lights-cover.png';
import ConstructionMaterialsCover from '../../assets/img/trade-department/products/cover/construction-materials-cover.png';
import TextileCover from '../../assets/img/trade-department/products/cover/textile-cover.png';

// CONTRACT
import HotelCover from '../../assets/img/trade-department/products/contract/cover/hotel-cover.png';
import RestaurantBarCover from '../../assets/img/trade-department/products/contract/cover/restaurant-cafe-cover.png';
import SpaCenterCover from '../../assets/img/trade-department/products/contract/cover/spa-center-cover.png';
import BanquetCover from '../../assets/img/trade-department/products/contract/cover/banquet-cover.png';

// HOME
import SofasCover from '../../assets/img/trade-department/products/furniture/home/cover/sofas-cover.png';
import TablesChairsCover from '../../assets/img/trade-department/products/furniture/home/cover/tables-chairs-cover.png';
import BedsCover from '../../assets/img/trade-department/products/furniture/home/cover/beds-cover.png';
import StoragesCover from '../../assets/img/trade-department/products/furniture/home/cover/storages-cover.png';
import KitchenCover from '../../assets/img/trade-department/products/furniture/home/cover/kitchen-cover.png';
import OutdoorCover from '../../assets/img/trade-department/products/furniture/home/cover/outdoor-cover.png';

// OFFICE
import TablesCover from '../../assets/img/trade-department/products/furniture/office/cover/tables-cover.png';
import ChairsCover from '../../assets/img/trade-department/products/furniture/office/cover/chairs-cover.png';
import StorageCover from '../../assets/img/trade-department/products/furniture/office/cover/storage-cover.png';
import WaitingZoneCover from '../../assets/img/trade-department/products/furniture/office/cover/waiting-zone-cover.png';

// PUBLIC SPACE
import ReceptionCover from '../../assets/img/trade-department/products/contract/hotel/public-space/cover/reception-cover.png';
import LoungeCover from '../../assets/img/trade-department/products/contract/hotel/public-space/cover/lounge-cover.png';
import DecorationCover from '../../assets/img/trade-department/products/contract/hotel/public-space/cover/decoration-cover.png';

// SPA CENTER
import FitnessCover from '../../assets/img/trade-department/products/contract/spa-center/cover/fitness-cover.png';
import MassageRoomCover from '../../assets/img/trade-department/products/contract/spa-center/cover/massage-room-cover.png';
import WellnessRoomCover from '../../assets/img/trade-department/products/contract/spa-center/cover/wellness-room-cover.png';

// RESTAURANT-BAR
import RestaurantTableCover from '../../assets/img/trade-department/products/contract/restaurant-bar/cover/tables-cover.png';
import RestaurantTChairsCover from '../../assets/img/trade-department/products/contract/restaurant-bar/cover/chairs-cover.png';
import RestaurantLoungeCover from '../../assets/img/trade-department/products/contract/restaurant-bar/cover/lounge-cover.png';
import RestaurantOpenBuffetCover from '../../assets/img/trade-department/products/contract/restaurant-bar/cover/open-buffet-cover.png';
import RestaurantBarsCover from '../../assets/img/trade-department/products/contract/restaurant-bar/cover/bars-cover.png';

// BACK OF THE HOUSE
import HouseKeepingCover from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/cover/house-keeping-cover.png';
import BackofthehouseBanquetCover from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/cover/banquet-cover.png';
import LuggageCover from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/cover/luggage-cover.png';
import BackofthehouseKitchenCover from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/cover/kitchen-cover.png';

// HOTEL ROOMS
import RoomsBed from '../../assets/img/trade-department/products/contract/hotel/rooms/cover/beds-cover.png';
import RoomsTables from '../../assets/img/trade-department/products/contract/hotel/rooms/cover/tables-chairs-cover.png';
import RoomsStorages from '../../assets/img/trade-department/products/contract/hotel/rooms/cover/storages-cover.png';
import RoomsBathrooms from '../../assets/img/trade-department/products/contract/hotel/rooms/cover/bathrooms-cover.png';
import RoomsEquipments from '../../assets/img/trade-department/products/contract/hotel/rooms/cover/equipments-cover.png';

const Partners = () => {
  return (
    <div className={style.partners}>
      {PartnerData.map(i => {
        return <img src={i} alt="partner" />;
      })}
    </div>
  );
};

const Light = () => {
  return (
    <div className={style.light}>
      <h1>LIGHTS</h1>
      <div>
        {LightData.map(i => {
          return <img src={i} alt="light" />;
        })}
      </div>
    </div>
  );
};

const Products = () => {
  const [isOpen, setOpen] = useState([]);

  const furniture = [
    {
      title: 'Contract',
      link: 'contract',
    },
    {
      title: 'Home',
      link: 'home',
    },
    {
      title: 'Office',
      link: 'office',
    },
  ];

  const materials = [
    {
      title: 'Drywall Products',
      link: 'drywall-products',
    },
    {
      title: 'Window Facade',
      link: 'window-facade',
    },
    {
      title: 'Flooring & Wall',
      link: 'flooring-wall',
    },
    {
      title: 'Doors & Furnitures',
      link: 'doors-furnitures',
    },
  ];

  const textile = [
    {
      title: 'Curtains',
      link: 'curtains',
    },
    {
      title: 'Furniture Fabrics',
      link: 'furniture-fabrics',
    },
    {
      title: 'Linens',
      link: 'linens',
    },
    {
      title: 'Carpets',
      link: 'carpets',
    },
  ];

  return (
    <div className={style.products}>
      <h1>PRODUCTS</h1>
      <div className={style.cardWrapper}>
        <button
          onClick={() => {
            if (isOpen.includes('furniture')) {
              setOpen(isOpen.filter(i => i !== 'furniture'));
            } else {
              setOpen([...isOpen, 'furniture']);
            }
          }}
        >
          <h3>FURNITURE</h3>
          <img src={FurnitureCover} alt="furniture-cover" />
          {isOpen.includes('furniture') ? (
            <div className={`${style.buttonWrapper} ${style.horizontal}`}>
              {furniture.map(i => (
                <a href={`/trade-department/furniture/${i.link}`}>{i.title}</a>
              ))}
            </div>
          ) : null}
        </button>
        <a href={'/trade-department/products/lights'}>
          <h3>LIGHTS</h3>
          <img src={LightsCover} alt="furniture-cover" />
        </a>
        <button
          onClick={() => {
            if (isOpen.includes('material')) {
              setOpen(isOpen.filter(i => i !== 'material'));
            } else {
              setOpen([...isOpen, 'material']);
            }
          }}
        >
          <h3>CONSTRUCTION MATERIALS</h3>
          <img src={ConstructionMaterialsCover} alt="furniture-cover" />
          {isOpen.includes('material') ? (
            <div className={`${style.buttonWrapper} `}>
              {materials.map(i => (
                <a href={`/trade-department/material/${i.link}`}>{i.title}</a>
              ))}
            </div>
          ) : null}
        </button>
        <button
          onClick={() => {
            if (isOpen.includes('textile')) {
              setOpen(isOpen.filter(i => i !== 'textile'));
            } else {
              setOpen([...isOpen, 'textile']);
            }
          }}
        >
          <h3>TEXTILE</h3>
          <img src={TextileCover} alt="furniture-cover" />
          {isOpen.includes('textile') ? (
            <div className={`${style.buttonWrapper} `}>
              {textile.map(i => (
                <a href={`/trade-department/textile/${i.link}`}>{i.title}</a>
              ))}
            </div>
          ) : null}
        </button>
      </div>
      <h4>Everything you can think of is here</h4>
    </div>
  );
};

const Contract = () => {
  const [isOpen, setOpen] = useState([]);

  const furniture = [
    {
      title: 'Rooms',
      link: 'rooms',
    },
    {
      title: 'Public Space',
      link: 'public-space',
    },
    {
      title: 'Back of the House',
      link: 'back-of-the-house',
    },
  ];

  return (
    <div className={style.contract}>
      <h1>CONTRACT</h1>
      <div className={style.cardWrapper}>
        <button
          onClick={() => {
            if (isOpen.includes('furniture')) {
              setOpen(isOpen.filter(i => i !== 'furniture'));
            } else {
              setOpen([...isOpen, 'furniture']);
            }
          }}
        >
          <h3>Hotel</h3>
          <img src={HotelCover} alt="furniture-cover" />
          {isOpen.includes('furniture') ? (
            <div className={`${style.buttonWrapper} ${style.horizontal}`}>
              {furniture.map(i => (
                <a href={`/trade-department/furniture/contract/${i.link}`}>
                  {i.title}
                </a>
              ))}
            </div>
          ) : null}
        </button>
        <a href="/trade-department/furniture/contract/restaurant-bar">
          <h3>Restaurant & Bar</h3>
          <img src={RestaurantBarCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/spa-center">
          <h3>Spa Center</h3>
          <img src={SpaCenterCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/back-of-the-house/banquet">
          <h3>Banquet</h3>
          <img src={BanquetCover} alt="furniture-cover" />
        </a>
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <div className={style.home}>
      <h1>HOME</h1>
      <div className={style.cardWrapper}>
        <a href="/trade-department/furniture/home/sofas">
          <h3>Sofas</h3>
          <img src={SofasCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/rooms/tables-chairs">
          <h3>Tables & Chairs</h3>
          <img src={TablesChairsCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/rooms/beds">
          <h3>Beds</h3>
          <img src={BedsCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/home/special-storage-design">
          <h3>Storages</h3>
          <img src={StoragesCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/home/special-kitchen-design">
          <h3>Kitchen</h3>
          <img src={KitchenCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/home/outdoor">
          <h3>Outdoor</h3>
          <img src={OutdoorCover} alt="furniture-cover" />
        </a>
      </div>
    </div>
  );
};

const HomeDetail = ({ data }) => {
  return (
    <div
      className={`${style.homeDetail} ${
        data.width === 'wide' ? style.wide : null
      }`}
    >
      <h1>{data.title}</h1>
      <div>
        {data.pictures.map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
    </div>
  );
};

const Office = () => {
  return (
    <div className={style.office}>
      <h1>OFFICE</h1>
      <div className={style.cardWrapper}>
        <a href="/trade-department/furniture/office/tables">
          <h3>Tables</h3>
          <img src={TablesCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/office/chairs">
          <h3>Chairs</h3>
          <img src={ChairsCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/office/storage">
          <h3>Storage</h3>
          <img src={StorageCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/office/waiting-zone">
          <h3>Waiting Zone</h3>
          <img src={WaitingZoneCover} alt="furniture-cover" />
        </a>
      </div>
    </div>
  );
};

const OfficeDetail = ({ data }) => {
  return (
    <>
      {data.title === 'multiple' ? (
        <div className={style.multiple}>
          {data.data.map(i => {
            return (
              <div
                className={`${style.officeDetail} ${
                  data.width === 'wide' ? style.wide : null
                } `}
              >
                <h1>
                  <div className={style.leftLine}></div>
                  <span>{i.title}</span>
                  <div className={style.rightLine}></div>
                </h1>
                <div>
                  {i.pictures.map(i => (
                    <img src={i} alt="home" />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className={`${style.officeDetail} ${
            data.width === 'wide' ? style.wide : null
          }`}
        >
          <h1>
            <div className={style.leftLine}></div>
            <span>{data.title}</span>
            <div className={style.rightLine}></div>
          </h1>
          <div>
            {data.pictures.map(i => (
              <img src={i} alt="home" />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const PublicSpace = () => {
  return (
    <div className={style.publicSpace}>
      <h1>PUBLIC SPACE</h1>
      <div className={style.cardWrapper}>
        <a href="/trade-department/furniture/contract/public-space/reception">
          <h3>Reception</h3>
          <img src={ReceptionCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/restaurant-bar/lounge">
          <h3>Lounge</h3>
          <img src={LoungeCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/public-space/decoration">
          <h3>Decorations</h3>
          <img src={DecorationCover} alt="furniture-cover" />
        </a>
      </div>
    </div>
  );
};

const ReceptionDetail = ({ data }) => {
  return (
    <div className={`${style.publicSpaceDetail}`}>
      <h1>{ReceptionData().title}</h1>
      <p>{ReceptionData().info}</p>
      <div>
        {ReceptionData().pictures.map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
    </div>
  );
};

const DecorationDetail = ({ data }) => {
  return (
    <div className={`${style.decorationDetail}`}>
      <h1>{'DECORATION'}</h1>
      <div className={style.top}>
        {DecorationData().top.map(i => {
          return (
            <div className={style.card}>
              <img src={i.cover} alt="decoration-cover" />
              <div>
                <h2>{i.title}</h2>
                <p>
                  {i.text.map(text => {
                    return (
                      <>
                        {text}
                        <br />
                        <br />
                      </>
                    );
                  })}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className={style.bottom}>
        {DecorationData().bottom.map(i => {
          return (
            <div className={style.card}>
              <img src={i.cover} alt="decoration-cover" />
              <p>{i.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SpaCenter = () => {
  return (
    <div className={style.spaCenter}>
      <h1>SPA CENTER</h1>
      <div className={style.cardWrapper}>
        <a href="/trade-department/furniture/contract/spa-center/fitness">
          <h3>Fitness</h3>
          <img src={FitnessCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/spa-center/massage-room">
          <h3>Massage Room & Resting Area</h3>
          <img src={MassageRoomCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/spa-center/wellness-room">
          <h3>Wellness Room</h3>
          <img src={WellnessRoomCover} alt="furniture-cover" />
        </a>
      </div>
    </div>
  );
};

const MassageRoomDetail = () => {
  return (
    <div className={`${style.massageRoomDetail}`}>
      <h1>{'MASSAGE ROOM & RESTING AREA'}</h1>
      <div>
        {MassageRoomData().map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
    </div>
  );
};

const WellnessDetail = () => {
  return (
    <div className={style.wellnessDetail}>
      <h1>{'MASSAGE ROOM & RESTING AREA'}</h1>
      <div className={style.sectionWrapper}>
        {WellnessData().map(i => {
          return (
            <div className={style.section}>
              <h2>
                <div className={style.leftLine}></div>
                <span>{i.title}</span>
                <div className={style.rightLine}></div>
              </h2>
              <div className={style.imageWrapper}>
                {i.pictures.map(picture => {
                  return <img src={picture} alt="massage-room" />;
                })}
              </div>
              <div className={style.info}>
                <div>
                  {i.info.map(info => {
                    return info.type === 'title' ? (
                      <>
                        <br />
                        <br />
                        <h4>{info.value}</h4>
                      </>
                    ) : (
                      <>
                        <br />
                        <br />
                        <p>{info.value}</p>
                      </>
                    );
                  })}
                </div>
                <img src={i.cover} alt="cover" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const FitnessDetail = () => {
  return (
    <div className={style.fitnessDetail}>
      <h1>FITNESS</h1>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1852.65"
        height="309.405"
        viewBox="0 0 1852.65 309.405"
      >
        <g
          id="Group_5948"
          data-name="Group 5948"
          transform="translate(-98.366 -348.803)"
        >
          <text
            id="Cardio"
            transform="matrix(0.978, -0.208, 0.208, 0.978, 139.41, 597.147)"
            fill="#707070"
            font-size="146"
            font-family="PhotographSignature, Photograph Signature"
          >
            <tspan x="0" y="0">
              Cardio
            </tspan>
          </text>
          <path
            id="Path_21246"
            data-name="Path 21246"
            d="M7429.745-1380.365s102.659-88.858,275.937-82.272,183.749,74.306,316.644,80.161,250.372,7.318,328.934-13.173,193.1-38.788,229.813-37.325,46.257.732,116.742,30.006,119.679,48.3,162.264,24.151,81.5-40.252,88.842-40.252"
            transform="translate(-6999.904 1871.322)"
            fill="none"
            stroke="#707070"
            stroke-linecap="round"
            stroke-width="4"
          />
        </g>
      </svg>
      {FitnessData()
        .slice(0, 5)
        .map(i => {
          return (
            <>
              <div className={style.section}>
                <img src={i.cover} alt="cover" />
                <div className={style.info}>
                  <h2>{i.title}</h2>
                  <div className={style.text}>
                    {i.info.map(info => {
                      return info.type === 'bigTitle' ? (
                        <h3>{info.text}</h3>
                      ) : info.type === 'title' ? (
                        <h4>{info.text}</h4>
                      ) : info.type === 'text' ? (
                        <ul>
                          {info.text.map(text => (
                            <li>{text}</li>
                          ))}
                        </ul>
                      ) : info.type === 'paragraph' ? (
                        <p>{info.text}</p>
                      ) : info.type === 'bottomTitle' ? (
                        <h5>{info.text}</h5>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1852.65"
        height="309.405"
        viewBox="0 0 1852.65 309.405"
      >
        <g
          id="Group_5948"
          data-name="Group 5948"
          transform="translate(-98.366 -348.803)"
        >
          <text
            id="Cardio"
            transform="matrix(0.978, -0.208, 0.208, 0.978, 139.41, 597.147)"
            fill="#707070"
            font-size="146"
            font-family="PhotographSignature, Photograph Signature"
          >
            <tspan x="0" y="0">
              Strength
            </tspan>
          </text>
          <path
            id="Path_21246"
            data-name="Path 21246"
            d="M7429.745-1380.365s102.659-88.858,275.937-82.272,183.749,74.306,316.644,80.161,250.372,7.318,328.934-13.173,193.1-38.788,229.813-37.325,46.257.732,116.742,30.006,119.679,48.3,162.264,24.151,81.5-40.252,88.842-40.252"
            transform="translate(-6999.904 1871.322)"
            fill="none"
            stroke="#707070"
            stroke-linecap="round"
            stroke-width="4"
          />
        </g>
      </svg>
      {FitnessData()
        .slice(5, 10)
        .map(i => {
          return (
            <>
              <div className={style.section}>
                <img src={i.cover} alt="cover" />
                <div className={style.info}>
                  <h2>{i.title}</h2>
                  <div className={style.text}>
                    {i.info.map(info => {
                      return info.type === 'bigTitle' ? (
                        <h3>{info.text}</h3>
                      ) : info.type === 'title' ? (
                        <h4>{info.text}</h4>
                      ) : info.type === 'text' ? (
                        <ul>
                          {info.text.map(text => (
                            <li>{text}</li>
                          ))}
                        </ul>
                      ) : info.type === 'paragraph' ? (
                        <p>{info.text}</p>
                      ) : info.type === 'bottomTitle' ? (
                        <h5>{info.text}</h5>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1852.65"
        height="309.405"
        viewBox="0 0 1852.65 309.405"
      >
        <g
          id="Group_5948"
          data-name="Group 5948"
          transform="translate(-98.366 -348.803)"
        >
          <text
            id="Cardio"
            transform="matrix(0.978, -0.208, 0.208, 0.978, 139.41, 597.147)"
            fill="#707070"
            font-size="146"
            font-family="PhotographSignature, Photograph Signature"
          >
            <tspan x="0" y="0">
              Stretching
            </tspan>
          </text>
          <path
            id="Path_21246"
            data-name="Path 21246"
            d="M7429.745-1380.365s102.659-88.858,275.937-82.272,183.749,74.306,316.644,80.161,250.372,7.318,328.934-13.173,193.1-38.788,229.813-37.325,46.257.732,116.742,30.006,119.679,48.3,162.264,24.151,81.5-40.252,88.842-40.252"
            transform="translate(-6999.904 1871.322)"
            fill="none"
            stroke="#707070"
            stroke-linecap="round"
            stroke-width="4"
          />
        </g>
      </svg>
      {FitnessData()
        .slice(10, 12)
        .map(i => {
          return (
            <>
              <div className={style.section}>
                <img src={i.cover} alt="cover" />
                <div className={style.info}>
                  <h2>{i.title}</h2>
                  <div className={style.text}>
                    {i.info.map(info => {
                      return info.type === 'bigTitle' ? (
                        <h3>{info.text}</h3>
                      ) : info.type === 'title' ? (
                        <h4>{info.text}</h4>
                      ) : info.type === 'text' ? (
                        <ul>
                          {info.text.map(text => (
                            <li>{text}</li>
                          ))}
                        </ul>
                      ) : info.type === 'paragraph' ? (
                        <p>{info.text}</p>
                      ) : info.type === 'bottomTitle' ? (
                        <h5>{info.text}</h5>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

const RestaurantBar = () => {
  return (
    <div className={style.restaurantBar}>
      <h1>RESTAURANT & BAR</h1>
      <div className={style.cardWrapper}>
        <a href="/trade-department/furniture/contract/restaurant-bar/tables">
          <h3>Tables</h3>
          <img src={RestaurantTableCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/restaurant-bar/chairs">
          <h3>Chairs</h3>
          <img src={RestaurantTChairsCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/restaurant-bar/lounge">
          <h3>Lounge</h3>
          <img src={RestaurantLoungeCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/restaurant-bar/open-buffet">
          <h3>Open Buffet</h3>
          <img src={RestaurantOpenBuffetCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/restaurant-bar/bars">
          <h3>Bars</h3>
          <img src={RestaurantBarsCover} alt="furniture-cover" />
        </a>
      </div>
    </div>
  );
};

const RestaurantBarsDetail = ({ data }) => {
  return (
    <div className={`${style.restaurantBarsDetail}`}>
      <h1>{'BARS'}</h1>
      <div>
        {RestaurantBarsData().map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
    </div>
  );
};

const OpenBuffetDetail = ({ data }) => {
  return (
    <div className={`${style.restaurantBarsDetail}`}>
      <h1>{'OPEN BUFFET'}</h1>
      <div>
        {OpenBuffetData().map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
    </div>
  );
};

const RestaurantLoungeDetail = ({ data }) => {
  return (
    <div className={`${style.restaurantLoungeDetail}`}>
      <h1>{'LOUNGE'}</h1>
      <div>
        {RestaurantLoungeData().map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
    </div>
  );
};

const RestaurantTableDetail = ({ data }) => {
  return (
    <div className={`${style.restaurantTableDetail}`}>
      <h1>{'TABLES'}</h1>
      <p>
        Our Standard Tables are produced in many different sizes and with
        adjustable leg height. It is packaged as 4 and designed for the use of
        small squares or tables. Our cafe type table legs allow you to walk
        around the table top. Different connection plates, main pipe sizes, and
        table base dimensions are recommended according to the table top size.
        Our table legs are produced in dimensions of 71 cm, which is in
        accordance with table standards, and 41 cm, which is in accordance with
        the standard.
      </p>
      <div className={style.top}>
        {TableData()
          .slice(0, 5)
          .map(i => (
            <img src={i} alt="home" />
          ))}
      </div>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'TABLE TOP'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <div className={style.bottom}>
        {TableData()
          .slice(5, TableData().length)
          .map(i => (
            <img src={i} alt="home" />
          ))}
      </div>
    </div>
  );
};

const RestaurantChairDetail = ({ data }) => {
  return (
    <div className={`${style.restaurantTableDetail}`}>
      <h1>{'CHAIRS'}</h1>
      <p>
        You can use it in all areas such as office, home, waiting area, cafe,
        restaurant. Its comfortable, orthopedic and robust structure immediately
        draws attention.
      </p>
      <div className={style.top}>
        {RestaurantChairData()
          .slice(0, 9)
          .map(i => (
            <img src={i} alt="home" />
          ))}
      </div>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'BAR CHAIRS'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <div className={style.top}>
        {RestaurantChairData()
          .slice(9, TableData().length)
          .map(i => (
            <img src={i} alt="home" />
          ))}
      </div>
    </div>
  );
};

const BackofTheHouse = () => {
  return (
    <div className={style.backOfTheHouse}>
      <h1>BACK OF THE HOUSE</h1>
      <div className={style.cardWrapper}>
        <a href="/trade-department/furniture/contract/back-of-the-house/house-keeping">
          <h3>House Keeping & Room Services</h3>
          <img src={HouseKeepingCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/back-of-the-house/banquet">
          <h3>Banquet</h3>
          <img src={BackofthehouseBanquetCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/back-of-the-house/luggage">
          <h3>Luggage & Bellboy</h3>
          <img src={LuggageCover} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/back-of-the-house/kitchen">
          <h3>Kitchen</h3>
          <img src={BackofthehouseKitchenCover} alt="furniture-cover" />
        </a>
      </div>
    </div>
  );
};

const HouseKeepingDetail = () => {
  return (
    <div className={`${style.houseKeepingDetail}`}>
      <h1>{'HOUSE KEEPING & ROOM SERVICES'}</h1>

      <div className={style.cardWrapper}>
        {HouseKeepingData().map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <div>
                <img src={i.cover} alt="home" />
                <ul>
                  {i.text.map(text => (
                    <li>{text}</li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const LuggageDetail = () => {
  return (
    <div className={`${style.luggageDetail}`}>
      <h1>{'LUGGAGE & BELLBOY'}</h1>

      <div className={style.cardWrapper}>
        {LuggageData().map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <div>
                <img src={i.cover} alt="home" />
                <ul>
                  {i.text.map(text => (
                    <li>{text}</li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const BanquetDetail = () => {
  return (
    <div className={`${style.banquetDetail}`}>
      <h1>{'BANQUET'}</h1>
      <div className={style.cardWrapper}>
        {BanquetData()
          .slice(0, 9)
          .map(i => {
            return (
              <div className={style.card}>
                <h2>{i.title}</h2>
                <div>
                  <img src={i.cover} alt="home" />
                  <h5>{i.info}</h5>
                </div>
              </div>
            );
          })}
      </div>
      <h1>{'BANQUET TABLE'}</h1>
      <p>
        Edges can be produced; with the aluminium or pvc bant protection. The
        legs can be easily folded/ unfolded by pulling the trigger of the double
        locking mechanism.
      </p>
      <div className={style.bottom}>
        {BanquetData()
          .slice(9, BanquetData().length)
          .map(i => {
            return <img src={i.cover} alt="product" />;
          })}
      </div>
    </div>
  );
};

const KitchenDetail = () => {
  return (
    <div className={`${style.kitchenDetail}`}>
      <h1>{'KITCHEN'}</h1>

      <div className={style.cardWrapper}>
        {KitchenData().map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <div>
                <img src={i.cover} alt="home" />
                <ul>
                  {i.text.map(text => (
                    <li>{text}</li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const DrywallDetail = () => {
  return (
    <div className={`${style.drywallDetail}`}>
      <h1>
        <div className={style.leftLine}></div>
        <span>{'METAL STUDS'}</span>
        <div className={style.rightLine}></div>
      </h1>
      <p>
        Ceiling and wall profiles are galvanized steel profiles used in the
        construction of plasterboard partition walls, dry-linings and suspended
        ceiling systems. Ceiling and wall profiles are highly compatible with
        plasterboard and help with flexibility of installation. Corner profiles
        are thin galvanized steel profiles used in the corners of plasterboard
        partition walls and suspended ceilings systems. Corner profiles are also
        used for maintaining the perpendicularity of wall corners in gypsum
        plaster applications, and for increasing resistance against shocks.
      </p>
      <div className={style.cardWrapper}>
        {DrywallData()
          .slice(0, 6)
          .map(i => {
            return (
              <div className={style.card}>
                <img src={i.cover} alt="home" />
                <div>
                  <h2>{i.title}</h2>
                  <h4>{i.info}</h4>
                </div>
              </div>
            );
          })}
      </div>
      <h1>
        <div className={style.leftLine}></div>
        <span>{'DRYWALLS'}</span>
        <div className={style.rightLine}></div>
      </h1>
      <div className={style.cardWrapper}>
        {DrywallData()
          .slice(6, DrywallData().length)
          .map(i => {
            return (
              <div className={style.card}>
                <img src={i.cover} alt="home" />
                <div>
                  <h2>{i.title}</h2>
                  <h4>{i.info}</h4>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const FloorWallDetail = () => {
  return (
    <div className={`${style.floorwallDetail}`}>
      <div className={style.cardWrapper}>
        {FlooringWallData().map(i => {
          return (
            <div className={style.card}>
              <h1>
                <div className={style.leftLine}></div>
                <span>{i.title}</span>
                <div className={style.rightLine}></div>
              </h1>

              {i.pictures.map(picture => (
                <img src={picture} alt="home" />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const DoorsFurnituresDetail = ({ data }) => {
  return (
    <div className={`${style.doorsFurnitureDetail}`}>
      <h1>{'DOORS & FURNITURES'}</h1>
      <div>
        {DoorsFurnituresData().map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
    </div>
  );
};

const WindowDetail = () => {
  return (
    <div className={style.windowDetail}>
      <h1>WINDOW FACADE</h1>
      <div className={style.cardWrapper}>
        {WindowData().map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <div className={style.infoWrapper}>
                <img src={i.cover} alt="cover" />
                <div>
                  <h4>FEATURES:</h4>
                  <ul>
                    {i.info.map(text => (
                      <li>{text}</li>
                    ))}
                  </ul>
                  {i.bottomTitle ? <h5>{i.bottomTitle}</h5> : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const FurnitureFabricDetail = () => {
  return (
    <div className={style.furnitureFabricDetail}>
      <h1>FURNITURE FABRICS</h1>
      <div className={style.cardWrapper}>
        {FurnitureFabricsData().map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <div className={style.infoWrapper}>
                <img className={style.cover} src={i.cover} alt="cover" />
                <div className={style.info}>
                  <ul>
                    <li>
                      <h3>Pattern Type:</h3>
                      <span>{i.pattern}</span>
                    </li>
                    <li>
                      <h3>Fabric Type:</h3>
                      <span>{i.fabric}</span>
                    </li>
                    <li>
                      <h3>Ussage:</h3>
                      <span>{i.usage}</span>
                    </li>
                    <li>
                      <h3>Number of colors:</h3>
                      <span>{i.color}</span>
                    </li>
                    <li>
                      <h3>Content:</h3>
                      <span>{i.content}</span>
                    </li>
                    <li>
                      <h3>Width:</h3>
                      <span>{i.width}</span>
                    </li>
                  </ul>
                  <div
                    className={`${style.miniPhotoWrapper} ${
                      i.photoType === 'long' ? style.miniLong : null
                    }`}
                  >
                    {i.miniPhoto.map(miniPhoto => (
                      <img
                        className={style.miniPhoto}
                        src={miniPhoto}
                        alt="miniPhoto"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const LinensDetail = () => {
  return (
    <div className={style.furnitureFabricDetail}>
      <h1>LINENS</h1>
      <div className={style.cardWrapper}>
        {LinensData().map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <div className={style.infoWrapper}>
                <img className={style.cover} src={i.cover} alt="cover" />
                <div className={style.info}>
                  <ul>
                    <li>
                      <h3>Pattern Type:</h3>
                      <span>{i.pattern}</span>
                    </li>
                    <li>
                      <h3>Fabric Type:</h3>
                      <span>{i.fabric}</span>
                    </li>
                    <li>
                      <h3>Ussage:</h3>
                      <span>{i.usage}</span>
                    </li>
                    <li>
                      <h3>Number of colors:</h3>
                      <span>{i.color}</span>
                    </li>
                    <li>
                      <h3>Content:</h3>
                      <span>{i.content}</span>
                    </li>
                    <li>
                      <h3>Width:</h3>
                      <span>{i.width}</span>
                    </li>
                  </ul>
                  <div
                    className={`${style.miniPhotoWrapper} ${
                      i.photoType === 'long' ? style.miniLong : null
                    }`}
                  >
                    {i.miniPhoto.map(miniPhoto => (
                      <img
                        className={style.miniPhoto}
                        src={miniPhoto}
                        alt="miniPhoto"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CarpetDetail = () => {
  return (
    <div className={style.carpetDetail}>
      <h1>CARPETS</h1>
      <div className={style.cardWrapper}>
        {CarpetData().map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <div className={style.infoWrapper}>
                <img className={style.cover} src={i.cover} alt="cover" />
                <div className={style.info}>
                  <p>{i.textTop}</p>
                  <div className={style.miniPhotoWrapper}>
                    {i.miniPhoto.map(miniImage => (
                      <img src={miniImage} alt="mini" />
                    ))}
                  </div>
                  <p className={style.bottom}>
                    It is produced using viscose and acrylic yarn. Since cotton
                    scarf is used, there may be a variation between 1% and 5% in
                    the dimensions.
                  </p>
                </div>
              </div>
              <img
                className={style.bottomImage}
                src={CarpetBottomImage}
                alt="carpet-bottom"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CurtainsDetail = () => {
  return (
    <div className={style.curtainsDetail}>
      <h1>CURTAINS</h1>
      <div className={style.cardWrapper}>
        {CurtainsData().map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <div className={style.infoWrapper}>
                <img src={i.cover} alt="cover" />
                <ul>
                  <li>
                    Size: <span>{i.size}</span>
                  </li>
                  <li>
                    Material: <span>{i.material}</span>
                  </li>
                  <li>
                    Number of Pieces: <span>{i.pieces}</span>
                  </li>
                  <li>
                    Attachment Type: <span>{i.type}</span>
                  </li>
                  <li>
                    Color: <span>{i.color}</span>
                  </li>

                  <h4>{i.bottomText}</h4>
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HotelRooms = () => {
  return (
    <div className={style.hotelRooms}>
      <h1>ROOMS</h1>
      <div className={style.cardWrapper}>
        <a href="/trade-department/furniture/contract/rooms/beds">
          <h3>Beds</h3>
          <img src={RoomsBed} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/rooms/tables-chairs">
          <h3>Tables & Chairs</h3>
          <img src={RoomsTables} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/rooms/storages">
          <h3>Storages</h3>
          <img src={RoomsStorages} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/rooms/bathrooms">
          <h3>Bathrooms</h3>
          <img src={RoomsBathrooms} alt="furniture-cover" />
        </a>
        <a href="/trade-department/furniture/contract/rooms/equipments">
          <h3>Equipments</h3>
          <img src={RoomsEquipments} alt="furniture-cover" />
        </a>
      </div>
    </div>
  );
};

const HotelStorageDetail = ({ data }) => {
  return (
    <div className={`${style.restaurantBarsDetail}`}>
      <h1>{'STORAGES'}</h1>
      <div>
        {HotelStorageData().map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
    </div>
  );
};

const HotelTableChairsDetail = ({ data }) => {
  return (
    <div className={`${style.hotelTableChairsDetail}`}>
      <h1>{'TABLE LEG'}</h1>
      <p>
        Our Standard Tables are produced in many different sizes and with
        adjustable leg height. It is packaged as 4 and designed for the use of
        small squares or tables. Our cafe type table legs allow you to walk
        around the table top. Different connection plates, main pipe sizes, and
        table base dimensions are recommended according to the table top size.
        Our table legs are produced in dimensions of 71 cm, which is in
        accordance with table standards, and 41 cm, which is in accordance with
        the standard.
      </p>
      <div className={style.cardWrapper}>
        {HotelTablesChairsData('table-leg').map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'TABLE TOP'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <div className={style.cardWrapperMultiple}>
        {HotelTablesChairsData('table-top').map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'CHAIRS'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <p>
        You can use it in all areas such as office, home, waiting area, cafe,
        restaurant. Its comfortable, orthopedic and robust structure immediately
        draws attention.
      </p>
      <div className={style.cardWrapper}>
        {HotelTablesChairsData('chairs').map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'BAR CHAIRS'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <div className={style.cardWrapper}>
        {HotelTablesChairsData('bar-chairs').map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'COFFE TABLE'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <div className={style.cardWrapper}>
        {HotelTablesChairsData('coffee-table').map(i => (
          <img src={i} alt="home" />
        ))}
      </div>
    </div>
  );
};

const HotelBedDetail = () => {
  return (
    <div className={style.hotelBedDetail}>
      <h1>
        <div className={style.leftLine}></div>
        <span>{'BED BASE'}</span>
        <div className={style.rightLine}></div>
      </h1>
      <div className={style.cardWrapper}>
        {HotelBedData('bed-base').map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <img className={style.cover} src={i.cover} alt="cover" />
              <img
                className={style.bottomImage}
                src={HotelBedBottomImage}
                alt="cover"
              />
              <ul>
                <li>* Stainless and heavy-duty steel construction</li>
                <li>
                  * Large internal volume enclosures offering modular storage
                  space
                </li>
                <li>
                  * Shock absorbers and scissors reinforced with special safety
                  locks
                </li>
                <li>* Easy-to-grip front handles</li>
                <li>* All 5 mm willow inner coating</li>
              </ul>
            </div>
          );
        })}
      </div>
      <h1>
        <div className={style.leftLine}></div>
        <span>{'BED'}</span>
        <div className={style.rightLine}></div>
      </h1>
      <div className={style.cardWrapper}>
        {HotelBedData('bed').map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <img className={style.cover} src={i.cover} alt="cover" />
              <img
                className={style.bottomImage}
                src={HotelBedBottomImage}
                alt="cover"
              />
              <p>{i.info}</p>
              <div className={style.bottomInfo}>
                <h3>
                  Hardness Degree: <span>{i.hardness}</span>
                </h3>
                <h3>
                  Select Weight: <span>{i.weight}</span>
                </h3>
                <h3>
                  Choose a Sleeping Position: <span>{i.position}</span>
                </h3>
              </div>
            </div>
          );
        })}
      </div>
      <h1>
        <div className={style.leftLine}></div>
        <span>{'HEADBOARD'}</span>
        <div className={style.rightLine}></div>
      </h1>
      <div className={style.cardWrapper}>
        {HotelBedData('headboard').map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <img className={style.cover} src={i.cover} alt="cover" />
              <img
                className={style.bottomImage}
                src={HotelBedBottomImage}
                alt="cover"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HotelEquipmentsDetail = () => {
  return (
    <div className={style.hotelEquipmentsDetail}>
      <h1>EQUIPMENTS</h1>
      <div className={style.cardWrapper}>
        {HotelEquipmentsData('equipments').map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <div className={style.infoWrapper}>
                <img src={i.cover} alt="cover" />
                <div className={style.info}>
                  <h3>Standard Features:</h3>
                  <p>
                    Interior Led Light With Sensor <br />
                    Auto-Defrost <br />
                    Reversible Door <br />
                    Adjustable Interior Shelves <br />
                    Built-In <br />
                    Black, White <br />
                    PVC Coated Galvanized Steel Body
                    <br />
                  </p>

                  <table>
                    <tr>
                      <td>Cooling Capacity (L)</td>
                      <td>{i.cooling}</td>
                    </tr>
                    <tr>
                      <td>Dimensions (mm) (W x D x H)</td>
                      <td>{i.dimension}</td>
                    </tr>
                    <tr>
                      <td>Ambient Temperature</td>
                      <td>{i.ambient}</td>
                    </tr>
                    <tr>
                      <td>Storage Working Temperature</td>
                      <td>{i.storage}</td>
                    </tr>
                    <tr>
                      <td>Refrigerant</td>
                      <td>{i.refrigerant}</td>
                    </tr>
                    <tr>
                      <td>Amount of Refrigerant (g/L)</td>
                      <td>{i.amount}</td>
                    </tr>
                    <tr>
                      <td>Energy Consumption (kwh/24h)</td>
                      <td>{i.energy}</td>
                    </tr>
                    {i.electric ? (
                      <tr>
                        <td>Electric Rating (Watt)</td>
                        <td>{i.electric}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>Voltage Frequency (V / Hz)</td>
                      <td>{i.voltage}</td>
                    </tr>
                    <tr>
                      <td>Relative Working Ratio (%)</td>
                      <td>{i.relative}</td>
                    </tr>
                    <tr>
                      <td>Noise (dB)</td>
                      <td>{i.noise}</td>
                    </tr>
                    <tr>
                      <td>Net Weight (kg)</td>
                      <td>{i.weight}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={style.cardWrapper}>
        {HotelEquipmentsData('equipments-2').map(i => {
          return (
            <div className={style.card}>
              <h2>{i.title}</h2>
              <div className={style.infoWrapper}>
                <img src={i.cover} alt="cover" />
                <div className={style.info}>
                  <h3>Standard Features:</h3>
                  <p>
                    {i.info.map(info => {
                      return (
                        <>
                          {info}
                          <br />
                        </>
                      );
                    })}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={style.cardWrapperMultiple}>
        <div className={style.card}>
          <h2>{'T-TIME-3674'}</h2>
          <div className={style.infoWrapper}>
            <div className={style.imageWrapper}>
              {HotelEquipmentsData('t-time').map(i => (
                <img src={i} alt="cover" />
              ))}
            </div>
            <div className={style.info}>
              <p>
                • 1 -1,2 L capacity, stainless steel <br />
                • 1785 Watt (220-240 volt / 50-60 Hz) <br />
                • Automatic opening button
                <br />
                • Covered heating element (Otter Brand - Italy)
                <br />
                • Cordless with safety wire for hotel use
                <br />
                • Stainless steel flat bottom
                <br />
                • Rotation 360°, blue light indicator <br />
                • Auto steam shut-off, boil dry protection <br />
                • Washable lime filter <br />
                • ORIGIN : P.R.C. <br />
              </p>
              <div>
                <h3>WOODEN TRAY SPECIFICATIONS</h3>
                <p>
                  • 100% Solid beech
                  <br />
                  • Anti-theft system
                  <br />
                  • Easy to clean, (computerızed CNC process)
                  <br />
                </p>

                <h4>
                  Large <span>3 x 30 x 50 cm</span>
                </h4>
                <h4>
                  Medium <span>3 x 25 x 40 cm</span>
                </h4>
                <h4>
                  Small <span>3 x 20 x 50 cm</span>
                </h4>
                <h4>
                  Weight <span>3 kg</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BathroomDetail = () => {
  return (
    <div className={style.bathroomDetail}>
      <h1>BATHROOM</h1>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'SINKS'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <div className={style.cardWrapper}>
        {BathroomData('sink').map(i => (
          <img src={i} alt="sink" />
        ))}
      </div>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'CLOSET'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <div className={style.cardWrapper}>
        {BathroomData('closet').map(i => (
          <img src={i} alt="closet" />
        ))}
      </div>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'URINALS'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <div className={style.cardWrapper}>
        {BathroomData('urinal').map(i => (
          <img src={i} alt="urinal" />
        ))}
      </div>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'ACRYLIC BATHTUB'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <div className={style.cardWrapper}>
        {BathroomData('acrylic').map(i => (
          <img src={i} alt="acrylic" />
        ))}
      </div>
      <h2>
        <div className={style.leftLine}></div>
        <span>{'Furniture Washbasin Cabinet'}</span>
        <div className={style.rightLine}></div>
      </h2>
      <div className={style.cardWrapper}>
        {BathroomData('furniture').map(i => (
          <img src={i} alt="furniture" />
        ))}
      </div>
    </div>
  );
};

const TradeDepartment = () => {
  const history = useHistory();
  const section = queryString.parse(history.location.search).section;
  const isInnerDetail = history.location.pathname.split('/')[3];
  const isSubInnerDetail = history.location.pathname.split('/')[4];
  const isDetail = history.location.pathname.split('/')[5];
  const [partnerOpen, setPartnerOpen] = useState(false);

  const [styles, api] = useSpring(() => ({
    from: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
    },
  }));

  console.log(isSubInnerDetail);

  return (
    <>
      <div className={style.graphicDepartment}>
        {section ||
        isDetail ||
        isInnerDetail ||
        isSubInnerDetail ||
        partnerOpen ? null : (
          <Person
            image={KaanImage}
            title={'"Purchasing Manager"'}
            personName={'KAAN'}
            department={'TRADE DEPARTMENT'}
            info={`In the phase of project application, we consult on building strategies, material and form choices moreover we provide solutions for alternative technologies.`}
          />
        )}

        {partnerOpen ? <Partners /> : null}

        {section || isDetail || isInnerDetail || isSubInnerDetail ? null : (
          <animated.button
            onClick={() => {
              if (partnerOpen) {
                api({
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 40,
                  paddingRight: 40,
                });
                setPartnerOpen(false);
              } else {
                api({
                  paddingTop: 10,
                  paddingBottom: 120,
                  paddingLeft: 80,
                  paddingRight: 80,
                });
                setPartnerOpen(true);
              }
            }}
            className={style.partnerButton}
            style={{ ...styles }}
          >
            PARTNERS
          </animated.button>
        )}

        {section === 'menu' ? <Products /> : null}

        {/* {isDetail === 'contract' ? (
          <Contract />
        ) : isDetail === 'lights' ? (
          <Light />
        ) : null} */}

        {isInnerDetail === 'contract' && !isSubInnerDetail ? (
          <Contract />
        ) : isInnerDetail === 'lights' && !isSubInnerDetail ? (
          <Light />
        ) : isInnerDetail === 'home' && !isSubInnerDetail ? (
          <Home />
        ) : isInnerDetail === 'office' && !isSubInnerDetail ? (
          <Office />
        ) : isInnerDetail === 'drywall-products' && !isSubInnerDetail ? (
          <DrywallDetail />
        ) : isInnerDetail === 'flooring-wall' && !isSubInnerDetail ? (
          <FloorWallDetail />
        ) : isInnerDetail === 'doors-furnitures' && !isSubInnerDetail ? (
          <DoorsFurnituresDetail />
        ) : isInnerDetail === 'window-facade' && !isSubInnerDetail ? (
          <WindowDetail />
        ) : isInnerDetail === 'furniture-fabrics' && !isSubInnerDetail ? (
          <FurnitureFabricDetail />
        ) : isInnerDetail === 'linens' && !isSubInnerDetail ? (
          <LinensDetail />
        ) : isInnerDetail === 'carpets' && !isSubInnerDetail ? (
          <CarpetDetail />
        ) : isInnerDetail === 'curtains' && !isSubInnerDetail ? (
          <CurtainsDetail />
        ) : null}

        {(!isDetail && isSubInnerDetail === 'sofas') ||
        isSubInnerDetail === 'special-kitchen-design' ||
        isSubInnerDetail === 'outdoor' ||
        isSubInnerDetail === 'special-storage-design' ? (
          <HomeDetail data={HomeData(isSubInnerDetail)} />
        ) : (!isDetail && isSubInnerDetail === 'tables') ||
          isSubInnerDetail === 'chairs' ||
          isSubInnerDetail === 'storage' ||
          isSubInnerDetail === 'waiting-zone' ? (
          <OfficeDetail data={OfficeData(isSubInnerDetail)} />
        ) : !isDetail && isSubInnerDetail === 'public-space' ? (
          <PublicSpace />
        ) : !isDetail && isSubInnerDetail === 'spa-center' ? (
          <SpaCenter />
        ) : !isDetail && isSubInnerDetail === 'restaurant-bar' ? (
          <RestaurantBar />
        ) : !isDetail && isSubInnerDetail === 'back-of-the-house' ? (
          <BackofTheHouse />
        ) : !isDetail && isSubInnerDetail === 'rooms' ? (
          <HotelRooms />
        ) : null}

        {isDetail === 'reception' ? (
          <ReceptionDetail />
        ) : isDetail === 'decoration' ? (
          <DecorationDetail />
        ) : isDetail === 'massage-room' ? (
          <MassageRoomDetail />
        ) : isDetail === 'wellness-room' ? (
          <WellnessDetail />
        ) : isDetail === 'fitness' ? (
          <FitnessDetail />
        ) : isDetail === 'bars' ? (
          <RestaurantBarsDetail />
        ) : isDetail === 'open-buffet' ? (
          <OpenBuffetDetail />
        ) : isDetail === 'lounge' ? (
          <RestaurantLoungeDetail />
        ) : isDetail === 'tables' ? (
          <RestaurantTableDetail />
        ) : isDetail === 'chairs' ? (
          <RestaurantChairDetail />
        ) : isDetail === 'house-keeping' ? (
          <HouseKeepingDetail />
        ) : isDetail === 'luggage' ? (
          <LuggageDetail />
        ) : isDetail === 'banquet' ? (
          <BanquetDetail />
        ) : isDetail === 'kitchen' ? (
          <KitchenDetail />
        ) : isDetail === 'storages' ? (
          <HotelStorageDetail />
        ) : isDetail === 'tables-chairs' ? (
          <HotelTableChairsDetail />
        ) : isDetail === 'beds' ? (
          <HotelBedDetail />
        ) : isDetail === 'equipments' ? (
          <HotelEquipmentsDetail />
        ) : isDetail === 'bathrooms' ? (
          <BathroomDetail />
        ) : null}
      </div>
    </>
  );
};

export default TradeDepartment;
