import lightImage1 from '../../assets/img/trade-department/products/lights/light-1.png';
import lightImage2 from '../../assets/img/trade-department/products/lights/light-2.png';
import lightImage3 from '../../assets/img/trade-department/products/lights/light-3.png';
import lightImage4 from '../../assets/img/trade-department/products/lights/light-4.png';
import lightImage5 from '../../assets/img/trade-department/products/lights/light-5.png';
import lightImage6 from '../../assets/img/trade-department/products/lights/light-6.png';
import lightImage7 from '../../assets/img/trade-department/products/lights/light-7.png';
import lightImage8 from '../../assets/img/trade-department/products/lights/light-8.png';
import lightImage9 from '../../assets/img/trade-department/products/lights/light-9.png';
import lightImage10 from '../../assets/img/trade-department/products/lights/light-10.png';
import lightImage11 from '../../assets/img/trade-department/products/lights/light-11.png';
import lightImage12 from '../../assets/img/trade-department/products/lights/light-12.png';
import lightImage13 from '../../assets/img/trade-department/products/lights/light-13.png';
import lightImage14 from '../../assets/img/trade-department/products/lights/light-14.png';
import lightImage15 from '../../assets/img/trade-department/products/lights/light-15.png';
import lightImage16 from '../../assets/img/trade-department/products/lights/light-16.png';

const LightData = [
  lightImage1,
  lightImage2,
  lightImage3,
  lightImage4,
  lightImage5,
  lightImage6,
  lightImage7,
  lightImage8,
  lightImage9,
  lightImage10,
  lightImage11,
  lightImage12,
  lightImage13,
  lightImage14,
  lightImage15,
  lightImage16,
];

export default LightData;
