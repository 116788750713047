import style from './card.module.scss';

const CatalogCard = ({ link, title, cover }) => {
  return (
    <a className={style.catalogCard} href={link}>
      <h2>{title}</h2>
      <img src={cover} alt={`${link}-cover`} />
    </a>
  );
};

export default CatalogCard;
