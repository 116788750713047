import lff4820 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4820.png';
import lff4820Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4820-mini-1.png';
import lff4820Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4820-mini-2.png';
import lff4820Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4820-mini-3.png';
import lff4820Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4820-mini-4.png';
import lff4820Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4820-mini-5.png';
import lff4820Mini6 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4820-mini-6.png';

import lff4821 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4821.png';
import lff4821Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4821-mini-1.png';
import lff4821Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4821-mini-2.png';
import lff4821Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4821-mini-3.png';
import lff4821Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4821-mini-4.png';

import lff4822 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4822.png';
import lff4822Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4822-mini-1.png';
import lff4822Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4822-mini-2.png';
import lff4822Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4822-mini-3.png';
import lff4822Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4822-mini-4.png';
import lff4822Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4822-mini-5.png';
import lff4822Mini6 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4822-mini-6.png';

import lff4823 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4823.png';
import lff4823Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4823-mini-1.png';
import lff4823Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4823-mini-2.png';

import lff4824 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4824.png';
import lff4824Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4824-mini-1.png';
import lff4824Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4824-mini-2.png';
import lff4824Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4824-mini-3.png';
import lff4824Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4824-mini-4.png';
import lff4824Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4824-mini-5.png';
import lff4824Mini6 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4824-mini-6.png';

import lff4825 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4825.png';
import lff4825Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4825-mini-1.png';
import lff4825Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4825-mini-2.png';
import lff4825Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4825-mini-3.png';

import lff4826 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4826.png';
import lff4826Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4826-mini-1.png';
import lff4826Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4826-mini-2.png';
import lff4826Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4826-mini-3.png';
import lff4826Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4826-mini-4.png';
import lff4826Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4826-mini-5.png';
import lff4826Mini6 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4826-mini-6.png';

import lff4827 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4827.png';
import lff4827Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4827-mini-1.png';
import lff4827Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4827-mini-2.png';

import lff4828 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4828.png';
import lff4828Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4828-mini-1.png';
import lff4828Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4828-mini-2.png';
import lff4828Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4828-mini-3.png';

import lff4829 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4829.png';
import lff4829Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4829-mini-1.png';
import lff4829Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4829-mini-2.png';

import lff4830 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4830.png';
import lff4830Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4830-mini-1.png';
import lff4830Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4830-mini-2.png';
import lff4830Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4830-mini-3.png';
import lff4830Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4830-mini-4.png';
import lff4830Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4830-mini-5.png';
import lff4830Mini6 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4830-mini-6.png';

import lff4831 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4831.png';
import lff4831Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4831-mini-1.png';
import lff4831Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4831-mini-2.png';

import lff4832 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4832.png';
import lff4832Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4832-mini-1.png';
import lff4832Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4832-mini-2.png';
import lff4832Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4832-mini-3.png';
import lff4832Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4832-mini-4.png';
import lff4832Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4832-mini-5.png';

import lff4833 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4833.png';
import lff4833Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4833-mini-1.png';
import lff4833Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4833-mini-2.png';
import lff4833Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4833-mini-3.png';
import lff4833Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4833-mini-4.png';

import lff4834 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4834.png';
import lff4834Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4834-mini-1.png';
import lff4834Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4834-mini-2.png';
import lff4834Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4834-mini-3.png';
import lff4834Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4834-mini-4.png';
import lff4834Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4834-mini-5.png';
import lff4834Mini6 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4834-mini-6.png';

import lff4835 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4835.png';
import lff4835Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4835-mini-1.png';
import lff4835Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4835-mini-2.png';
import lff4835Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4835-mini-3.png';
import lff4835Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4835-mini-4.png';
import lff4835Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4835-mini-5.png';
import lff4835Mini6 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4835-mini-6.png';

import lff4836 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4836.png';
import lff4836Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4836-mini-1.png';
import lff4836Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4836-mini-2.png';
import lff4836Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4836-mini-3.png';
import lff4836Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4836-mini-4.png';
import lff4836Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4836-mini-5.png';

import lff4837 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4837.png';
import lff4837Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4837-mini-1.png';
import lff4837Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4837-mini-2.png';
import lff4837Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4837-mini-3.png';
import lff4837Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4837-mini-4.png';
import lff4837Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4837-mini-5.png';
import lff4837Mini6 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4837-mini-6.png';

import lff4838 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4838.png';
import lff4838Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4838-mini-1.png';
import lff4838Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4838-mini-2.png';
import lff4838Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4838-mini-3.png';
import lff4838Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4838-mini-4.png';
import lff4838Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4838-mini-5.png';

import lff4839 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4839.png';
import lff4839Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4839-mini-1.png';
import lff4839Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4839-mini-2.png';

import lff4840 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4840.png';
import lff4840Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4840-mini-1.png';
import lff4840Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4840-mini-2.png';
import lff4840Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4840-mini-3.png';
import lff4840Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4840-mini-4.png';
import lff4840Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4840-mini-5.png';

import lff4841 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4841.png';
import lff4841Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4841-mini-1.png';
import lff4841Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4841-mini-2.png';
import lff4841Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4841-mini-3.png';
import lff4841Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4841-mini-4.png';
import lff4841Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4841-mini-5.png';

import lff4842 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4842.png';
import lff4842Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4842-mini-1.png';
import lff4842Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4842-mini-2.png';
import lff4842Mini3 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4842-mini-3.png';
import lff4842Mini4 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4842-mini-4.png';
import lff4842Mini5 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4842-mini-5.png';
import lff4842Mini6 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4842-mini-6.png';

import lff4843 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4843.png';
import lff4843Mini1 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4843-mini-1.png';
import lff4843Mini2 from '../../assets/img/trade-department/products/textile/furniture-fabric/lff-4843-mini-2.png';

// LINENS

import lff4720 from '../../assets/img/trade-department/products/textile/linens/lff-4720.png';
import lff4720Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4720-mini-1.png';
import lff4720Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4720-mini-2.png';
import lff4720Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4720-mini-3.png';
import lff4720Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4720-mini-4.png';
import lff4720Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4720-mini-5.png';
import lff4720Mini6 from '../../assets/img/trade-department/products/textile/linens/lff-4720-mini-6.png';

import lff4721 from '../../assets/img/trade-department/products/textile/linens/lff-4721.png';
import lff4721Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4721-mini-1.png';
import lff4721Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4721-mini-2.png';
import lff4721Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4721-mini-3.png';
import lff4721Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4721-mini-4.png';
import lff4721Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4721-mini-5.png';

import lff4722 from '../../assets/img/trade-department/products/textile/linens/lff-4722.png';
import lff4722Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4722-mini-1.png';
import lff4722Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4722-mini-2.png';
import lff4722Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4722-mini-3.png';
import lff4722Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4722-mini-4.png';
import lff4722Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4722-mini-5.png';

import lff4723 from '../../assets/img/trade-department/products/textile/linens/lff-4723.png';
import lff4723Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4723-mini-1.png';
import lff4723Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4723-mini-2.png';
import lff4723Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4723-mini-3.png';
import lff4723Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4723-mini-4.png';
import lff4723Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4723-mini-5.png';
import lff4723Mini6 from '../../assets/img/trade-department/products/textile/linens/lff-4723-mini-6.png';

import lff4724 from '../../assets/img/trade-department/products/textile/linens/lff-4724.png';
import lff4724Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4724-mini-1.png';
import lff4724Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4724-mini-2.png';
import lff4724Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4724-mini-3.png';
import lff4724Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4724-mini-4.png';

import lff4725 from '../../assets/img/trade-department/products/textile/linens/lff-4725.png';
import lff4725Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4725-mini-1.png';
import lff4725Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4725-mini-2.png';

import lff4726 from '../../assets/img/trade-department/products/textile/linens/lff-4726.png';
import lff4726Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4726-mini-1.png';
import lff4726Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4726-mini-2.png';

import lff4727 from '../../assets/img/trade-department/products/textile/linens/lff-4727.png';
import lff4727Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4727-mini-1.png';
import lff4727Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4727-mini-2.png';
import lff4727Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4727-mini-3.png';
import lff4727Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4727-mini-4.png';

import lff4728 from '../../assets/img/trade-department/products/textile/linens/lff-4728.png';
import lff4728Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4728-mini-1.png';
import lff4728Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4728-mini-2.png';
import lff4728Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4728-mini-3.png';
import lff4728Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4728-mini-4.png';
import lff4728Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4728-mini-5.png';
import lff4728Mini6 from '../../assets/img/trade-department/products/textile/linens/lff-4728-mini-6.png';

import lff4729 from '../../assets/img/trade-department/products/textile/linens/lff-4729.png';
import lff4729Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4729-mini-1.png';
import lff4729Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4729-mini-2.png';
import lff4729Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4729-mini-3.png';
import lff4729Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4729-mini-4.png';
import lff4729Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4729-mini-5.png';

import lff4730 from '../../assets/img/trade-department/products/textile/linens/lff-4730.png';
import lff4730Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4730-mini-1.png';
import lff4730Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4730-mini-2.png';
import lff4730Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4730-mini-3.png';
import lff4730Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4730-mini-4.png';

import lff4731 from '../../assets/img/trade-department/products/textile/linens/lff-4731.png';
import lff4731Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4731-mini-1.png';
import lff4731Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4731-mini-2.png';
import lff4731Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4731-mini-3.png';
import lff4731Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4731-mini-4.png';
import lff4731Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4731-mini-5.png';
import lff4731Mini6 from '../../assets/img/trade-department/products/textile/linens/lff-4731-mini-6.png';

import lff4732 from '../../assets/img/trade-department/products/textile/linens/lff-4732.png';
import lff4732Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4732-mini-1.png';
import lff4732Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4732-mini-2.png';
import lff4732Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4732-mini-3.png';
import lff4732Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4732-mini-4.png';
import lff4732Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4732-mini-5.png';
import lff4732Mini6 from '../../assets/img/trade-department/products/textile/linens/lff-4732-mini-6.png';

import lff4733 from '../../assets/img/trade-department/products/textile/linens/lff-4733.png';
import lff4733Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4733-mini-1.png';
import lff4733Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4733-mini-2.png';
import lff4733Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4733-mini-3.png';
import lff4733Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4733-mini-4.png';
import lff4733Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4733-mini-5.png';

import lff4734 from '../../assets/img/trade-department/products/textile/linens/lff-4734.png';
import lff4734Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4734-mini-1.png';
import lff4734Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4734-mini-2.png';
import lff4734Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4734-mini-3.png';
import lff4734Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4734-mini-4.png';
import lff4734Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4734-mini-5.png';
import lff4734Mini6 from '../../assets/img/trade-department/products/textile/linens/lff-4734-mini-6.png';

import lff4735 from '../../assets/img/trade-department/products/textile/linens/lff-4735.png';
import lff4735Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4735-mini-1.png';

import lff4736 from '../../assets/img/trade-department/products/textile/linens/lff-4736.png';
import lff4736Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4736-mini-1.png';
import lff4736Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4736-mini-2.png';
import lff4736Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4736-mini-3.png';
import lff4736Mini4 from '../../assets/img/trade-department/products/textile/linens/lff-4736-mini-4.png';
import lff4736Mini5 from '../../assets/img/trade-department/products/textile/linens/lff-4736-mini-5.png';

import lff4737 from '../../assets/img/trade-department/products/textile/linens/lff-4737.png';
import lff4737Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4737-mini-1.png';
import lff4737Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4737-mini-2.png';

import lff4738 from '../../assets/img/trade-department/products/textile/linens/lff-4738.png';
import lff4738Mini1 from '../../assets/img/trade-department/products/textile/linens/lff-4738-mini-1.png';
import lff4738Mini2 from '../../assets/img/trade-department/products/textile/linens/lff-4738-mini-2.png';
import lff4738Mini3 from '../../assets/img/trade-department/products/textile/linens/lff-4738-mini-3.png';

// CARPETS

import lxr02b from '../../assets/img/trade-department/products/textile/carpets/lxr02b.png';
import lxr02bMini1 from '../../assets/img/trade-department/products/textile/carpets/lxr02b-mini-1.png';

import lxr02a from '../../assets/img/trade-department/products/textile/carpets/lxr02a.png';
import lxr02aMini1 from '../../assets/img/trade-department/products/textile/carpets/lxr02a-mini-1.png';

import lbx05a from '../../assets/img/trade-department/products/textile/carpets/lbx05a.png';

import lxr04a from '../../assets/img/trade-department/products/textile/carpets/lxr04a.png';
import lxr04aMini1 from '../../assets/img/trade-department/products/textile/carpets/lxr04a-mini-1.png';

import lxr02b2 from '../../assets/img/trade-department/products/textile/carpets/lxr02b-2.png';
import lxr02bMini12 from '../../assets/img/trade-department/products/textile/carpets/lxr02b-2-mini-1.png';

import lbx09c from '../../assets/img/trade-department/products/textile/carpets/lbx09c.png';

import lbx11a from '../../assets/img/trade-department/products/textile/carpets/lbx11a.png';

import lbx13a from '../../assets/img/trade-department/products/textile/carpets/lbx13a.png';
import lbx13aMini1 from '../../assets/img/trade-department/products/textile/carpets/lbx13a-mini-1.png';

import lvk06a from '../../assets/img/trade-department/products/textile/carpets/lvk06a.png';

import lvk08d from '../../assets/img/trade-department/products/textile/carpets/lvk08d.png';
import lvk08dMini1 from '../../assets/img/trade-department/products/textile/carpets/lvk08d-mini-1.png';
import lvk08dMini2 from '../../assets/img/trade-department/products/textile/carpets/lvk08d-mini-2.png';

import lvk13a from '../../assets/img/trade-department/products/textile/carpets/lvk13a.png';

import lno00a from '../../assets/img/trade-department/products/textile/carpets/lno00a.png';

import lno02e from '../../assets/img/trade-department/products/textile/carpets/lno02e.png';
import lno02eMini1 from '../../assets/img/trade-department/products/textile/carpets/lno02e-mini-1.png';

import lno03a from '../../assets/img/trade-department/products/textile/carpets/lno03a.png';

import lno04a from '../../assets/img/trade-department/products/textile/carpets/lno04a.png';
import lno04aMini1 from '../../assets/img/trade-department/products/textile/carpets/lno04a-mini-1.png';
import lno04aMini2 from '../../assets/img/trade-department/products/textile/carpets/lno04a-mini-2.png';

import lno10d from '../../assets/img/trade-department/products/textile/carpets/lno10d.png';
import lno10dMini1 from '../../assets/img/trade-department/products/textile/carpets/lno10d-mini-1.png';
import lno10dMini2 from '../../assets/img/trade-department/products/textile/carpets/lno10d-mini-2.png';

import lpo02a from '../../assets/img/trade-department/products/textile/carpets/lpo02a.png';

import lpo04b from '../../assets/img/trade-department/products/textile/carpets/lpo04b.png';

import lpo07a from '../../assets/img/trade-department/products/textile/carpets/lpo07a.png';
import lpo07aMini1 from '../../assets/img/trade-department/products/textile/carpets/lpo07a-mini-1.png';

import lpo10b from '../../assets/img/trade-department/products/textile/carpets/lpo10b.png';

import lpo13c from '../../assets/img/trade-department/products/textile/carpets/lpo13c.png';
import lpo13cMini1 from '../../assets/img/trade-department/products/textile/carpets/lpo13c-mini-1.png';
import lpo13cMini2 from '../../assets/img/trade-department/products/textile/carpets/lpo13c-mini-2.png';

import lpp07c from '../../assets/img/trade-department/products/textile/carpets/lpp07c.png';
import lpp07cMini1 from '../../assets/img/trade-department/products/textile/carpets/lpp07c-mini-1.png';

import lhe31b from '../../assets/img/trade-department/products/textile/carpets/lhe31b.png';
import lhe31bMini1 from '../../assets/img/trade-department/products/textile/carpets/lhe31b-mini-1.png';
import lhe31bMini2 from '../../assets/img/trade-department/products/textile/carpets/lhe31b-mini-2.png';

import lik09a from '../../assets/img/trade-department/products/textile/carpets/lik09a.png';
import lik09aMini1 from '../../assets/img/trade-department/products/textile/carpets/lik09a-mini-1.png';
import lik09aMini2 from '../../assets/img/trade-department/products/textile/carpets/lik09a-mini-2.png';

import llo01a from '../../assets/img/trade-department/products/textile/carpets/llo01a.png';
import llo01aMini1 from '../../assets/img/trade-department/products/textile/carpets/llo01a-mini-1.png';
import llo01aMini2 from '../../assets/img/trade-department/products/textile/carpets/llo01a-mini-2.png';

import llo04b from '../../assets/img/trade-department/products/textile/carpets/llo04b.png';
import llo04bMini1 from '../../assets/img/trade-department/products/textile/carpets/llo04b-mini-1.png';

import lpa04d from '../../assets/img/trade-department/products/textile/carpets/lpa04d.png';
import lpa04dMini1 from '../../assets/img/trade-department/products/textile/carpets/lpa04d-mini-1.png';
import lpa04dMini2 from '../../assets/img/trade-department/products/textile/carpets/lpa04d-mini-2.png';

import lpa07c from '../../assets/img/trade-department/products/textile/carpets/lpa07c.png';
import lpa07cMini1 from '../../assets/img/trade-department/products/textile/carpets/lpa07c-mini-1.png';
import lpa07cMini2 from '../../assets/img/trade-department/products/textile/carpets/lpa07c-mini-2.png';

import leg15a from '../../assets/img/trade-department/products/textile/carpets/leg15a.png';

import lkd12a from '../../assets/img/trade-department/products/textile/carpets/lkd12a.png';

import lkd13a from '../../assets/img/trade-department/products/textile/carpets/lkd13a.png';

import lkd14a from '../../assets/img/trade-department/products/textile/carpets/lkd14a.png';

import lkd15a from '../../assets/img/trade-department/products/textile/carpets/lkd15a.png';

import lkd19a from '../../assets/img/trade-department/products/textile/carpets/lkd19a.png';

import lkd23c from '../../assets/img/trade-department/products/textile/carpets/lkd23c.png';

import lkd24b from '../../assets/img/trade-department/products/textile/carpets/lkd24b.png';

import lkd26b from '../../assets/img/trade-department/products/textile/carpets/lkd26b.png';

import lkd29a from '../../assets/img/trade-department/products/textile/carpets/lkd29a.png';

// CURTAINS

import lc6783 from '../../assets/img/trade-department/products/textile/curtains/lc-6783.png';
import lc6784 from '../../assets/img/trade-department/products/textile/curtains/lc-6784.png';
import lc6785 from '../../assets/img/trade-department/products/textile/curtains/lc-6785.png';
import lc6786 from '../../assets/img/trade-department/products/textile/curtains/lc-6786.png';
import lc6787 from '../../assets/img/trade-department/products/textile/curtains/lc-6787.png';
import lc67872 from '../../assets/img/trade-department/products/textile/curtains/lc-6787-2.png';
import lc6788 from '../../assets/img/trade-department/products/textile/curtains/lc-6788.png';
import lc6789 from '../../assets/img/trade-department/products/textile/curtains/lc-6789.png';
import lc6790 from '../../assets/img/trade-department/products/textile/curtains/lc-6790.png';
import lc6791 from '../../assets/img/trade-department/products/textile/curtains/lc-6791.png';

const FurnitureFabricsData = () => {
  return [
    {
      title: 'LFF - 4820',
      pattern: 'Geometric',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      content: '58%PES 42%CO',
      color: '6',
      width: '155 cm',
      cover: lff4820,
      miniPhoto: [
        lff4820Mini1,
        lff4820Mini2,
        lff4820Mini3,
        lff4820Mini4,
        lff4820Mini5,
        lff4820Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4821',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '4',
      content: '%46LI %29VI %22CO %3PES',
      width: '140 cm',
      cover: lff4821,
      miniPhoto: [lff4821Mini1, lff4821Mini2, lff4821Mini3, lff4821Mini4],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4822',
      pattern: 'Baklava',
      fabric: 'Jacquard',
      color: '6',
      usage: 'Upholstery',
      content: '58%PES 42%CO',
      width: '155 cm',
      cover: lff4822,
      miniPhoto: [
        lff4822Mini1,
        lff4822Mini2,
        lff4822Mini3,
        lff4822Mini4,
        lff4822Mini5,
        lff4822Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4823',
      pattern: 'Textured',
      fabric: 'Jacquard',
      color: '32',
      usage: 'Upholstery',
      content: '58%PES 42%CO',
      width: '155 cm',
      cover: lff4823,
      miniPhoto: [lff4823Mini1, lff4823Mini2],
      photoType: 'long',
    },
    {
      title: 'LFF - 4824',
      pattern: 'Geometric',
      fabric: 'Jacquard',
      usage: 'Upholstery, Curtain',
      color: '7',
      content: '%65CO %35VI',
      width: '155 cm',
      cover: lff4824,
      miniPhoto: [
        lff4824Mini1,
        lff4824Mini2,
        lff4824Mini3,
        lff4824Mini4,
        lff4824Mini5,
        lff4824Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4825',
      pattern: 'Geometric',
      fabric: 'Velvet',
      usage: 'Upholstery, Curtain',
      color: '34',
      content: '100%PES',
      width: '140 cm',
      cover: lff4825,
      miniPhoto: [lff4825Mini1, lff4825Mini2, lff4825Mini3],
      photoType: 'long',
    },
    {
      title: 'LFF - 4826',
      pattern: '',
      fabric: 'Velvet',
      usage: 'Upholstery, Curtain',
      color: '7',
      content: '100%PES',
      width: '140 cm',
      cover: lff4826,
      miniPhoto: [
        lff4826Mini1,
        lff4826Mini2,
        lff4826Mini3,
        lff4826Mini4,
        lff4826Mini5,
        lff4826Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4827',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '12',
      content: '%61PAN %36PES %3VII',
      width: '140 cm',
      cover: lff4827,
      miniPhoto: [lff4827Mini1, lff4827Mini2],
      photoType: 'long',
    },
    {
      title: 'LFF - 4828',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '4',
      content: '%70 CO & 18 PAN %9VI %3 PES',
      width: '140 cm',
      cover: lff4828,
      miniPhoto: [lff4828Mini1, lff4828Mini2, lff4828Mini3],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4829',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '15',
      content: '%55 VI %30 PES %15 CO',
      width: '140 cm',
      cover: lff4829,
      miniPhoto: [lff4829Mini1, lff4829Mini2],
      photoType: 'long',
    },
    {
      title: 'LFF - 4830',
      pattern: 'Geometric',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '7',
      content: '%90 VI %10 CO',
      width: '145 cm',
      cover: lff4830,
      miniPhoto: [
        lff4830Mini1,
        lff4830Mini2,
        lff4830Mini3,
        lff4830Mini4,
        lff4830Mini5,
        lff4830Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4831',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '14',
      content: '%75 CO %25 VI',
      width: '140 cm',
      cover: lff4831,
      miniPhoto: [lff4831Mini1, lff4831Mini2],
      photoType: 'long',
    },
    {
      title: 'LFF - 4832',
      pattern: 'Baklava',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '5',
      content: '48 % CO 41 % VI 11 % PES',
      width: '142 cm',
      cover: lff4832,
      miniPhoto: [
        lff4832Mini1,
        lff4832Mini2,
        lff4832Mini3,
        lff4832Mini4,
        lff4832Mini5,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4833',
      pattern: 'Geometric',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '4',
      content: '64 % VI 33 % LI 3 % PES',
      width: '140 cm',
      cover: lff4833,
      miniPhoto: [lff4833Mini1, lff4833Mini2, lff4833Mini3, lff4833Mini4],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4834',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery,Curtain',
      color: '57',
      content: '100% PAN',
      width: '140 cm',
      cover: lff4834,
      miniPhoto: [
        lff4834Mini1,
        lff4834Mini2,
        lff4834Mini3,
        lff4834Mini4,
        lff4834Mini5,
        lff4834Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4835',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '6',
      content: ' 35% VI 20% CO 15% PES 10% LI 10% PA 10% PAN',
      width: '140 cm',
      cover: lff4835,
      miniPhoto: [
        lff4835Mini1,
        lff4835Mini2,
        lff4835Mini3,
        lff4835Mini4,
        lff4835Mini5,
        lff4835Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4836',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '5',
      content: '43% LI 34% VI 23% CO',
      width: '140 cm',
      cover: lff4836,
      miniPhoto: [
        lff4836Mini1,
        lff4836Mini2,
        lff4836Mini3,
        lff4836Mini4,
        lff4836Mini5,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4837',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '16',
      content: '68% PAN 28% PES 4% VI',
      width: '142 cm',
      cover: lff4837,
      miniPhoto: [
        lff4837Mini1,
        lff4837Mini2,
        lff4837Mini3,
        lff4837Mini4,
        lff4837Mini5,
        lff4837Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4838',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '5',
      content: '100% PES',
      width: '143 cm',
      cover: lff4838,
      miniPhoto: [
        lff4838Mini1,
        lff4838Mini2,
        lff4838Mini3,
        lff4838Mini4,
        lff4838Mini5,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4839',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '12',
      content: '100% PES',
      width: '140 cm',
      cover: lff4839,
      miniPhoto: [lff4839Mini1, lff4839Mini2],
      photoType: 'long',
    },
    {
      title: 'LFF - 4840',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '5',
      content: '46% PAN 34% PES 20% CO',
      width: '141 cm',
      cover: lff4840,
      miniPhoto: [
        lff4840Mini1,
        lff4840Mini2,
        lff4840Mini3,
        lff4840Mini4,
        lff4840Mini5,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4841',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '5',
      content: '61% PES 25% CO 14% VI',
      width: '140 cm',
      cover: lff4841,
      miniPhoto: [
        lff4841Mini1,
        lff4841Mini2,
        lff4841Mini3,
        lff4841Mini4,
        lff4841Mini5,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4842',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '5',
      content: '61% PES 25% CO 14% VI',
      width: '140 cm',
      cover: lff4842,
      miniPhoto: [
        lff4842Mini1,
        lff4842Mini2,
        lff4842Mini3,
        lff4842Mini4,
        lff4842Mini5,
        lff4842Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4843',
      pattern: 'Straight',
      fabric: 'Jacquard',
      usage: 'Upholstery',
      color: '16',
      content: '65% LI 25% CO 10% VI',
      width: '127 cm',
      cover: lff4843,
      miniPhoto: [lff4843Mini1, lff4843Mini2],
      photoType: 'long',
    },
  ];
};

const LinensData = () => {
  return [
    {
      title: 'LFF - 4720',
      pattern: 'Plain',
      fabric: 'Velvet',
      usage: 'Upholstery, Curtain',
      content: '100% PES',
      color: '34',
      width: '295 cm',
      cover: lff4720,
      miniPhoto: [
        lff4720Mini1,
        lff4720Mini2,
        lff4720Mini3,
        lff4720Mini4,
        lff4720Mini5,
        lff4720Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4721',
      pattern: 'Baklava',
      fabric: 'Jacquard',
      usage: 'Curtain',
      content: '100% PES',
      color: '5',
      width: '310 cm',
      cover: lff4721,
      miniPhoto: [
        lff4721Mini1,
        lff4721Mini2,
        lff4721Mini3,
        lff4721Mini4,
        lff4721Mini5,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4722',
      pattern: 'Geometric',
      fabric: 'Jacquard',
      usage: 'Upholstery, Curtain',
      content: '%59 VI %41 PES',
      color: '5',
      width: '310 cm',
      cover: lff4722,
      miniPhoto: [
        lff4722Mini1,
        lff4722Mini2,
        lff4722Mini3,
        lff4722Mini4,
        lff4722Mini5,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4723',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Upholstery, Curtain',
      content: '50% PES 50% VI',
      color: '8',
      width: '140 cm',
      cover: lff4723,
      miniPhoto: [
        lff4723Mini1,
        lff4723Mini2,
        lff4723Mini3,
        lff4723Mini4,
        lff4723Mini5,
        lff4723Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4724',
      pattern: 'Striped',
      fabric: 'Jacquard',
      usage: 'Tulle',
      content: '95% LI 5% PES',
      color: '3',
      width: '295 cm',
      cover: lff4724,
      miniPhoto: [lff4724Mini1, lff4724Mini2, lff4724Mini3, lff4724Mini4],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4725',
      pattern: 'Textured',
      fabric: 'Weaving',
      usage: 'Tulle',
      content: '100%PES',
      color: '2',
      width: '295 cm',
      cover: lff4725,
      miniPhoto: [lff4725Mini1, lff4725Mini2],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4726',
      pattern: 'Textured',
      fabric: 'Brode',
      usage: 'Tulle',
      content: '100%PES',
      color: '2',
      width: '300 cm',
      cover: lff4726,
      miniPhoto: [lff4726Mini1, lff4726Mini2],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4727',
      pattern: 'Textured',
      fabric: 'Weaving',
      usage: 'Tulle',
      content: '37% PES 25% CO 20% VI 18% RY',
      color: '2',
      width: '295 cm',
      cover: lff4727,
      miniPhoto: [lff4727Mini1, lff4727Mini2, lff4727Mini3, lff4727Mini4],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4728',
      pattern: 'Striped',
      fabric: 'Jacquard',
      usage: 'Curtain',
      content: '88% PES 12% PAN',
      color: '9',
      width: '320 cm',
      cover: lff4728,
      miniPhoto: [
        lff4728Mini1,
        lff4728Mini2,
        lff4728Mini3,
        lff4728Mini4,
        lff4728Mini5,
        lff4728Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4729',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Curtain',
      content: '%55 VI %25 PES %20 CO',
      color: '7',
      width: '300 cm',
      cover: lff4729,
      miniPhoto: [
        lff4729Mini1,
        lff4729Mini2,
        lff4729Mini3,
        lff4729Mini4,
        lff4729Mini5,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4730',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Curtain',
      content: '',
      color: '3',
      width: '',
      cover: lff4730,
      miniPhoto: [lff4730Mini1, lff4730Mini2, lff4730Mini3, lff4730Mini4],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4731',
      pattern: 'Flowering',
      fabric: 'Jacquard',
      usage: 'Curtain',
      content: '99% PES 1% ME',
      color: '6',
      width: '300 cm',
      cover: lff4731,
      miniPhoto: [
        lff4731Mini1,
        lff4731Mini2,
        lff4731Mini3,
        lff4731Mini4,
        lff4731Mini5,
        lff4731Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4732',
      pattern: 'Baklava',
      fabric: 'Jacquard',
      usage: 'Curtain',
      content: '100% PES',
      color: '6',
      width: '310 cm',
      cover: lff4732,
      miniPhoto: [
        lff4732Mini1,
        lff4732Mini2,
        lff4732Mini3,
        lff4732Mini4,
        lff4732Mini5,
        lff4732Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4733',
      pattern: 'Geometric',
      fabric: 'Jacquard',
      usage: 'Upholstery, Curtain',
      content: '100% PES',
      color: '6',
      width: '140 cm',
      cover: lff4733,
      miniPhoto: [
        lff4733Mini1,
        lff4733Mini2,
        lff4733Mini3,
        lff4733Mini4,
        lff4733Mini5,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4734',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Curtain',
      content: '72% RPET 28% PES',
      color: '9',
      width: '140 cm',
      cover: lff4734,
      miniPhoto: [
        lff4734Mini1,
        lff4734Mini2,
        lff4734Mini3,
        lff4734Mini4,
        lff4734Mini5,
        lff4734Mini6,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4735',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Curtain',
      content: '100% PES',
      color: '5',
      width: '300 cm',
      cover: lff4735,
      miniPhoto: [lff4735Mini1],
      photoType: 'long',
    },
    {
      title: 'LFF - 4736',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Curtain',
      content: '100% PES',
      color: '10',
      width: '308 cm',
      cover: lff4736,
      miniPhoto: [
        lff4736Mini1,
        lff4736Mini2,
        lff4736Mini3,
        lff4736Mini4,
        lff4736Mini5,
      ],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4737',
      pattern: 'Striped',
      fabric: 'Jacquard',
      usage: 'Tulle',
      content: '100% PES',
      color: '2',
      width: '142 cm',
      cover: lff4737,
      miniPhoto: [lff4737Mini1, lff4737Mini2],
      photoType: 'wide',
    },
    {
      title: 'LFF - 4738',
      pattern: 'Textured',
      fabric: 'Jacquard',
      usage: 'Curtain',
      content: '100% PES',
      color: '5',
      width: '300 cm',
      cover: lff4738,
      miniPhoto: [lff4738Mini1, lff4738Mini2, lff4738Mini3],
      photoType: 'wide',
    },
  ];
};

const CarpetData = () => {
  return [
    {
      title: 'LXR02B',
      cover: lxr02b,
      textTop: `It will add splendid touches to your new spaces, which contain simplicity and elegance in its modern lines.`,
      miniPhoto: [lxr02bMini1],
    },
    {
      title: 'LXR02A',
      cover: lxr02a,
      textTop: `It will add splendid touches to your new spaces, which contain simplicity and elegance in its modern lines.`,
      miniPhoto: [lxr02aMini1],
    },
    {
      title: 'LBX05A',
      cover: lbx05a,
      textTop: `It will add warm touches to new homes with its modern patterns, unique texture and beautiful colors.`,
      miniPhoto: [],
    },
    {
      title: 'LXR04A',
      cover: lxr04a,
      textTop: `It will add splendid touches to your new spaces, which contain simplicity and elegance in its modern lines.`,
      miniPhoto: [lxr04aMini1],
    },
    {
      title: 'LXR02B',
      cover: lxr02b2,
      textTop: `It will add warm touches to new homes with its modern patterns, unique texture and beautiful colors.`,
      miniPhoto: [lxr02bMini12],
    },
    {
      title: 'LBX09C',
      cover: lbx09c,
      textTop: `It will add warm touches to new homes with its modern patterns, unique texture and beautiful colors.`,
      miniPhoto: [],
    },
    {
      title: 'LBX11A',
      cover: lbx11a,
      textTop: `It will add warm touches to new homes with its modern patterns, unique texture and beautiful colors.`,
      miniPhoto: [],
    },
    {
      title: 'LBX13A',
      cover: lbx13a,
      textTop: `It will add warm touches to new homes with its modern patterns, unique texture and beautiful colors.`,
      miniPhoto: [lbx13aMini1],
    },
    {
      title: 'LVK06A',
      cover: lvk06a,
      textTop: `Taking its nobility from its unique motifs, it will provide a perfect look to your homes with its elegant texture.`,
      miniPhoto: [],
    },
    {
      title: 'LVK08D',
      cover: lvk08d,
      textTop: `Taking its nobility from its unique motifs, it will provide a perfect look to your homes with its elegant texture.`,
      miniPhoto: [lvk08dMini1, lvk08dMini2],
    },
    {
      title: 'LVK13A',
      cover: lvk13a,
      textTop: `Taking its nobility from its unique motifs, it will provide a perfect look to your homes with its elegant texture.`,
      miniPhoto: [],
    },
    {
      title: 'LNO00A',
      cover: lno00a,
      textTop: `It leaves a pleasant mark in homes, embodying the nobility of black in its texture and patterns.`,
      miniPhoto: [],
    },
    {
      title: 'LNO02E',
      cover: lno02e,
      textTop: `It leaves a pleasant mark in homes, embodying the nobility of black in its texture and patterns.`,
      miniPhoto: [lno02eMini1],
    },
    {
      title: 'LNO03A',
      cover: lno03a,
      textTop: `It leaves a pleasant mark in homes, embodying the nobility of black in its texture and patterns.`,
      miniPhoto: [],
    },
    {
      title: 'LNO04A',
      cover: lno04a,
      textTop: `It leaves a pleasant mark in homes, embodying the nobility of black in its texture and patterns.`,
      miniPhoto: [lno04aMini1, lno04aMini2],
    },
    {
      title: 'LNO10D',
      cover: lno10d,
      textTop: `It leaves a pleasant mark in homes, embodying the nobility of black in its texture and patterns.`,
      miniPhoto: [lno10dMini1, lno10dMini2],
    },
    {
      title: 'LPO02A',
      cover: lpo02a,
      textTop: `With its unique motifs, it was inspired by the heart touching lines of a new artist.`,
      miniPhoto: [],
    },
    {
      title: 'LPO04B',
      cover: lpo04b,
      textTop: `With its unique motifs, it was inspired by the heart touching lines of a new artist.`,
      miniPhoto: [],
    },
    {
      title: 'LPO07A',
      cover: lpo07a,
      textTop: `With its unique motifs, it was inspired by the heart touching lines of a new artist.`,
      miniPhoto: [lpo07aMini1],
    },
    {
      title: 'LPO10B',
      cover: lpo10b,
      textTop: `With its unique motifs, it was inspired by the heart touching lines of a new artist.`,
      miniPhoto: [],
    },
    {
      title: 'LPO13C',
      cover: lpo13c,
      textTop: `With its unique motifs, it was inspired by the heart touching lines of a new artist.`,
      miniPhoto: [lpo13cMini1, lpo13cMini2],
    },
    {
      title: 'LPP07C',
      cover: lpp07c,
      textTop: `The new cultural and historical spirit reflected in the designs adds a magnificent touch to the beauty of the spaces.`,
      miniPhoto: [lpp07cMini1],
    },
    {
      title: 'LHE31B',
      cover: lhe31b,
      textTop: `It has become indispensable for elegant homes suitable for all styles and tastes.`,
      miniPhoto: [lhe31bMini1, lhe31bMini2],
    },
    {
      title: 'LIK09A',
      cover: lik09a,
      textTop: `The symbol of nobility, elegance and glamor will change the atmosphere of your home.`,
      miniPhoto: [lik09aMini1, lik09aMini2],
    },
    {
      title: 'LLO01A',
      cover: llo01a,
      textTop: `The collection, which impresses with its elegance while adopting simplicity, adds freshness to spaces.`,
      miniPhoto: [llo01aMini1, llo01aMini2],
    },
    {
      title: 'LLO04B',
      cover: llo04b,
      textTop: `The collection, which impresses with its elegance while adopting simplicity, adds freshness to spaces.`,
      miniPhoto: [llo04bMini1],
    },
    {
      title: 'LPA04D',
      cover: lpa04d,
      textTop: `Its colorful threads and unique motifs are designed to add color to living spaces.`,
      miniPhoto: [lpa04dMini1, lpa04dMini2],
    },
    {
      title: 'LPA07C',
      cover: lpa07c,
      textTop: `Its colorful threads and unique motifs are designed to add color to living spaces.`,
      miniPhoto: [lpa07cMini1, lpa07cMini2],
    },
    {
      title: 'LEG15A',
      cover: leg15a,
      textTop: `Some blue, some earth colors; equips living spaces with the color of peace.`,
      miniPhoto: [],
    },
    {
      title: 'LKD12A',
      cover: lkd12a,
      textTop: `Colorful, vibrant and crazy. The children's collection has everything for collectibles.
      100% Polypropylene`,
      miniPhoto: [],
    },
    {
      title: 'LKD13A',
      cover: lkd13a,
      textTop: `Colorful, vibrant and crazy. The children's collection has everything for collectibles.
      100% Polypropylene`,
      miniPhoto: [],
    },
    {
      title: 'LKD14A',
      cover: lkd14a,
      textTop: `Colorful, vibrant and crazy. The children's collection has everything for collectibles.
      100% Polypropylene`,
      miniPhoto: [],
    },
    {
      title: 'LKD15A',
      cover: lkd15a,
      textTop: `Colorful, vibrant and crazy. The children's collection has everything for collectibles.
      100% Polypropylene`,
      miniPhoto: [],
    },
    {
      title: 'LKD19A',
      cover: lkd19a,
      textTop: `Colorful, vibrant and crazy. The children's collection has everything for collectibles.
      100% Polypropylene`,
      miniPhoto: [],
    },
    {
      title: 'LKD23C',
      cover: lkd23c,
      textTop: `Colorful, vibrant and crazy. The children's collection has everything for collectibles.
      100% Polypropylene`,
      miniPhoto: [],
    },
    {
      title: 'LKD24B',
      cover: lkd24b,
      textTop: `Colorful, vibrant and crazy. The children's collection has everything for collectibles.
      100% Polypropylene`,
      miniPhoto: [],
    },
    {
      title: 'LKD26B',
      cover: lkd26b,
      textTop: `Colorful, vibrant and crazy. The children's collection has everything for collectibles.
      100% Polypropylene`,
      miniPhoto: [],
    },
    {
      title: 'LKD29A',
      cover: lkd29a,
      textTop: `Colorful, vibrant and crazy. The children's collection has everything for collectibles.
      100% Polypropylene`,
      miniPhoto: [],
    },
  ];
};

const CurtainsData = () => {
  return [
    {
      title: 'LC-6783',
      cover: lc6783,
      size: '100 x 260',
      material: 'Polyester',
      pieces: 'One Piece',
      type: 'Cornice',
      color: 'White',
      bottomText: 'Slub Pattern, Jacquard Tulle Curtain, 1/2 Sparse Pleated',
    },
    {
      title: 'LC-6784',
      cover: lc6784,
      size: '100 x 260',
      material: 'Polyester',
      pieces: 'One Piece',
      type: 'Cornice',
      color: 'White',
      bottomText:
        'Sand Pattern Jacquard Tulle Curtain 1/2 Sparse Pleat Dotted Jacquard',
    },
    {
      title: 'LC-6785',
      cover: lc6785,
      size: '100 x 260',
      material: 'Polyester',
      pieces: 'One Piece',
      type: 'Cornice',
      color: 'White',
      bottomText: 'Pasta Glittery Tulle Curtain, High Weight,',
    },
    {
      title: 'LC-6786',
      cover: lc6786,
      size: '100 x 260',
      material: 'Polyester',
      pieces: 'One Piece',
      type: 'Cornice',
      color: 'White',
      bottomText: 'Embroidered Tulle Curtain, Virtual Floor, 1/2 Sparse Pleat',
    },
    {
      title: 'LC-6787',
      cover: lc6787,
      size: '100 x 260',
      material: 'Polyester',
      pieces: 'One Piece',
      type: 'Cornice',
      color: 'White',
      bottomText: 'Black Greek Tulle Curtain - Leaded Skirt, 1/2 Sparse Pleat',
    },
    {
      title: 'LC-6787',
      cover: lc67872,
      size: '100 x 250',
      material: 'Polyester',
      pieces: 'One Piece',
      type: 'Cornice',
      color: 'White',
      bottomText:
        'It is suitable for plug and play use. It is washable, does not fade in the sun, does not contain stains, is produced from imported Pietra fabric.',
    },
    {
      title: 'LC-6788',
      cover: lc6788,
      size: '100 x 250',
      material: 'Polyester',
      pieces: 'One Piece',
      type: 'Cornice',
      color: 'White',
      bottomText:
        'It is suitable for plug and play use. It is washable, does not fade in the sun, does not contain stains, is produced from imported Pietra fabric.',
    },
    {
      title: 'LC-6789',
      cover: lc6789,
      size: '100 x 250',
      material: 'Polyester',
      pieces: 'One Piece',
      type: 'Cornice',
      color: 'White',
      bottomText:
        'It is suitable for plug and play use. It is washable, does not fade in the sun, does not contain stains, is produced from imported Pietra fabric.',
    },
    {
      title: 'LC-6790',
      cover: lc6790,
      size: '100 x 250',
      material: 'Polyester',
      pieces: 'One Piece',
      type: 'Cornice',
      color: 'White',
      bottomText:
        'It is suitable for plug and play use. It is washable, does not fade in the sun, does not contain stains, is produced from imported Pietra fabric.',
    },
    {
      title: 'LC-6791',
      cover: lc6791,
      size: '100 x 250',
      material: 'Polyester',
      pieces: 'One Piece',
      type: 'Cornice',
      color: 'White',
      bottomText:
        'It is suitable for plug and play use. It is washable, does not fade in the sun, does not contain stains, is produced from imported Pietra fabric.',
    },
  ];
};

export { FurnitureFabricsData, LinensData, CarpetData, CurtainsData };
