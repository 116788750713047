import {
  Home,
  GraphicDepartment,
  ContractDepartment,
  DesignDepartment,
  TradeDepartment,
} from '../containers';

const routes = [
  {
    name: 'home',
    path: '/',
    exact: true,
    component: Home,
  },
  {
    name: 'graphicDepartment',
    path: '/graphic-department',
    exact: true,
    component: GraphicDepartment,
  },
  {
    name: 'graphicDepartmentSection',
    path: '/graphic-department/:section',
    exact: true,
    component: GraphicDepartment,
  },
  {
    name: 'contractDepartment',
    path: '/contract-department',
    exact: true,
    component: ContractDepartment,
  },
  {
    name: 'contractDepartmentDetail',
    path: '/contract-department/:detail',
    exact: true,
    component: ContractDepartment,
  },
  {
    name: 'designDepartment',
    path: '/design-department',
    exact: true,
    component: DesignDepartment,
  },
  {
    name: 'designDepartmentSection',
    path: '/design-department/:section',
    exact: true,
    component: DesignDepartment,
  },
  {
    name: 'designDepartmentDetail',
    path: '/design-department/:section/:detail',
    exact: true,
    component: DesignDepartment,
  },
  {
    name: 'tradeDepartment',
    path: '/trade-department',
    exact: true,
    component: TradeDepartment,
  },
  {
    name: 'tradeDepartmentSection',
    path: '/trade-department/:section',
    exact: true,
    component: TradeDepartment,
  },
  {
    name: 'tradeDepartmentSection',
    path: '/trade-department/:section/:innerSection',
    exact: true,
    component: TradeDepartment,
  },
  {
    name: 'tradeDepartmentSection',
    path: '/trade-department/:section/:innerSection/:subInnerSection',
    exact: true,
    component: TradeDepartment,
  },
  {
    name: 'tradeDepartmentSection',
    path: '/trade-department/:section/:innerSection/:subInnerSection/:detail',
    exact: true,
    component: TradeDepartment,
  },
];

export default routes;
