import style from './person.module.scss';
import { WhatsappIcon, CircleBottomArrow } from '../../assets/icon';
import { useState } from 'react';
import { useSpring, animated } from 'react-spring';

const Person = ({
  personName,
  title,
  department,
  image,
  subTitle,
  info,
  video,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [styles, api] = useSpring(() => ({
    from: {
      translateY: 1500,
    },
  }));

  return (
    <div className={style.person}>
      <div className={style.left}>
        <img src={image} alt="person" />
        <div className={style.title}>
          <h2>{personName}</h2>
          <h4>{title}</h4>
          <button
            onClick={() => {
              if (isOpen) {
                api({
                  translateY: 1500,
                });
                setOpen(false);
              } else {
                api({
                  translateY: 0,
                });
                setOpen(true);
              }
            }}
          >
            <WhatsappIcon /> WHATSAPP
          </button>
          <animated.button style={{ ...styles }}>
            +90 537 443 28 67
          </animated.button>
        </div>
      </div>
      <div className={style.right}>
        <h3>{department}</h3>
        {subTitle ? <h5>{subTitle}</h5> : null}
        <div className={style.info}>
          <p>{info}</p>
          <CircleBottomArrow />
          <a href="?section=menu">SHOW ME EVERYTHING</a>
          {video ? (
            <>
              <h6>&</h6>
              <a className={style.videoButton} href="?section=video">
                3D Video Renders
              </a>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Person;
