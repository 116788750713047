import style from './button.module.scss';

const MainButton = ({ click, text, link }) => {
  return (
    <>
      {link ? (
        <a className={style.mainButton} href={link}>
          {text}
        </a>
      ) : (
        <button className={style.mainButton} onClick={click}>
          {text}
        </button>
      )}
    </>
  );
};

export default MainButton;
