import style from './designpart.module.scss';
import { BigTitle, CatalogCard, Slider } from '../../components';
import { useHistory } from 'react-router';

const DesignPart = ({ title, link, data }) => {
  const history = useHistory();
  const isDetail = history.location.pathname.split('/')[3];
  const findIndex = data.findIndex(i => i.link === isDetail);

  return (
    <>
      <div className={style.residences}>
        {isDetail ? (
          <Slider
            prev={
              findIndex
                ? `/design-department/${link}/${data[findIndex - 1].link}`
                : false
            }
            next={
              findIndex >= 0 && !(findIndex === data.length - 1)
                ? `/design-department/${link}/${data[findIndex + 1].link}`
                : false
            }
            data={data.filter(i => i.link === isDetail)[0]}
          />
        ) : (
          <div className={style.wrapper}>
            <BigTitle title={title} />
            <div className={style.cardWrapper}>
              {data.map(data => {
                return (
                  <CatalogCard
                    link={`/design-department/${link}/${data.link}`}
                    title={data.title}
                    cover={data.cover}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DesignPart;
