import { useState } from 'react';
import { HamburgerIcon } from '../../assets/icon';
import Logo from '../../assets/img/logo.png';
import style from './header.module.scss';
import { useSpring, animated } from 'react-spring';

const HeaderCard = ({ isOpen, styles, click }) => {
  const [menu, setMenu] = useState();

  const data = value => {
    if (value === 'furniture') {
      return [
        {
          title: 'CONTRACT',
          link: '/trade-department/furniture/contract',
        },
        {
          title: 'HOME',
          link: '/trade-department/furniture/home',
        },
        {
          title: 'OFFICE',
          link: '/trade-department/furniture/office',
        },
      ];
    } else if (value === 'textile') {
      return [
        {
          title: 'CURTAINS',
          link: '/trade-department/textile/curtains',
        },
        {
          title: 'FURNITURE FABRICS',
          link: '/trade-department/textile/furniture-fabrics',
        },
        {
          title: 'LINENS',
          link: '/trade-department/textile/linens',
        },
        {
          title: 'CARPETS',
          link: '/trade-department/textile/carpets',
        },
      ];
    } else if (value === 'construction-materials') {
      return [
        {
          title: 'DRYWALL PRODUCTS',
          link: '/trade-department/material/drywall-products',
        },
        {
          title: 'WINDOW FACADE',
          link: '/trade-department/material/window-facade',
        },
        {
          title: 'FLOORING & WALL',
          link: '/trade-department/material/flooring-wall',
        },
        {
          title: 'DOORS FURNITURE',
          link: '/trade-department/material/doors-furnitures',
        },
      ];
    }
  };

  return (
    <>
      <animated.div
        className={`${style.headerCard} `}
        style={{
          ...styles,
        }}
      >
        <div className={style.head}>
          <h1>Website Map</h1>
          <button onClick={click}>X</button>
        </div>
        <div className={style.menu}>
          <ul>
            <a className={style.title} href="/design-department">
              DESIGN DEPARTMENT
            </a>
            <li>
              <a href="/design-department/hotels">HOTELS</a>
            </li>
            <li>
              <a href="/design-department/residences">RESIDENCES</a>
            </li>
            <li>
              <a href="/design-department/restaurant-cafe">RESTAURANTS</a>
            </li>
            <li>
              <a href="/design-department/office-store">OFFICE & STORE</a>
            </li>
          </ul>
          <ul>
            <a className={style.title} href="/contract-department">
              CONTRACT DEPARTMENT
            </a>
            <li>
              <a href="/contract-department?section=menu">TURNKEY SERVICES</a>
            </li>
          </ul>
          <ul>
            <a className={style.title} href="/trade-department">
              TRADE DEPARTMENT
            </a>
            <li>
              <button onClick={() => setMenu('furniture')}>FURNITURE</button>
            </li>
            <li>
              <button onClick={() => setMenu('textile')}>TEXTILE</button>
            </li>
            <li>
              <button onClick={() => setMenu('construction-materials')}>
                CONSTRUCTION MATERIALS
              </button>
            </li>
            <li>
              <a href="/trade-department/products/lights">LIGHTS</a>
            </li>

            {menu ? (
              <ul className={style.bottomMenu}>
                {menu
                  ? data(menu).map(i => {
                      return (
                        <li>
                          <a href={i.link}>{i.title}</a>
                        </li>
                      );
                    })
                  : null}
              </ul>
            ) : null}
          </ul>
          <ul>
            <a className={style.title} href="/graphic-department">
              GRAPHIC DEPARTMENT
            </a>
            <li>
              <a href="/graphic-department/logo-design">LOGO DESIGN</a>
            </li>
            <li>
              <a href="/graphic-department/printable-design">
                PRINTABLE DESIGN
              </a>
            </li>
            <li>
              <a href="/graphic-department/digital-design">DIGITAL DESIGN</a>
            </li>
            <li>
              <a href="/graphic-department/social-media-design">
                SOCIAL MEDIA DESIGN
              </a>
            </li>
          </ul>
        </div>
      </animated.div>
      {isOpen ? (
        <div onClick={click} className={style.shadowCover}></div>
      ) : null}
    </>
  );
};

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const [styles, api] = useSpring(() => ({
    from: {
      translateY: -1000,
    },
  }));

  return (
    <header className={style.header}>
      <div className={style.wrapper}>
        <a className={style.logo} href={'/'}>
          <img src={Logo} alt="lineare-logo" />
        </a>
        <button
          onClick={() => {
            if (isOpen) {
              api({
                translateY: -1000,
              });
              setOpen(false);
            } else {
              api({
                translateY: 0,
              });
              setOpen(true);
            }
          }}
        >
          <HamburgerIcon />
        </button>
        <HeaderCard
          click={() => {
            setOpen(false);
            api({
              translateY: -1000,
            });
          }}
          styles={styles}
          isOpen={isOpen}
        />
      </div>
    </header>
  );
};

export default Header;
