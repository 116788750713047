import style from './designdepartments.module.scss';
import { Person } from '../../components';
import BoboImage from '../../assets/img/main/bobo.png';
import queryString from 'query-string';
import VideoRender from './VideoRender';

import { DesignPart } from '..';

import ResidencesData from '../DesignPart/ResidencesData';
import OfficeStoreData from '../DesignPart/OfficeStoreData';
import HotelData from '../DesignPart/HotelData';
import RestaurantCafeData from '../DesignPart/RestaurantCafeData';

//COVER
import ResidenceCover from '../../assets/img/design-department/cover/residence.png';
import HotelCover from '../../assets/img/design-department/cover/hotels.png';
import OfficeStoreCover from '../../assets/img/design-department/cover/office-store.png';
import RestaurantCafeCover from '../../assets/img/design-department/cover/restaurant-cafe.png';
import { useHistory } from 'react-router';

const Menu = () => {
  return (
    <div className={style.menu}>
      <div className={style.cardWrapper}>
        <div className={style.top}>
          <a href="/design-department/hotels">
            <h3>HOTELS</h3>
            <img src={HotelCover} alt="logo-design-cover" />
          </a>
          <a href="/design-department/residences" className={style.right}>
            <h3>RESIDENCES</h3>
            <img src={ResidenceCover} alt="logo-design-cover" />
          </a>
        </div>
        <div className={style.bottom}>
          <a
            href="/design-department/restaurant-cafe"
            className={style.bottomLeft}
          >
            <h3>RESTAURANTS & CAFE</h3>
            <img src={RestaurantCafeCover} alt="logo-design-cover" />
          </a>
          <a
            href="/design-department/office-store"
            className={style.bottomRight}
          >
            <h3>OFFICE & STORE</h3>
            <img src={OfficeStoreCover} alt="logo-design-cover" />
          </a>
        </div>
      </div>
    </div>
  );
};

const Data = [
  {
    title: 'RESIDENCES',
    link: 'residences',
    data: ResidencesData,
  },
  {
    title: 'OFFICE & STORE',
    link: 'office-store',
    data: OfficeStoreData,
  },
  {
    title: 'HOTELS',
    link: 'hotels',
    data: HotelData,
  },
  {
    title: 'RESTAURANT & CAFE',
    link: 'restaurant-cafe',
    data: RestaurantCafeData,
  },
];

const DesignDepartment = () => {
  const history = useHistory();
  const isDetail = history.location.pathname.split('/')[2];
  const section = queryString.parse(history.location.search).section;

  const findDetail = ({ value }) => {
    return Data.filter(i => i.link === isDetail)[0];
  };

  return (
    <>
      <div className={style.designDepartment}>
        {isDetail ? (
          <DesignPart
            data={findDetail(isDetail).data}
            title={findDetail(isDetail).title}
            link={findDetail(isDetail).link}
          />
        ) : section === 'menu' ? (
          <Menu />
        ) : section === 'video' ? (
          <VideoRender />
        ) : (
          <Person
            video
            image={BoboImage}
            title={'"Design Manager"'}
            personName={'BOBO'}
            department={'DESIGN DEPARTMENT'}
            info={`"We create a concept or an idea then realize it in the form of a design portfolio. We start with sketches and space configuration, followed by 3D renders, animations and technical drawings. Adding material samples, the project concept is finalized into a complete design "package.`}
          />
        )}
      </div>
    </>
  );
};

export default DesignDepartment;
