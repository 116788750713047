//RECEPTION
import Reception1 from '../../assets/img/trade-department/products/contract/hotel/public-space/reception/reception-1.png';
import Reception2 from '../../assets/img/trade-department/products/contract/hotel/public-space/reception/reception-2.png';
import Reception3 from '../../assets/img/trade-department/products/contract/hotel/public-space/reception/reception-3.png';
import Reception4 from '../../assets/img/trade-department/products/contract/hotel/public-space/reception/reception-4.png';
import Reception5 from '../../assets/img/trade-department/products/contract/hotel/public-space/reception/reception-5.png';
import Reception6 from '../../assets/img/trade-department/products/contract/hotel/public-space/reception/reception-6.png';
import Reception7 from '../../assets/img/trade-department/products/contract/hotel/public-space/reception/reception-7.png';

//DECORATION TOP
import DecorationTop1 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-top-1.png';
import DecorationTop2 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-top-2.png';
import DecorationTop3 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-top-3.png';

//DECORATION BOTTOM
import DecorationBottom1 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-bottom-1.png';
import DecorationBottom2 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-bottom-2.png';
import DecorationBottom3 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-bottom-3.png';
import DecorationBottom4 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-bottom-4.png';
import DecorationBottom5 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-bottom-5.png';
import DecorationBottom6 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-bottom-6.png';
import DecorationBottom7 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-bottom-7.png';
import DecorationBottom8 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-bottom-8.png';
import DecorationBottom9 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-bottom-9.png';
import DecorationBottom10 from '../../assets/img/trade-department/products/contract/hotel/public-space/decoration/decoration-bottom-10.png';

const ReceptionData = () => {
  return {
    title: 'RECEPTION',
    info: `The reception area at most companies is a very welcoming space. it has the ability to act as much more than a transitional place of respite for visitors; in most cases it is the first opportunity to interact with a company and its brand. Layouts and furniture arrangements vary with each company, but at its core a reception area should be inviting and comfortable while leaving a lasting first impression. Here are a few favorite reception areas our teams have helped clients create over the years.`,
    pictures: [
      Reception1,
      Reception2,
      Reception3,
      Reception4,
      Reception5,
      Reception6,
      Reception7,
    ],
  };
};

const DecorationData = () => {
  return {
    top: [
      {
        cover: DecorationTop1,
        title: '100% Cotton Fabric',
        text: [
          'In all our canvas paintings, 370gr/m2 100% cotton lined first class canvas fabrics are used. Does not contain substances intended for health. It does not contain PVC, acid or any carcinogenic substance.',
          "100% cotton canvas material uses a long cut from a product. It's about health problems in Rhythm Abstract Painting and all other paintings.",
        ],
      },
      {
        cover: DecorationTop2,
        title: 'First Class Craftsmanship',
        text: [
          'The wooden frames used in the tables are made of first class pine wood. The pine frames used were dried and fired. Wooden frames in Ritim Abstract Painting and all other products have a thickness of 3x3cm.',
          'Thanks to the strong structure of the wooden frame, it maintains its strong structure even when there is any physical blow to the painting.',
        ],
      },
      {
        cover: DecorationTop3,
        title: 'Ultra Definition Printing',
        text: [
          'All high resolution digital printing machines in our machine park are 2020 and 2021 models. It has a print resolution of 2440 DPI. Solvent ink is not used, water-based HP vivera inks are used.',
          'It maintains the same color, light and texture quality for many years. Rhythm Abstract Painting and all other products are not affected by factors such as sun, light, flash.',
        ],
      },
    ],
    bottom: [
      {
        cover: DecorationBottom1,
        text: 'Anyone who is interested in art and abstract paintings and paintings must also be interested in abstract paintings.',
      },
      {
        cover: DecorationBottom2,
        text: 'In general, these works, in which no objects are painted, are also known as artistic works.',
      },
      {
        cover: DecorationBottom3,
        text: 'Valuable works that look like scribbles often come out of the hands of painters.',
      },
      {
        cover: DecorationBottom4,
        text: 'The only aspect that should not be confused with the paintings of famous painters is that there are no paintings by painters alone.',
      },
      {
        cover: DecorationBottom5,
        text: 'These works, which are reflected in both modern and classical styles, add an extremely stylish atmosphere.',
      },
      {
        cover: DecorationBottom6,
        text: 'Each of the abstract paintings, one of the most popular categories, is a unique work of art.',
      },
      {
        cover: DecorationBottom7,
        text: '',
      },
      {
        cover: DecorationBottom8,
        text: '',
      },
      {
        cover: DecorationBottom9,
        text: '',
      },
      {
        cover: DecorationBottom10,
        text: '',
      },
    ],
  };
};

export { ReceptionData, DecorationData };
