import privateVillaCover from '../../assets/img/residences/cover/private-villa-cover.png';
import privateVilla1 from '../../assets/img/residences/private-villa/private-villa-1.png';
import privateVilla2 from '../../assets/img/residences/private-villa/private-villa-2.png';
import privateVilla3 from '../../assets/img/residences/private-villa/private-villa-3.png';
import privateVilla4 from '../../assets/img/residences/private-villa/private-villa-4.png';
import privateVilla5 from '../../assets/img/residences/private-villa/private-villa-5.png';

import privateVilla2Cover from '../../assets/img/residences/cover/private-villa-2-cover.png';

import villaHamadCover from '../../assets/img/residences/cover/villa-hamad-cover.png';
import villaHamad1 from '../../assets/img/residences/villa-hamad/villa-hamad-1.png';
import villaHamad2 from '../../assets/img/residences/villa-hamad/villa-hamad-2.png';
import villaHamad3 from '../../assets/img/residences/villa-hamad/villa-hamad-3.png';
import villaHamad4 from '../../assets/img/residences/villa-hamad/villa-hamad-4.png';
import villaHamad5 from '../../assets/img/residences/villa-hamad/villa-hamad-5.png';

import belgradeWaterfront from '../../assets/img/residences/cover/belgrade-waterfront-cover.png';
import belgradeWaterfront1 from '../../assets/img/residences/belgrade-waterfront/belgrade-waterfront-1.png';
import belgradeWaterfront2 from '../../assets/img/residences/belgrade-waterfront/belgrade-waterfront-2.png';
import belgradeWaterfront3 from '../../assets/img/residences/belgrade-waterfront/belgrade-waterfront-3.png';
import belgradeWaterfront4 from '../../assets/img/residences/belgrade-waterfront/belgrade-waterfront-4.png';
import belgradeWaterfront5 from '../../assets/img/residences/belgrade-waterfront/belgrade-waterfront-5.png';

import villaKursunluCover from '../../assets/img/residences/cover/villa-kursunlu-cover.png';
import villaKursunlu1 from '../../assets/img/residences/villa-kursunlu/villa-kursunlu-1.png';
import villaKursunlu2 from '../../assets/img/residences/villa-kursunlu/villa-kursunlu-2.png';
import villaKursunlu3 from '../../assets/img/residences/villa-kursunlu/villa-kursunlu-3.png';
import villaKursunlu4 from '../../assets/img/residences/villa-kursunlu/villa-kursunlu-4.png';
import villaKursunlu5 from '../../assets/img/residences/villa-kursunlu/villa-kursunlu-5.png';

import villaMarasCover from '../../assets/img/residences/cover/villa-maras-cover.png';
import villaMaras1 from '../../assets/img/residences/villa-maras/villa-maras-1.png';
import villaMaras2 from '../../assets/img/residences/villa-maras/villa-maras-2.png';
import villaMaras3 from '../../assets/img/residences/villa-maras/villa-maras-3.png';
import villaMaras4 from '../../assets/img/residences/villa-maras/villa-maras-4.png';
import villaMaras5 from '../../assets/img/residences/villa-maras/villa-maras-5.png';

import villaBudvaCover from '../../assets/img/residences/cover/villa-budva-cover.png';
import villaBudva1 from '../../assets/img/residences/villa-budva/villa-budva-1.png';
import villaBudva2 from '../../assets/img/residences/villa-budva/villa-budva-2.png';
import villaBudva3 from '../../assets/img/residences/villa-budva/villa-budva-3.png';
import villaBudva4 from '../../assets/img/residences/villa-budva/villa-budva-4.png';
import villaBudva5 from '../../assets/img/residences/villa-budva/villa-budva-5.png';

import seaPearlCover from '../../assets/img/residences/cover/sea-pearl-cover.png';
import seaPearl1 from '../../assets/img/residences/sea-pearl/sea-pearl-1.png';
import seaPearl2 from '../../assets/img/residences/sea-pearl/sea-pearl-2.png';
import seaPearl3 from '../../assets/img/residences/sea-pearl/sea-pearl-3.png';
import seaPearl4 from '../../assets/img/residences/sea-pearl/sea-pearl-4.png';
import seaPearl5 from '../../assets/img/residences/sea-pearl/sea-pearl-5.png';

import villaBudva2Cover from '../../assets/img/residences/cover/villa-budva-2-cover.png';

import villaCecaCover from '../../assets/img/residences/cover/villa-ceca-cover.png';
import villaCeca1 from '../../assets/img/residences/villa-ceca/villa-ceca-1.png';
import villaCeca2 from '../../assets/img/residences/villa-ceca/villa-ceca-2.png';
import villaCeca3 from '../../assets/img/residences/villa-ceca/villa-ceca-3.png';
import villaCeca4 from '../../assets/img/residences/villa-ceca/villa-ceca-4.png';
import villaCeca5 from '../../assets/img/residences/villa-ceca/villa-ceca-5.png';

import villaSvetiStefan from '../../assets/img/residences/cover/villa-sveti-stefan-cover.png';
import villaSvetiStefan1 from '../../assets/img/residences/villa-sveti-stefan/villa-sveti-stefan-1.png';
import villaSvetiStefan2 from '../../assets/img/residences/villa-sveti-stefan/villa-sveti-stefan-2.png';
import villaSvetiStefan3 from '../../assets/img/residences/villa-sveti-stefan/villa-sveti-stefan-3.png';
import villaSvetiStefan4 from '../../assets/img/residences/villa-sveti-stefan/villa-sveti-stefan-4.png';
import villaSvetiStefan5 from '../../assets/img/residences/villa-sveti-stefan/villa-sveti-stefan-5.png';

import villaQasim from '../../assets/img/residences/cover/villa-qasim-cover.png';
import villaQasim1 from '../../assets/img/residences/villa-qasim/villa-qasim-1.png';
import villaQasim2 from '../../assets/img/residences/villa-qasim/villa-qasim-2.png';
import villaQasim3 from '../../assets/img/residences/villa-qasim/villa-qasim-3.png';
import villaQasim4 from '../../assets/img/residences/villa-qasim/villa-qasim-4.png';
import villaQasim5 from '../../assets/img/residences/villa-qasim/villa-qasim-5.png';

import residenceZlatibor from '../../assets/img/residences/cover/residence-zlatibor-cover.png';

import apartmentBudva from '../../assets/img/residences/cover/apartment-budva-cover.png';

import belgradeApartment from '../../assets/img/residences/cover/belgrade-apartment-cover.png';

import feoConceptResidence from '../../assets/img/residences/cover/feo-concept-residence-cover.png';

import villaAvali from '../../assets/img/residences/cover/villa-avali-cover.png';
import villaAvali1 from '../../assets/img/residences/villa-avali/villa-avali-1.png';
import villaAvali2 from '../../assets/img/residences/villa-avali/villa-avali-2.png';
import villaAvali3 from '../../assets/img/residences/villa-avali/villa-avali-3.png';
import villaAvali4 from '../../assets/img/residences/villa-avali/villa-avali-4.png';
import villaAvali5 from '../../assets/img/residences/villa-avali/villa-avali-5.png';

import privateVilla4Cover from '../../assets/img/residences/cover/private-villa-4-cover.png';
import privateVilla41 from '../../assets/img/residences/private-villa-4/private-villa-4-1.png';
import privateVilla42 from '../../assets/img/residences/private-villa-4/private-villa-4-2.png';
import privateVilla43 from '../../assets/img/residences/private-villa-4/private-villa-4-3.png';
import privateVilla44 from '../../assets/img/residences/private-villa-4/private-villa-4-4.png';
import privateVilla45 from '../../assets/img/residences/private-villa-4/private-villa-4-5.png';

import privateVilla5Cover from '../../assets/img/residences/cover/private-villa-5-cover.png';
import privateVilla51 from '../../assets/img/residences/private-villa-5/private-villa-5-1.png';
import privateVilla52 from '../../assets/img/residences/private-villa-5/private-villa-5-2.png';
import privateVilla53 from '../../assets/img/residences/private-villa-5/private-villa-5-3.png';
import privateVilla54 from '../../assets/img/residences/private-villa-5/private-villa-5-4.png';
import privateVilla55 from '../../assets/img/residences/private-villa-5/private-villa-5-5.png';

const ResidencesData = [
  {
    title: 'PRIVATE VILLA',
    link: 'private-villa',
    cover: privateVillaCover,
    subInfo: 'Serbia 1,500 m² Realized in 2020',
    info: 'This contemporary villa featuring a lush design approach is situated on the banks of the Belica River.',
    images: [
      privateVilla1,
      privateVilla2,
      privateVilla3,
      privateVilla4,
      privateVilla5,
    ],
  },
  {
    title: 'PRIVATE VILLA',
    link: 'private-villa-2',
    cover: privateVilla2Cover,
    subInfo: 'Serbia 1,500 m² Realized in 2020',
    info: 'This contemporary villa featuring a lush design approach is situated on the banks of the Belica River.',
    images: [
      privateVilla1,
      privateVilla2,
      privateVilla3,
      privateVilla4,
      privateVilla5,
    ],
  },
  {
    title: 'VILLA HAMAD',
    link: 'villa-hamad',
    cover: villaHamadCover,
    subInfo: 'Doha, Qatar 1,500 m² Realized in 2019',
    info: 'The luxury design concept is implemented without giving concessions to the usability and sustainability of the space used by its inhabitants.',
    images: [villaHamad1, villaHamad2, villaHamad3, villaHamad4, villaHamad5],
  },
  {
    title: 'BELGRADE WATERFRONT',
    link: 'belgrade-waterfront',
    cover: belgradeWaterfront,
    subInfo: 'Belgrade, Serbia 500 m² Realized in 2020',
    info: 'The luxury design concept is implemented without giving concessions to the usability and sustainability of the space used by its inhabitants.',
    images: [
      belgradeWaterfront1,
      belgradeWaterfront2,
      belgradeWaterfront3,
      belgradeWaterfront4,
      belgradeWaterfront5,
    ],
  },
  {
    title: 'VILLA KURSUNLU',
    link: 'villa-kursunlu',
    cover: villaKursunluCover,
    subInfo: 'Kursunlu, Turkey 625 m² Realized in 2016',
    info: 'This special two-floor villa was designed in a Mediterranean style. While the curved white walls give the house a sense of liveliness and movement, the color white gives it serenity. The design also features wood and blue details. The villa complex includes a curved swimming pool which extends the villa’s concept to the surroundings.',
    images: [
      villaKursunlu1,
      villaKursunlu2,
      villaKursunlu3,
      villaKursunlu4,
      villaKursunlu5,
    ],
  },
  {
    title: 'VILLA MARAS',
    link: 'villa-maras',
    cover: villaMarasCover,
    subInfo: 'Maras, Turkey 3,700 m² Realized in 2019',
    info: `This three-floor villa was designed in a classic style with elements of modern glamour. The villa is one of three in a complex which also features a boutique hotel. The villa's interior is a continuation of its exterior -  it includes classic marble floors, a lobby featuring a central staircase with wrought iron fences, and classic glass and metal chandeliers. The interior also includes mirrors, wood paneling and carefully selected accessories and details.`,
    images: [villaMaras1, villaMaras2, villaMaras3, villaMaras4, villaMaras5],
  },
  {
    title: 'VILLA BUDVA',
    link: 'villa-budva',
    cover: villaBudvaCover,
    subInfo: 'Budva, Montenegro 324 m² Realized in 2017',
    info: 'This private three-floor Mediterranean-style villa, surrounded by picturesque olive and citrus trees, has a beautiful view of the sea and a terrace with an open air swimming pool. The villa was designed in a contemporary style, dominated by earth tones and wood which, combined with hidden lights, create a cozy atmosphere. Brass details add a necessary dose of glamour. ',
    images: [villaBudva1, villaBudva2, villaBudva3, villaBudva4, villaBudva5],
  },
  {
    title: 'VILLA BUDVA',
    link: 'villa-budva-2',
    cover: villaBudva2Cover,
    subInfo: 'Budva, Montenegro 324 m² Realized in 2017',
    info: 'This private three-floor Mediterranean-style villa, surrounded by picturesque olive and citrus trees, has a beautiful view of the sea and a terrace with an open air swimming pool. The villa was designed in a contemporary style, dominated by earth tones and wood which, combined with hidden lights, create a cozy atmosphere. Brass details add a necessary dose of glamour. ',
    images: [villaBudva1, villaBudva2, villaBudva3, villaBudva4, villaBudva5],
  },
  {
    title: 'SEA PEARL',
    link: 'sea-pearl',
    cover: seaPearlCover,
    subInfo: 'Budva, Montenegro 6,000 m² Realized in 2019',
    info: 'Sea Pearl radiates with style and radical design welcoming locals and visitors at the entrance of Budva. The residence provides an amazing view of the surrounding area.',
    images: [seaPearl1, seaPearl2, seaPearl3, seaPearl4, seaPearl5],
  },

  {
    title: 'PRIVATE VILLA',
    link: 'private-villa-3',
    subInfo: 'Budva, Montenegro 3,500 m² Realized in 2016',
    info: 'This residential building featuring apartments on five floors and an underground garage was designed in a contemporary style consisting of straight lines. The façade is dominated by natural stone, wooden panels and glass, while all apartments include spacious terraces, most of them with a sea view.',
    cover: villaCecaCover,
    images: [villaCeca1, villaCeca2, villaCeca3, villaCeca4, villaCeca5],
  },
  {
    title: 'VILLA SVETI STEFAN',
    link: 'villa-sveti-stefan',
    cover: villaSvetiStefan,
    subInfo: 'Budva, Montenegro 624 m² Realized in 2017',
    info: 'The luxury design concept is implemented without giving concessions to the usability and sustainability of the space used by its inhabitants.',
    images: [
      villaSvetiStefan1,
      villaSvetiStefan2,
      villaSvetiStefan3,
      villaSvetiStefan4,
      villaSvetiStefan5,
    ],
  },
  {
    title: 'VILLA QASIM',
    link: 'villa-qasim',
    cover: villaQasim,
    subInfo: 'Mecca, Saudi Arabia 985 m² Realized in 2018',
    info: `The luxury of this villa is conveyed through consistency of design throughout the home, inside and out.
    A clean and refined design, true to the house's architectural elements, is achieved by avoiding too much clutter.`,
    images: [villaQasim1, villaQasim2, villaQasim3, villaQasim4, villaQasim5],
  },
  {
    title: 'PRIVATE VILLA',
    link: 'private-villa-4',
    cover: privateVilla4Cover,
    subInfo: 'Belgrade, Serbia Realized in 2021',
    info: `This residential building featuring apartments on five floors and an underground garage was designed in a contemporary style consisting of straight lines.
    The façade is dominated by natural stone, wooden panels and glass, while all apartments include spacious terraces, most of them with a sea view. `,
    images: [
      privateVilla41,
      privateVilla42,
      privateVilla43,
      privateVilla44,
      privateVilla45,
    ],
  },
  {
    title: 'RESIDENCE ZLATIBOR',
    link: 'residence-zlatibor',
    cover: residenceZlatibor,
    subInfo: 'Budva',
    info: 'This',
    images: [],
  },
  {
    title: 'APARTMENT BUDVA',
    link: 'apartment-budva',
    cover: apartmentBudva,
    subInfo: 'Budva',
    info: 'This',
    images: [],
  },
  {
    title: 'BELGRADE APARTMENT',
    link: 'belgrade-apartment',
    cover: belgradeApartment,
    subInfo: 'Budva',
    info: 'This',
    images: [],
  },
  {
    title: 'FEO CONCEPT RESIDENCE',
    link: 'feo-concept-residence',
    cover: feoConceptResidence,
    subInfo: 'Budva',
    info: 'This',
    images: [],
  },
  {
    title: 'VILLA AVALI',
    link: 'villa-avali',
    cover: villaAvali,
    subInfo: 'Mecca, Saudi Arabia 3,200 m² Realized in 2019',
    info: `The foundation of this Mecca villa’s design lies in embracing history and infusing it with a charismatic spirit of contemporary design.
    What sets this residence apart is a unique balance of the past, present and future, with special attention to details.`,
    images: [villaAvali1, villaAvali2, villaAvali3, villaAvali4, villaAvali5],
  },
  {
    title: 'PRIVATE VILLA',
    link: 'private-villa-5',
    cover: privateVilla5Cover,
    subInfo: 'Belgrade, Serbia Realized in 2021',
    info: `This residential building featuring apartments on five floors and an underground garage was designed in a contemporary style consisting of straight lines.
    The façade is dominated by natural stone, wooden panels and glass, while all apartments include spacious terraces, most of them with a sea view.`,
    images: [
      privateVilla51,
      privateVilla52,
      privateVilla53,
      privateVilla54,
      privateVilla55,
    ],
  },
];

export default ResidencesData;
