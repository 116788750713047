import MassageRoom1 from '../../assets/img/trade-department/products/contract/spa-center/massage-room/massage-room-1.png';
import MassageRoom2 from '../../assets/img/trade-department/products/contract/spa-center/massage-room/massage-room-2.png';
import MassageRoom3 from '../../assets/img/trade-department/products/contract/spa-center/massage-room/massage-room-3.png';
import MassageRoom4 from '../../assets/img/trade-department/products/contract/spa-center/massage-room/massage-room-4.png';
import MassageRoom5 from '../../assets/img/trade-department/products/contract/spa-center/massage-room/massage-room-5.png';
import MassageRoom6 from '../../assets/img/trade-department/products/contract/spa-center/massage-room/massage-room-6.png';
import MassageRoom7 from '../../assets/img/trade-department/products/contract/spa-center/massage-room/massage-room-7.png';
import MassageRoom8 from '../../assets/img/trade-department/products/contract/spa-center/massage-room/massage-room-8.png';
import MassageRoom9 from '../../assets/img/trade-department/products/contract/spa-center/massage-room/massage-room-9.png';
import MassageRoom10 from '../../assets/img/trade-department/products/contract/spa-center/massage-room/massage-room-10.png';

// FOOT SPAS

import FootSpaMain from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/foot-spa-main.png';
import FootSpa1 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/foot-spa-1.png';
import FootSpa2 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/foot-spa-2.png';
import FootSpa3 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/foot-spa-3.png';
import FootSpa4 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/foot-spa-4.png';

// HAMMAM

import HammamMain from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/hammam-main.png';
import Hammam1 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/hammam-1.png';
import Hammam2 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/hammam-2.png';
import Hammam3 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/hammam-3.png';
import Hammam4 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/hammam-4.png';

// ICE ROOM

import IceRoomMain from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/ice-room-main.png';
import IceRoom1 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/ice-room-1.png';
import IceRoom2 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/ice-room-2.png';
import IceRoom3 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/ice-room-3.png';
import IceRoom4 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/ice-room-4.png';

// SALT WET

import SaltWetMain from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/salt-wet-main.png';
import SaltWet1 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/salt-wet-1.png';
import SaltWet2 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/salt-wet-2.png';
import SaltWet3 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/salt-wet-3.png';
import SaltWet4 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/salt-wet-4.png';

// SALT WET

import ShowerMain from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/shower-main.png';
import Shower1 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/shower-1.png';
import Shower2 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/shower-2.png';
import Shower3 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/shower-3.png';
import Shower4 from '../../assets/img/trade-department/products/contract/spa-center/wellness-room/shower-4.png';

// FITNESS

import LNF9001 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9001.png';
import LNF9002 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9002.png';
import LNF9003 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9003.png';
import LNF9004 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9004.png';
import LNF9005 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9005.png';
import LNF9006 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9006.png';
import LNF9007 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9007.png';
import LNF9008 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9008.png';
import LNF9009 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9009.png';
import LNF9010 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9010.png';
import LNF9012 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9012.png';
import LNF9013 from '../../assets/img/trade-department/products/contract/spa-center/fitness/lnf-9013.png';

const MassageRoomData = () => {
  return [
    MassageRoom1,
    MassageRoom2,
    MassageRoom3,
    MassageRoom4,
    MassageRoom5,
    MassageRoom6,
    MassageRoom7,
    MassageRoom8,
    MassageRoom9,
    MassageRoom10,
  ];
};

const WellnessData = () => {
  return [
    {
      title: 'FOOT SPAS',
      pictures: [FootSpa1, FootSpa2, FootSpa3, FootSpa4],
      cover: FootSpaMain,
      info: [
        {
          type: 'title',
          value: '1.HEATED',
        },
        {
          type: 'text',
          value: `Comfort is the number one priority when providing wellness treatments such as a foot spa. Our bespoke tiled foot spas can have an internal heating system built into the seating. This ensures your clients are warm and relaxed during their treatment session.`,
        },
        {
          type: 'title',
          value: '2.TILED',
        },
        {
          type: 'text',
          value: `Choose from an exlensive range of tiling styles, colours and finishes. They are alsı easy to keep clean and need very little maintenance.`,
        },
        {
          type: 'title',
          value: '3.AUTOMADED',
        },
        {
          type: 'text',
          value: `Built into your commercial foot spa will be a range of automated equipment. The foot wells come complate with an automated timed empty and fill treatment programme. Additionally, they can also be flushed at the end of a session.`,
        },
      ],
    },
    {
      title: 'SALT WET ROOM',
      pictures: [SaltWet1, SaltWet2, SaltWet3, SaltWet4],
      cover: SaltWetMain,
      info: [
        {
          type: 'title',
          value: 'HAND BUILT SALT WET ROOMS',
        },
        {
          type: 'text',
          value: `A bespoke salt wet room is quite similar to a standard steam room, but with the addition of salt mixed into the steam. Salt wet rooms have been known to have an equally beneficial effect for some asthma sufferers and certain skin conditions.`,
        },

        {
          type: 'text',
          value: `Wet salt rooms or japanese salt steam rooms usually keep salt blocks behind glass or clear Perspex. Asalt halogenerator then injects the salt into the steam with the addition or aromathreapy scents using mint or eucalyptus to complate the threapy experience.`,
        },
      ],
    },
    {
      title: 'HAMMAM',
      pictures: [Hammam1, Hammam2, Hammam3, Hammam4],
      cover: HammamMain,
      info: [
        {
          type: 'title',
          value: 'HAMMAM SPA DESIGN',
        },
        {
          type: 'text',
          value: `Usually only suitable or larger commercial wellness spas, the Hammam is a range of treatment romms designed to pamper to your clients every need. We have installed Turkish saunas & bespoke Hammam spas for mant high profile clients.`,
        },

        {
          type: 'text',
          value: `Leisurequip can cater for range of commercial clients, including spa facilities, hotels, health clubs and more. We can also insert ourselves seamlessly into your architects plans or design and install a bespoke Hammam spa from concept.`,
        },
      ],
    },
    {
      title: 'ICE ROOM',
      pictures: [IceRoom1, IceRoom2, IceRoom3, IceRoom4],
      cover: IceRoomMain,
      info: [
        {
          type: 'title',
          value: 'CUSTOM MADE ICE ROOM SPAS',
        },
        {
          type: 'text',
          value: `Your custom-made ice room from Lineare will be the centrepiece of any wellness spa. We can design it for you from solid ice or can be a sealed cold room with tile interior.`,
        },

        {
          type: 'text',
          value: `We can also fit blue led lights into the ceiling to add that extra mood dimension to the whole treatment room experience. Additionally, you could opt for a fire and ice spa. The extreme experiences of hot cold are an incredibly popular wellnes combination.`,
        },
      ],
    },
    {
      title: 'FEATURE SHOWER',
      pictures: [Shower1, Shower2, Shower3, Shower4],
      cover: ShowerMain,
      info: [
        {
          type: 'title',
          value: 'BESPOKE FEATURE SHOWERS',
        },
        {
          type: 'text',
          value: `Your custom-made ice room from Lineare will be the centrepiece of any wellness spa. We can design it for you from solid ice or can be a sealed cold room with tile interior.`,
        },

        {
          type: 'text',
          value: `We can also fit blue led lights into the ceiling to add that extra mood dimension to the whole treatment room experience. Additionally, you could opt for a fire and ice spa. The extreme experiences of hot cold are an incredibly popular wellnes combination.`,
        },
      ],
    },
  ];
};

const FitnessData = () => {
  return [
    {
      cover: LNF9001,
      title: 'LNF-9001',
      info: [
        {
          type: 'bigTitle',
          text: 'MEASUREMENTS',
        },
        {
          type: 'text',
          text: [
            `Dimensions (LxWxH)1760 x 785 x 1260 mm (69,3 x 30,9 x 49,6 in)`,
            `Equipment weight92 kg (202,8 lb)`,
            `Running Surface (Length x Width)1430 x 500 mm (56,3 x 19,7 in)`,
            `Running surface height above ground170 mm (6.7 in)`,
          ],
        },
        {
          type: 'title',
          text: 'Electrical specifications',
        },
        {
          type: 'text',
          text: [
            `Motor power continuous duty220 V – 2.5 HP / 110 V – 3.0 HP`,
            `Energy Consumption in-standbyLess than 0.5 W`,
            `Power requirement230 Vac ±10% 50/60 Hz`,
          ],
        },
        {
          type: 'title',
          text: 'User specifications',
        },
        {
          type: 'text',
          text: [`Max user weight140 kg (308.6 lbs)`],
        },
        {
          type: 'title',
          text: 'Performance',
        },
        {
          type: 'text',
          text: [
            `Min Speed0.8 km/h (0.5 mph)`,
            `Max speed20 km/h (12.4 mph)`,
            `Incline (Min)0%`,
            `Incline (Max)12%`,
          ],
        },
      ],
    },
    {
      cover: LNF9002,
      title: 'LNF-9002',
      info: [
        {
          type: 'bigTitle',
          text: 'MEASUREMENTS',
        },
        {
          type: 'text',
          text: [
            `Dimensions (L x W x H) 1140 x 600 x 1360 mm`,
            `Maximum user weight 180 kg`,
          ],
        },
        {
          type: 'title',
          text: 'Electrical specifications',
        },
        {
          type: 'text',
          text: [
            `Standby energy consumption Less than 0.5 W`,
            `Power requirement 100-240 V ac ± 10% 50/60 Hz`,
          ],
        },
        {
          type: 'title',
          text: 'Technical specifications and performance',
        },
        {
          type: 'text',
          text: [
            `Suitable for users whose height is 155-205 cm`,
            `Max resistance at 160 RPM 600 w`,
            `Pedal crank length 172.5mm`,
            `Q factor 170 mm`,
            `Fast Track Controls Yes`,
            `USB Charger Yes`,
          ],
        },
        {
          type: 'title',
          text: 'Connectivity',
        },
        {
          type: 'text',
          text: [
            `Bluetooth 5.0 Yes`,
            `Technogym Live Yes`,
            `Upgrade via Technogym Live App Yes`,
            `100% recyclable packaging size (L x W x H) 1840 x 876 x 364 mm`,
          ],
        },
      ],
    },
    {
      cover: LNF9003,
      title: 'LNF-9003',
      info: [
        {
          type: 'bigTitle',
          text: 'MEASUREMENTS',
        },
        {
          type: 'text',
          text: [
            `Dimensions (LxWxH)  1300 x 770 x 1890 mm`,
            `(51” x 30” x 74”) from workout position`,
          ],
        },

        {
          type: 'text',
          text: [`First step height from the ground 265 mm (10.4”)`],
        },

        {
          type: 'text',
          text: [`Split Step Technology`],
        },

        {
          type: 'text',
          text: [`Courtesy StepTM for easy access  265 mm (10.4”)`],
        },
        {
          type: 'text',
          text: [`ToeSmart Design™ for anti toe pinching`],
        },
        {
          type: 'text',
          text: [`Smart Lateral Footrest`],
        },
        {
          type: 'text',
          text: [`Max user weight (kg | lbs) 180 kg (397 lbs)`],
        },
        {
          type: 'text',
          text: [`Footprint (sqm | sq.f)  1 | 10.7`],
        },
      ],
    },
    {
      cover: LNF9004,
      title: 'LNF-9004',
      info: [
        {
          type: 'text',
          text: [
            `Length (mm | in) 1940 | 76`,
            `Width (mm | in) 690 | 28"`,
            `Height (mm | in) 1760 | 69"`,
          ],
        },
        {
          type: 'bigTitle',
          text: 'MEASUREMENTS',
        },
        {
          type: 'title',
          text: 'Console type',
        },
        {
          type: 'text',
          text: [
            `15.6" console Heart rate monitoring`,
            `Hand Sensor, Polar OH1`,
          ],
        },
        {
          type: 'title',
          text: 'Equipment Weight',
        },
        {
          type: 'text',
          text: [`145 kg | 320 lbs`, `Max user weight (kg | lbs) 160 | 352`],
        },
        {
          type: 'title',
          text: 'Difficulty Levels',
        },
        {
          type: 'text',
          text: [`1-25`],
        },
        {
          type: 'title',
          text: 'Power requirement',
        },
        {
          type: 'text',
          text: [`100-240 Vac ±10% 50-60 Hz`],
        },
        {
          type: 'title',
          text: 'Goal driven workout programs',
        },
        {
          type: 'text',
          text: [
            `21 - Sessions, Routines, Quick Start, Goals (Time, Distance,`,
            `Calories), CPR-CHR, Profiles (6 preset), Create your own, Training`,
            `Zone, Weight Loss, Outdoors, On Trend (Hills; Hi-Low Blocks; Burn`,
            `Out; Cross Training)`,
          ],
        },
      ],
    },
    {
      cover: LNF9005,
      title: 'LNF-9005',
      info: [
        {
          type: 'title',
          text: 'Dimensions',
        },
        {
          type: 'text',
          text: [
            `(L x W x H) 2435 x 629 x 1280 mm (96 x 25 x 50 in)`,
            `Equipment Weight61 kg (134 lb)`,
            `Shipment Weight76 kg (167 lb)`,
          ],
        },

        {
          type: 'title',
          text: 'User specifications',
        },
        {
          type: 'text',
          text: [
            `Max user weight160 kg (352 lbs)`,
            `Min - Max user height150 - 210 cm (4.11 - 6.11 in)`,
          ],
        },
        {
          type: 'title',
          text: 'Performance',
        },
        {
          type: 'text',
          text: [
            `Resistance levelsAir resisted rowing mode - 10 levels,`,
            `additional power mode - 3 levels`,
          ],
        },
        {
          type: 'title',
          text: 'Resistance technology',
        },
        {
          type: 'text',
          text: [
            `MULTIDRIVE TECHNOLOGY™ - patent pending`,
            `Insulation protection IP55`,
          ],
        },
        {
          type: 'title',
          text: 'Training and comfort features',
        },
        {
          type: 'text',
          text: [`Phone HolderHolds phones up to 77.8mm width (3.06 in)`],
        },
      ],
    },
    {
      cover: LNF9006,
      title: 'LNF-9006',
      info: [
        {
          type: 'bigTitle',
          text: 'MEASUREMENTS',
        },
        {
          type: 'text',
          text: [`Length (mm | in) 859 mm | 39 in`],
        },

        {
          type: 'text',
          text: [`Width (mm | in) 1401 mm | 55 in`],
        },

        {
          type: 'text',
          text: [`Height (mm | in) 1498 mm | 58 in`],
        },

        {
          type: 'text',
          text: [`Weight stack (lbs) 65 kg | 130 lbs`],
        },
        {
          type: 'text',
          text: [`Weight Stack Plus (kg | lbs) 95 kg | 130 lbs`],
        },
        {
          type: 'text',
          text: [`Machine weight 194 kg | 428 lbs`],
        },
        {
          type: 'bottomTitle',
          text: `COMPACT DESIGN & COMFORT`,
        },
      ],
    },
    {
      cover: LNF9007,
      title: 'LNF-9007',
      info: [
        {
          type: 'bigTitle',
          text: 'DOUBLE WORKOUT OPTION',
        },
        {
          type: 'paragraph',
          text: `The Row and Lat Pull Down modules feature two pulleys that can be used separately, for a more challenging workout, or together, using the 2-1- connector accessory.`,
        },
        {
          type: 'bigTitle',
          text: 'Accessories within reach',
        },
        {
          type: 'paragraph',
          text: `All CABLE STATIONS are provided with accessories holders to house different tools close by. Each CABLE STATION base unit is equipped with four accessories holders, one in each corner. Specific accessories are included with each product.`,
        },
        {
          type: 'bottomTitle',
          text: `A FLEXIBLE SOLUTION`,
        },
      ],
    },
    {
      cover: LNF9008,
      title: 'LNF-9008',
      info: [
        {
          type: 'bigTitle',
          text: 'MEASUREMENTS',
        },
        {
          type: 'text',
          text: [`Length (mm | in) 2760 | 109`],
        },
        {
          type: 'text',
          text: [`Width (mm | in) 2721 | 107`],
        },
        {
          type: 'text',
          text: [`Height (mm | in) 2055 | 81`],
        },
        {
          type: 'text',
          text: [`Press/Overhead Press - Weight stack (kg | lbs) 60 | 120`],
        },
        {
          type: 'text',
          text: [`Minimum operational space (mq | sqf) 9,4 | 101`],
        },
        {
          type: 'text',
          text: [`Machine total weight (kg | lbs) 605 | 1334`],
        },
        {
          type: 'text',
          text: [`High/Low Pull - Weight stack (kg | lbs) 70 | 140`],
        },
        {
          type: 'text',
          text: [`Footprint (sqm | sq.f) 7,4 | 80`],
        },
        {
          type: 'text',
          text: [`Core/Leg Extension/Curl - Weight stack (kg | lbs) 60 | 120`],
        },
      ],
    },
    {
      cover: LNF9009,
      title: 'LNF-9009',
      info: [
        {
          type: 'bigTitle',
          text: 'MEASUREMENTS',
        },
        {
          type: 'text',
          text: [`Length (mm | in) 1500 | 59`],
        },
        {
          type: 'text',
          text: [`Width (mm | in) 1200 | 47`],
        },
        {
          type: 'text',
          text: [`Height (mm | in) 1715 | 67`],
        },
        {
          type: 'text',
          text: [`Weight (kg | lbs) 164 | 362`],
        },
        {
          type: 'text',
          text: [`Max Load (kg | lbs) 200 | 441`],
        },
        {
          type: 'bottomTitle',
          text: `Equipment that makes stronger, keeps safer, lasts longer.`,
        },
      ],
    },
    {
      cover: LNF9010,
      title: 'LNF-9010',
      info: [
        {
          type: 'bigTitle',
          text: 'MEASUREMENTS',
        },
        {
          type: 'text',
          text: [`Length (mm | in) 1300 | 51`],
        },
        {
          type: 'text',
          text: [`Width (mm | in) 1680 | 66`],
        },
        {
          type: 'text',
          text: [`Height (mm | in) 2460 | 97`],
        },
        {
          type: 'text',
          text: [`Weight (kg | lbs) 230 | 507`],
        },
        {
          type: 'text',
          text: [`Total Weight (kg | lbs) 380 | 838`],
        },
        {
          type: 'bottomTitle',
          text: `Comfort, safety and durability for top performance`,
        },
      ],
    },
    {
      cover: LNF9012,
      title: 'LNF-9012',
      info: [
        {
          type: 'bigTitle',
          text: 'TECHNICAL SPECIFICATIONS',
        },
        {
          type: 'paragraph',
          text: `With its 2080 mm depth, 890 mm width and 1250 mm height, FLEXABILITY POSTERIOR is equipped with a headrest cushion to ensure the most comfortable and one that is ergonomically correct. All the muscles involved are those of the column, lower back,
          Glutei, Semitendinei, Biceps femoral, Periarticular hip, Gastrocnemius, Soleo, Flexoros insoles.`,
        },
        {
          type: 'bottomTitle',
          text: `SAFE AND EFFECTIVE EXERCISES USING STRETCHING MACHINES`,
        },
      ],
    },
    {
      cover: LNF9013,
      title: 'LNF-9013',
      info: [
        {
          type: 'bigTitle',
          text: 'MEDICINE BALL',
        },
        {
          type: 'paragraph',
          text: `Medicine Ball is the ideal tool for functional exercises. Experience better stability while working out thanks to the ball’s even weight distribution.`,
        },
        {
          type: 'bottomTitle',
          text: `Design and sturdiness`,
        },
      ],
    },
  ];
};

export { MassageRoomData, WellnessData, FitnessData };
