import partnerImage1 from '../../assets/img/main/partners/partner-1.png';
import partnerImage2 from '../../assets/img/main/partners/partner-2.png';
import partnerImage3 from '../../assets/img/main/partners/partner-3.png';
import partnerImage4 from '../../assets/img/main/partners/partner-4.png';
import partnerImage5 from '../../assets/img/main/partners/partner-5.png';
import partnerImage6 from '../../assets/img/main/partners/partner-6.png';
import partnerImage7 from '../../assets/img/main/partners/partner-7.png';
import partnerImage8 from '../../assets/img/main/partners/partner-8.png';
import partnerImage9 from '../../assets/img/main/partners/partner-9.png';
import partnerImage10 from '../../assets/img/main/partners/partner-10.png';
import partnerImage11 from '../../assets/img/main/partners/partner-11.png';
import partnerImage12 from '../../assets/img/main/partners/partner-12.png';
import partnerImage13 from '../../assets/img/main/partners/partner-13.png';
import partnerImage14 from '../../assets/img/main/partners/partner-14.png';
import partnerImage15 from '../../assets/img/main/partners/partner-15.png';
import partnerImage16 from '../../assets/img/main/partners/partner-16.png';
import partnerImage17 from '../../assets/img/main/partners/partner-17.png';
import partnerImage18 from '../../assets/img/main/partners/partner-18.png';
import partnerImage19 from '../../assets/img/main/partners/partner-19.png';
import partnerImage20 from '../../assets/img/main/partners/partner-20.png';
import partnerImage21 from '../../assets/img/main/partners/partner-21.png';
import partnerImage22 from '../../assets/img/main/partners/partner-22.png';
import partnerImage23 from '../../assets/img/main/partners/partner-23.png';
import partnerImage24 from '../../assets/img/main/partners/partner-24.png';
import partnerImage25 from '../../assets/img/main/partners/partner-25.png';
import partnerImage26 from '../../assets/img/main/partners/partner-26.png';
import partnerImage27 from '../../assets/img/main/partners/partner-27.png';
import partnerImage28 from '../../assets/img/main/partners/partner-28.png';
import partnerImage29 from '../../assets/img/main/partners/partner-29.png';
import partnerImage30 from '../../assets/img/main/partners/partner-30.png';
import partnerImage31 from '../../assets/img/main/partners/partner-31.png';
import partnerImage32 from '../../assets/img/main/partners/partner-32.png';
import partnerImage33 from '../../assets/img/main/partners/partner-33.png';

const PartnerData = [
  partnerImage1,
  partnerImage2,
  partnerImage3,
  partnerImage4,
  partnerImage5,
  partnerImage6,
  partnerImage7,
  partnerImage8,
  partnerImage9,
  partnerImage10,
  partnerImage11,
  partnerImage12,
  partnerImage13,
  partnerImage14,
  partnerImage15,
  partnerImage16,
  partnerImage17,
  partnerImage18,
  partnerImage19,
  partnerImage20,
  partnerImage21,
  partnerImage22,
  partnerImage23,
  partnerImage24,
  partnerImage25,
  partnerImage26,
  partnerImage27,
  partnerImage28,
  partnerImage29,
  partnerImage30,
  partnerImage31,
  partnerImage32,
  partnerImage33,
];

export default PartnerData;
