import Bars1 from '../../assets/img/trade-department/products/contract/restaurant-bar/bars/bars-1.png';
import Bars2 from '../../assets/img/trade-department/products/contract/restaurant-bar/bars/bars-2.png';
import Bars3 from '../../assets/img/trade-department/products/contract/restaurant-bar/bars/bars-3.png';
import Bars4 from '../../assets/img/trade-department/products/contract/restaurant-bar/bars/bars-4.png';
import Bars5 from '../../assets/img/trade-department/products/contract/restaurant-bar/bars/bars-5.png';
import Bars6 from '../../assets/img/trade-department/products/contract/restaurant-bar/bars/bars-6.png';
import Bars7 from '../../assets/img/trade-department/products/contract/restaurant-bar/bars/bars-7.png';
import Bars8 from '../../assets/img/trade-department/products/contract/restaurant-bar/bars/bars-8.png';

import OpenBuffet1 from '../../assets/img/trade-department/products/contract/restaurant-bar/open-buffet/open-buffet-1.png';
import OpenBuffet2 from '../../assets/img/trade-department/products/contract/restaurant-bar/open-buffet/open-buffet-2.png';
import OpenBuffet3 from '../../assets/img/trade-department/products/contract/restaurant-bar/open-buffet/open-buffet-3.png';
import OpenBuffet4 from '../../assets/img/trade-department/products/contract/restaurant-bar/open-buffet/open-buffet-4.png';
import OpenBuffet5 from '../../assets/img/trade-department/products/contract/restaurant-bar/open-buffet/open-buffet-5.png';
import OpenBuffet6 from '../../assets/img/trade-department/products/contract/restaurant-bar/open-buffet/open-buffet-6.png';

import Lounge1 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-1.jpg';
import Lounge2 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-2.jpg';
import Lounge3 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-3.jpg';
import Lounge4 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-4.jpg';
import Lounge5 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-5.jpg';
import Lounge6 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-6.jpg';
import Lounge7 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-7.jpg';
import Lounge8 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-8.jpg';
import Lounge9 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-9.jpg';
import Lounge10 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-10.jpg';
import Lounge11 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-11.jpg';
import Lounge12 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-12.jpg';
import Lounge13 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-13.jpg';
import Lounge14 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-14.jpg';
import Lounge15 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-15.jpg';
import Lounge16 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-16.jpg';
import Lounge17 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-17.jpg';
import Lounge18 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-18.jpg';
import Lounge19 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-19.jpg';
import Lounge20 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-20.jpg';
import Lounge21 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-21.jpg';
import Lounge22 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-22.jpg';
import Lounge23 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-23.jpg';
import Lounge24 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-24.jpg';
import Lounge25 from '../../assets/img/trade-department/products/contract/restaurant-bar/lounge/lounge-25.jpg';

// TABLE

import Table1 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-1.png';
import Table2 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-2.png';
import Table3 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-3.png';
import Table4 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-4.png';
import Table5 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-5.png';

import TableTop1 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-1.png';
import TableTop2 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-2.png';
import TableTop3 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-3.png';
import TableTop4 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-4.png';
import TableTop5 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-5.png';
import TableTop6 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-6.png';
import TableTop7 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-7.png';
import TableTop8 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-8.png';
import TableTop9 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-9.png';
import TableTop10 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-10.png';
import TableTop11 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-11.png';
import TableTop12 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-12.png';
import TableTop13 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-13.png';
import TableTop14 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-14.png';
import TableTop15 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-15.png';
import TableTop16 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-16.png';
import TableTop17 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-17.png';
import TableTop18 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-18.png';
import TableTop19 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-19.png';
import TableTop20 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-20.png';
import TableTop21 from '../../assets/img/trade-department/products/contract/restaurant-bar/tables/table-top-21.png';

// CHAIRS

import Chairs1 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/chairs-1.png';
import Chairs2 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/chairs-2.png';
import Chairs3 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/chairs-3.png';
import Chairs4 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/chairs-4.png';
import Chairs5 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/chairs-5.png';
import Chairs6 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/chairs-6.png';
import Chairs7 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/chairs-7.png';
import Chairs8 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/chairs-8.png';
import Chairs9 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/chairs-9.png';

// BAR CHAIRS

import BarChairs1 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/bar-chairs-1.png';
import BarChairs2 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/bar-chairs-2.png';
import BarChairs3 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/bar-chairs-3.png';
import BarChairs4 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/bar-chairs-4.png';
import BarChairs5 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/bar-chairs-5.png';
import BarChairs6 from '../../assets/img/trade-department/products/contract/restaurant-bar/chairs/bar-chairs-6.png';

const RestaurantBarsData = () => {
  return [Bars1, Bars2, Bars3, Bars4, Bars5, Bars6, Bars7, Bars8];
};

const OpenBuffetData = () => {
  return [
    OpenBuffet1,
    OpenBuffet2,
    OpenBuffet3,
    OpenBuffet4,
    OpenBuffet5,
    OpenBuffet6,
  ];
};

const RestaurantLoungeData = () => {
  return [
    Lounge1,
    Lounge2,
    Lounge3,
    Lounge4,
    Lounge5,
    Lounge6,
    Lounge7,
    Lounge8,
    Lounge9,
    Lounge10,
    Lounge11,
    Lounge12,
    Lounge13,
    Lounge14,
    Lounge15,
    Lounge16,
    Lounge17,
    Lounge18,
    Lounge19,
    Lounge20,
    Lounge21,
    Lounge22,
    Lounge23,
    Lounge24,
    Lounge25,
  ];
};

const TableData = () => {
  return [
    Table1,
    Table2,
    Table3,
    Table4,
    Table5,
    TableTop1,
    TableTop2,
    TableTop3,
    TableTop4,
    TableTop5,
    TableTop6,
    TableTop7,
    TableTop8,
    TableTop9,
    TableTop10,
    TableTop11,
    TableTop12,
    TableTop13,
    TableTop14,
    TableTop15,
    TableTop16,
    TableTop17,
    TableTop18,
    TableTop19,
    TableTop20,
    TableTop21,
  ];
};

const RestaurantChairData = () => {
  return [
    Chairs1,
    Chairs2,
    Chairs3,
    Chairs4,
    Chairs5,
    Chairs6,
    Chairs7,
    Chairs8,
    Chairs9,

    BarChairs1,
    BarChairs2,
    BarChairs3,
    BarChairs4,
    BarChairs5,
    BarChairs6,
  ];
};

export {
  RestaurantBarsData,
  OpenBuffetData,
  RestaurantLoungeData,
  TableData,
  RestaurantChairData,
};
