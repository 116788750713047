// DRYWALL
import Cprofile from '../../assets/img/trade-department/products/construction-materials/drywall/C-profile.png';
import CornerProfile from '../../assets/img/trade-department/products/construction-materials/drywall/corner-profile.png';
import DCProfile from '../../assets/img/trade-department/products/construction-materials/drywall/DC-profile.png';
import DoorSupport from '../../assets/img/trade-department/products/construction-materials/drywall/door-support.png';
import DUProfile from '../../assets/img/trade-department/products/construction-materials/drywall/DU-profile.png';
import Green from '../../assets/img/trade-department/products/construction-materials/drywall/green.png';
import Red from '../../assets/img/trade-department/products/construction-materials/drywall/red.png';
import UProfile from '../../assets/img/trade-department/products/construction-materials/drywall/U-profile.png';
import White from '../../assets/img/trade-department/products/construction-materials/drywall/white.png';

// FlooringWall
import Marble1 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/marble-1.png';
import Marble2 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/marble-2.png';
import Marble3 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/marble-3.png';
import Marble4 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/marble-4.png';
import Marble5 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/marble-5.png';
import stone1 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/stone-1.png';
import stone2 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/stone-2.png';
import stone3 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/stone-3.png';
import stone4 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/stone-4.png';
import stone5 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/stone-5.png';
import stone6 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/stone-6.png';
import concrete1 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/concrete-1.png';
import concrete2 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/concrete-2.png';
import concrete3 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/concrete-3.png';
import concrete4 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/concrete-4.png';
import concrete5 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/concrete-5.png';
import concrete6 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/concrete-6.png';
import wood1 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/wood-1.png';
import wood2 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/wood-2.png';
import wood3 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/wood-3.png';
import wood4 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/wood-4.png';
import wood5 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/wood-5.png';
import wood6 from '../../assets/img/trade-department/products/construction-materials/flooring-wall/wood-6.png';

// DOORS FURNITURES
import DoorFurniture1 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-1.png';
import DoorFurniture2 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-2.png';
import DoorFurniture3 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-3.png';
import DoorFurniture4 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-4.png';
import DoorFurniture5 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-5.png';
import DoorFurniture6 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-6.png';
import DoorFurniture7 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-7.png';
import DoorFurniture8 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-8.png';
import DoorFurniture9 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-9.png';
import DoorFurniture10 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-10.png';
import DoorFurniture11 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-11.png';
import DoorFurniture12 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-12.png';
import DoorFurniture13 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-13.png';
import DoorFurniture14 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-14.png';
import DoorFurniture15 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-15.png';
import DoorFurniture16 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-16.png';
import DoorFurniture17 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-17.png';
import DoorFurniture18 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-18.png';
import DoorFurniture19 from '../../assets/img/trade-department/products/construction-materials/doors-furnitures/doors-furnitures-19.png';

// WINDOW

import PVC01 from '../../assets/img/trade-department/products/construction-materials/window-facade/pvc-01.png';
import PVC02 from '../../assets/img/trade-department/products/construction-materials/window-facade/pvc-02.png';
import PVC03 from '../../assets/img/trade-department/products/construction-materials/window-facade/pvc-03.png';
import PVCAluminum from '../../assets/img/trade-department/products/construction-materials/window-facade/pvc-aluminum.png';
import Timber from '../../assets/img/trade-department/products/construction-materials/window-facade/timber-window.png';
import WoodAluminum from '../../assets/img/trade-department/products/construction-materials/window-facade/wood-aluminum.png';
import AluminumSlimline from '../../assets/img/trade-department/products/construction-materials/window-facade/aluminum-slimline.png';
import Aluminum01 from '../../assets/img/trade-department/products/construction-materials/window-facade/aluminum-01.png';
import Aluminum02 from '../../assets/img/trade-department/products/construction-materials/window-facade/aluminum-02.png';
import Aluminum03 from '../../assets/img/trade-department/products/construction-materials/window-facade/aluminum-03.png';

const DrywallData = () => {
  return [
    {
      title: 'U PROFILE',
      cover: UProfile,
      info: `U profiles are galvanized steel sheet profiles used in construction of plaster board suspended ceiling and drywall linings systems. Ceiling U profiles are used in construction of; Suspended ceiling and drywall linings by screwing plaster boards on one side. This kind of profiles are 0,5mm  thickness. There is two type of U which has 24 and 27 mm  length of flange.`,
    },
    {
      title: 'C PROFILE',
      cover: Cprofile,
      info: `C profiles are galvanized steel sheet profiles used in construction of plasterboard suspended ceiling and drywall lining systems. Ceiling C profiles are used in construction of; Suspended ceiling and drywall linings by screwing plaster boards on one side. This kind of profiles are 0,5mm and 0.60 mm thickness.`,
    },
    {
      title: 'DU PROFILE',
      cover: DUProfile,
      info: `Wall profiles are galvanized steel sheet profiles used in construction of plaster board partition wall and drywall lining systems. Wall U profiles are used in construction of: Non-load bearing partition wall by screwing plaster boards on both sides.Curtain wall by screwing plaster boards on one side. There is nine sort from this material, their types are, 50, 75 and 100 mm width, 0.50 mm and 0,60 mm thickness and 38x38,30x30 flange.`,
    },
    {
      title: 'DC PROFILE',
      cover: DCProfile,
      info: `Wall profiles are galvanized steel sheet profiles used in construction of plaster board partition wall and curtain wall systems. Wall C profiles are used in construction of: Non-load bearing partition wall by screwing plaster boards on both sides.Drywall linings by screwing plaster boards on one side. There is fourteen sort from this material, their types are 50, 75 and
        100 mm width, 0.50mm and 0.60mm thickness and 47x47, 42x42, 35x35 flange.`,
    },
    {
      title: 'CORNER PROFILE',
      cover: CornerProfile,
      info: `Corner profiles are galvanized steel sheet profiles used on corners in construction of plasterboard partition wall, drywall linings and suspended ceiling systems.This kind of profiles are 0.35mm and  0.40mm thickness.`,
    },
    {
      title: 'DOOR SUPPORT PROFILES AND L BRACKET NUT BOLT SET',
      cover: DoorSupport,
      info: `100 g/m2 galvanized Door Support Profile with a base width of 49 mm and a wall thickness of 2 mm, intended for reinforcing door and window frames in drywall systems. Available in 3 sizes; 50, 75, and 100 mm. L brackets are used for fixing Door Support Profiles onto the existing floor and ceiling finish. Each box contains 50 pieces of L-brackets and 50 pieces of nut-washer-bolt sets. L50, L75, and L100 alternatives are available for different Door Support Profiles.`,
    },
    {
      title: 'White plasterboard (Standard)',
      cover: White,
      info: ` White plasterboard is a plasterboard that is used in the construction of partition walls, dry- linings and suspended ceilings. The core of white plasterboard is made from specially calcined high-purity natural gypsum that gives lightness, hardness, high strength and workability to plasterboard. Both faces of white plasterboard are covered with special paper that gives flexibility and high strength to the plasterboard. Installation of white plasterboard is quick and easy, so it saves labour and time, and greatly reduces construction costs. Walls and suspended ceilings made of white plasterboard are light and flexible.`,
    },
    {
      title: 'Red plasterboard (Improved Core Adhesion at High Temperature)',
      cover: Red,
      info: `Red plasterboard is a plasterboard that is used for construction of partition walls, drywall linings and suspended ceilings where resistance to fire is required. To increase fire resistance, the core of Red plasterboard is enhanced by special fibres and additives. Both faces of Red plasterboard are covered with special paper that gives flexibility and high strength to the plasterboard. Red plasterboard can be used in public buildings such as schools, hospitals, hotels, and commercial and business centres to improve fire safety.`,
    },
    {
      title: 'Green plasterboard (Reduced Water Absorption Rate)',
      cover: Green,
      info: `Green plasterboard, is a plasterboard that is used in the construction of partition walls, drywall linings and suspended ceilings where water and moisture resistance are required. For water and moisture resistance, the core of Green plasterboard is strengthened with water repellent agents. Both faces of Green plasterboard are covered with special paper that gives flexibility and high strength to the plasterboard. Green plasterboard is plasterboard that can be subjected to water and moisture for extended periods and is suitable for bathrooms, toilets, kitchens, dining areas and loft spaces.  Green plasterboard can be covered with tile, ceramic tile and similar materials.`,
    },
  ];
};

const FlooringWallData = () => {
  return [
    {
      title: 'MARBLE',
      pictures: [Marble1, Marble2, Marble3, Marble4, Marble5],
    },
    {
      title: 'STONE',
      pictures: [stone1, stone2, stone3, stone4, stone5, stone6],
    },
    {
      title: 'CONCRETE',
      pictures: [
        concrete1,
        concrete2,
        concrete3,
        concrete4,
        concrete5,
        concrete6,
      ],
    },
    {
      title: 'WOOD',
      pictures: [wood1, wood2, wood3, wood4, wood5, wood6],
    },
  ];
};

const DoorsFurnituresData = () => {
  return [
    DoorFurniture19,
    DoorFurniture18,
    DoorFurniture17,
    DoorFurniture16,
    DoorFurniture15,
    DoorFurniture14,
    DoorFurniture13,
    DoorFurniture12,
    DoorFurniture11,
    DoorFurniture10,
    DoorFurniture9,
    DoorFurniture8,
    DoorFurniture7,
    DoorFurniture6,
    DoorFurniture5,
    DoorFurniture4,
    DoorFurniture3,
    DoorFurniture2,
    DoorFurniture1,
  ];
};

const WindowData = () => {
  return [
    {
      title: 'PVC WINDOW - 01',
      cover: PVC01,
      info: [
        `01-  clean and simple design language`,
        `02-  narrow profile - 110 mm`,
        `03-  perfect statics`,
        `04-  optimal thermal insulation`,
        `05-  multi-chamber construction`,
        `06-  6 chambers in the frame and 5 in the wing`,
        `07-  system adapted to automatic glass bonding and STV technology`,
      ],
      bottomTitle: 'A class of its own',
    },
    {
      title: 'PVC WINDOW - 02',
      cover: PVC02,
      info: [
        `01-  modern profile shape in line with the current trends in architecture`,
        `02-  very narrow profiles on the inside of a building let in even more light`,
        `03-  6-chamber system with 83mm depth of the frame`,
        `04-  narrow profiles, height of the frame with the sash amount to only 103mm`,
        `05-  narrow movable post (106mm) with a centrally located handle`,
        `06-  possibility of using the glass pane sets of up to 54mm`,
        `07-  3 sealing lines`,
        `08-  large variety of colours (acrylcolor, veneer)`,
        `09-  greater rigidity of the window due to the application of the STV`,
      ],
      bottomTitle: '',
    },
    {
      title: 'PVC WINDOW - 03',
      cover: PVC03,
      info: [
        `01-  outstanding break-in resistance`,
        `02-  6 chambers system`,
        `03-  increased static due to the STV*`,
        `04-  excellent sound insulation`,
        `05-  low heat transfer coefficient`,
        `06-  modern design`,
      ],
      bottomTitle: '',
    },
    {
      title: 'PVC-ALUMINUM WINDOW',
      cover: PVCAluminum,
      info: [
        `01-  aluminum cladding`,
        `02-  6-chamber system`,
        `03-  increased stability due to the STV`,
        `04-  additional seals improve acoustic insulation`,
        `05-  large various of colors (Wood Grain Foils, Painting)`,
        `06-  optional additional 3rd seal`,
        `07-  system depth 87 mm`,
      ],
      bottomTitle: 'A perfect combination.',
    },
    {
      title: 'TIMBER WINDOW',
      cover: Timber,
      info: [
        `01-  depth of the system 92mm`,
        `02-  wide range of wood species`,
        `03-  double-chamber glazing`,
        `04-  3 sealing lines`,
        `05-  double drip cap`,
      ],
      bottomTitle: 'Modern system made of wood',
    },
    {
      title: 'WOOD-ALUMINIUM WINDOW',
      cover: WoodAluminum,
      info: [
        ` 01-  rectangular shapes`,
        ` 02-  the sash overlay is flush with the casing surface both, on the inside and outside`,
        ` 03-  depth of the system 124.5 mm`,
        ` 04-  wide range of wood species`,
        ` 05-  three or four glass pane glazing`,
        ` 06-  3 sealing lines`,
        ` 07-  anti-burglary protection`,
        ` 08-  additional thermal insulation material in the sash and casing`,
        ` 09-  Uw from 0.69W/m2K`,
      ],
      bottomTitle: '',
    },
    {
      title: 'ALUMINUM SLIMLINE',
      cover: AluminumSlimline,
      info: [
        `01-  excellent tightness to water penetration and air infiltration`,
        `02-  good thermal insulation Uw from 0.8W/(mK)`,
        `03-  wide range of glazing of up to 81 mm`,
        `04-  structures adjusted to different types of fittings`,
        `05-  narrow profiles`,
      ],
      bottomTitle: '',
    },
    {
      title: 'ALUMINUM WINDOW - 01',
      cover: Aluminum01,
      info: [
        `01-  classic window look`,
        `02-  high degree of thermal protection of the structure resulting from the use of technical solutions of the MB-86 system`,
        `03-  excellent tightness to water penetration and air infiltration`,
        `04-  wide range of glazing of up to 61.5mm`,
        `05-  possibility to manufacture anti-burglary constructions up to RC2 class`,
      ],
      bottomTitle: '',
    },
    {
      title: 'ALUMINUM WINDOW - 02',
      cover: Aluminum02,
      info: [
        `01-  modern design`,
        `02-  windows with Passive House Institute Darmstadt certificates for the versions of SI and Aero`,
        `03-  excellent thermal insulation`,
        `04-  beyond-standard tightness and insulation parameters`,
        `05-  wide range of glazing of up to 81mm`,
        `06-  advanced technological solutions`,
      ],
      bottomTitle:
        'Modern aluminum system dedicated for energy saving & passive architecture.',
    },
    {
      title: 'ALUMINUM WINDOW - 03',
      cover: Aluminum03,
      info: [
        `01-  modern design`,
        `02-  possibility of bending profiles, among others door frames, wings and battens`,
        `03-  close relation to the MB-45 and MB-70 window and door systems`,
        `04-  gaskets made of EPDM synthetic rubber`,
        `05-  thermal insulation`,
        `06-  advanced technological solutions`,
      ],
      bottomTitle: '',
    },
  ];
};

export { DrywallData, FlooringWallData, DoorsFurnituresData, WindowData };
