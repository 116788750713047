import Dina145ApartmentCover from '../../assets/video/145_Dia_Apartment_Design_cover.png';
import Dina145ApartmentVideo from '../../assets/video/145_Dia_Apartment_Design.mp4';

import DuplexApartment160Cover from '../../assets/video/160_Duplex_Apartment_Design_cover.png';
import DuplexApartment160Video from '../../assets/video/160_Duplex_Apartment_Design.mp4';

import VillaCecaCover from '../../assets/video/202_Villa_Ceca_Mobile_cover.png';
import VillaCecaVideo from '../../assets/video/202_Villa_Ceca_Mobile.mp4';

import AzzoGardenHCover from '../../assets/video/Azzo_Garden_Park_H_Mobile_cover.png';
import AzzoGardenHVideo from '../../assets/video/Azzo_Garden_Park_H_Mobile.mp4';

import AzzoGardenGCover from '../../assets/video/Azzo_Garden_Park_G_Mobile_cover.png';
import AzzoGardenGVideo from '../../assets/video/Azzo_Garden_Park_G_Mobile.mp4';

import { PlayIcon } from '../../assets/icon';

import style from './designdepartments.module.scss';
import { useState } from 'react';

const Data = [
  {
    cover: Dina145ApartmentCover,
    video: Dina145ApartmentVideo,
  },
  {
    cover: DuplexApartment160Cover,
    video: DuplexApartment160Video,
  },
  {
    cover: VillaCecaCover,
    video: VillaCecaVideo,
  },
  {
    cover: AzzoGardenHCover,
    video: AzzoGardenHVideo,
  },
  {
    cover: AzzoGardenGCover,
    video: AzzoGardenGVideo,
  },
];

const VideoCard = ({ cover, click }) => {
  return (
    <button onClick={click} className={style.videoCard}>
      <img src={cover} alt="cover" />
      <PlayIcon />
    </button>
  );
};

const PlayVideo = ({ video, click }) => {
  return (
    <>
      <video className={style.video} autoPlay controls>
        <source src={video} type="video/mp4" />
      </video>
      <div onClick={click} className={style.shadowCover}></div>
    </>
  );
};

const VideoRender = () => {
  const [video, setVideo] = useState();

  return (
    <>
      {video ? <PlayVideo video={video} click={() => setVideo()} /> : null}
      <div className={style.videoRender}>
        <div className={style.videoWrapper}>
          {Data.map(i => {
            return (
              <VideoCard click={() => setVideo(i.video)} cover={i.cover} />
            );
          })}
        </div>
        <div className={style.sideTitle}>
          <h1>3D Renders</h1>
        </div>
      </div>
    </>
  );
};

export default VideoRender;
