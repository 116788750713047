import style from './graphicdepartment.module.scss';
import { MainButton, Person } from '../../components';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { RightIcon, InfoBox, RightArrow, LeftArrow } from '../../assets/icon';
import AnilImage from '../../assets/img/main/anil.png';
import { useSpring, animated } from 'react-spring';
import { useState } from 'react';

//MENU
import DigitalDesignCover from '../../assets/img/graphic-department/digital-design.png';
import LogoDesignCover from '../../assets/img/graphic-department/logo-design.png';
import PrintableDesignCover from '../../assets/img/graphic-department/printable-design.png';
import SocialMediaDesignCover from '../../assets/img/graphic-department/social-media-design.png';

//LOGO DESIGN
import Logo1 from '../../assets/img/graphic-department/logo-design/logo-1.png';
import Logo2 from '../../assets/img/graphic-department/logo-design/logo-2.png';
import Logo3 from '../../assets/img/graphic-department/logo-design/logo-3.png';
import Logo4 from '../../assets/img/graphic-department/logo-design/logo-4.png';
import Logo5 from '../../assets/img/graphic-department/logo-design/logo-5.png';

//PRINTABLE DESIGN
import BusinessCard from '../../assets/img/graphic-department/printable-design/business-card.png';
import BrochureDesign from '../../assets/img/graphic-department/printable-design/brochure-design.png';
import PosterDesign from '../../assets/img/graphic-department/printable-design/poster-design.png';
import MagazineDesign from '../../assets/img/graphic-department/printable-design/magazine-design.png';
import MenuDesign from '../../assets/img/graphic-department/printable-design/menu-design.png';
import WetWipes from '../../assets/img/graphic-department/printable-design/wet-wipes.png';

//DIGITAL DESIGN
import WebsiteDesign from '../../assets/img/graphic-department/digital-design/website-design.png';
import AppDesign from '../../assets/img/graphic-department/digital-design/app-design.png';
import VideoMontage2 from '../../assets/img/graphic-department/digital-design/video-montage-2.mp4';

//SOCIAL MEDIA DESIGN
import SocialMedia from '../../assets/img/graphic-department/social-media/social-media.png';

const HeadNav = ({ prev, next, title, prevTitle, nextTitle }) => {
  return (
    <div className={style.headNav}>
      {prev ? (
        <a href={`/graphic-department/${prev}`}>
          {prevTitle}
          <LeftArrow />
        </a>
      ) : null}
      <h2>{title}</h2>
      {next ? (
        <a href={`/graphic-department/${next}`}>
          <RightArrow />
          {nextTitle}
        </a>
      ) : null}
    </div>
  );
};

const SocialMediaDesign = () => {
  return (
    <div className={style.socialMediaDesign}>
      <HeadNav
        prev={'digital-design'}
        prevTitle={'DIGITAL DESIGN'}
        title={'SOCIAL MEDIA DESIGN'}
      />
      <div className={`${style.part} ${style.websiteDesign}`}>
        <div className={style.info}>
          <h3>SOCIAL MEDIA DESIGN</h3>
          <div className={style.buttonWrapper}>
            <button>
              <RightIcon />
            </button>
            <MainButton
              link={`/graphic-department`}
              text="CONTACT FOR DESIGN"
            />
          </div>
        </div>
        <img src={SocialMedia} alt="business-card" />
      </div>
    </div>
  );
};

const DigitalDesign = () => {
  return (
    <div className={style.digitalDesign}>
      <HeadNav
        prev={'printable-design'}
        prevTitle={'PRINTABLE DESIGN'}
        title={'DIGITAL DESIGN'}
        next={'social-media-design'}
        nextTitle={'SOCIAL MEDIA DESIGN'}
      />
      <div className={`${style.part} ${style.websiteDesign}`}>
        <div className={style.info}>
          <h3>WEBSITE DESIGN</h3>
          <div className={style.buttonWrapper}>
            <button>
              <RightIcon />
            </button>
            <MainButton
              link={`/graphic-department`}
              text="CONTACT FOR DESIGN"
            />
          </div>
        </div>
        <img src={WebsiteDesign} alt="business-card" />
      </div>
      <div className={`${style.part} ${style.videoMontage}`}>
        <div className={style.info}>
          <h3>VIDEO MONTAGE</h3>
          <div className={style.buttonWrapper}>
            <button>
              <RightIcon />
            </button>
            <MainButton
              link={`/graphic-department`}
              text="CONTACT FOR DESIGN"
            />
          </div>
        </div>
        <video width="400" autoPlay muted>
          <source src={VideoMontage2} type="video/mp4 " />
          Your browser does not support HTML video.
        </video>
      </div>
      <div className={`${style.part} ${style.websiteDesign}`}>
        <div className={style.info}>
          <h3>APP DESIGN</h3>
          <div className={style.buttonWrapper}>
            <button>
              <RightIcon />
            </button>
            <MainButton
              link={`/graphic-department`}
              text="CONTACT FOR DESIGN"
            />
          </div>
        </div>
        <img src={AppDesign} alt="business-card" />
      </div>
    </div>
  );
};

const InfoCard = ({ value, styles, right, flex }) => {
  return (
    <animated.div
      style={{ ...styles }}
      className={`${style.detailCard} ${right ? style.rightAlign : null} `}
    >
      <p
        className={`${flex ? style.flexAlign : null}`}
        dangerouslySetInnerHTML={{ __html: value }}
      ></p>
    </animated.div>
  );
};

const PrintableDesign = () => {
  const [isOpen, setOpen] = useState(false);
  const [styles1, api1] = useSpring(() => ({
    from: {
      translateX: -1000,
    },
  }));
  const [styles2, api2] = useSpring(() => ({
    from: {
      translateX: 1000,
    },
  }));
  const [styles3, api3] = useSpring(() => ({
    from: {
      translateX: -1000,
    },
  }));
  const [styles4, api4] = useSpring(() => ({
    from: {
      translateX: 1000,
    },
  }));

  return (
    <>
      <div className={style.printableDesign}>
        <HeadNav
          prevTitle={'LOGO DESIGN'}
          prev={'logo-design'}
          title={'PRINTABLE DESIGN'}
          next={'digital-design'}
          nextTitle={'DIGITAL DESIGN'}
        />
        <p>
          When our solution partners are happy, we are happy too. Time is more
          valuable than anything else. We follow the printing processes in all
          the works we have designed and make your work easier. We can offer you
          the most advantageous prices with our contracted suppliers.
        </p>
        <div className={`${style.part} ${style.businessCard}`}>
          <div className={style.info}>
            <h3>BUSINESS CARD</h3>
            <div className={style.buttonWrapper}>
              <button>
                <RightIcon />
              </button>
              <MainButton
                link={`/graphic-department`}
                text="CONTACT FOR DESIGN"
              />
              <button
                onClick={() => {
                  if (isOpen === 'business') {
                    api1({
                      translateX: -1000,
                    });
                    setOpen(false);
                  } else {
                    api1({
                      translateX: 0,
                    });
                    setOpen('business');
                  }
                }}
                className={style.infoButton}
              >
                <InfoBox />

                <InfoCard
                  styles={styles1}
                  value={`Size: 82x51mm<br/><br/>
                Paper thickness: 700g<br/><br/>
                Double-Sided Printing<br/><br/>
                Relief Lacquered<br/><br/>
                at least 1000 pcs`}
                />
              </button>
            </div>
          </div>
          <img src={BusinessCard} alt="business-card" />
        </div>
        <div className={`${style.part} ${style.brochureDesign}`}>
          <div className={style.info}>
            <h3>BROCHURE DESIGN</h3>
            <div className={style.buttonWrapper}>
              <button>
                <RightIcon />
              </button>
              <MainButton
                link={`/graphic-department`}
                text="CONTACT FOR DESIGN"
              />
              <button
                onClick={() => {
                  if (isOpen === 'brochure') {
                    api2({
                      translateX: 1000,
                    });
                    setOpen(false);
                  } else {
                    api2({
                      translateX: 0,
                    });
                    setOpen('brochure');
                  }
                }}
                className={style.infoButton}
              >
                <InfoBox />

                <InfoCard
                  styles={styles2}
                  right
                  flex
                  value={`<ul>
                  <h6>TYPE-1</h6>
                  <li>Size: 147x210mm</li>
                  <li>Paper thickness: 135g</li>
                  <li>Double-Sided Printing</li>
                  <li>At least 1000 pcs</li>
                  </ul>
                  <ul>
                  <h6>TYPE-2</h6>
                  <li>Size: 210x297mm</li>
                  <li>Paper thickness: 135g</li>
                  <li>Double-Sided Printing</li>
                  <li>At least 1000 pcs</li>
                  <li>2 Fold</li>
                  </ul>
                  `}
                />
              </button>
            </div>
          </div>
          <img src={BrochureDesign} alt="brochure-design" />
        </div>
        <div className={`${style.part} ${style.posterDesign}`}>
          <div className={style.info}>
            <h3>POSTER DESIGN</h3>
            <div className={style.buttonWrapper}>
              <button>
                <RightIcon />
              </button>
              <MainButton
                link={`/graphic-department`}
                text="CONTACT FOR DESIGN"
              />
              <button
                onClick={() => {
                  if (isOpen === 'poster') {
                    api3({
                      translateX: -1000,
                    });
                    setOpen(false);
                  } else {
                    api3({
                      translateX: 0,
                    });
                    setOpen('poster');
                  }
                }}
                className={style.infoButton}
              >
                <InfoBox />

                <InfoCard
                  styles={styles3}
                  value={`Size: 500x700mm & 700x1000mm<br/><br/>
                  Standart Banner Paper<br/><br/>
                  One-Sided Printing<br/><br/>
                  At least 250 pcs
               `}
                />
              </button>
            </div>
          </div>
          <img src={PosterDesign} alt="poster-design" />
        </div>
        <div className={`${style.part} ${style.magazineDesign}`}>
          <div className={style.info}>
            <h3>MAGAZINE DESIGN</h3>
            <div className={style.buttonWrapper}>
              <button>
                <RightIcon />
              </button>
              <MainButton
                link={`/graphic-department`}
                text="CONTACT FOR DESIGN"
              />
            </div>
          </div>
          <img src={MagazineDesign} alt="magazine-design" />
        </div>
        <div className={`${style.part} ${style.menuDesign}`}>
          <div className={style.info}>
            <h3>MENU DESIGN</h3>
            <div className={style.buttonWrapper}>
              <button>
                <RightIcon />
              </button>
              <MainButton
                link={`/graphic-department`}
                text="CONTACT FOR DESIGN"
              />
            </div>
          </div>
          <img src={MenuDesign} alt="menu-design" />
        </div>
        <div className={`${style.part} ${style.wetWipes}`}>
          <div className={style.info}>
            <h3>CUSTOM PRINTED WET WIPES</h3>
            <div className={style.buttonWrapper}>
              <button>
                <RightIcon />
              </button>
              <MainButton
                link={`/graphic-department`}
                text="CONTACT FOR DESIGN"
              />
              <button
                onClick={() => {
                  if (isOpen === 'wipes') {
                    api4({
                      translateX: 1000,
                    });
                    setOpen(false);
                  } else {
                    api4({
                      translateX: 0,
                    });
                    setOpen('wipes');
                  }
                }}
                className={style.infoButton}
              >
                <InfoBox />

                <InfoCard
                  right
                  styles={styles4}
                  value={`Special design printed, prepared with a solution that is friendly to human health and effective against bacteria at pH 5.5
                  <h6>Product information</h6>
                  <h6>Inner Towel: <span>15x18cm (Nonwoven Towel) 40 gr </span></h6>
                  <h6>Outer Packing: <span>6x12cm</span></h6>
                  <h6>Minimum Quantity: <span>15,000</span></h6>
               `}
                />
              </button>
            </div>
          </div>
          <img src={WetWipes} alt="custom-printed-wet-wipes" />
        </div>
      </div>
    </>
  );
};

const LogoDesign = () => {
  return (
    <div className={style.logoDesign}>
      <HeadNav
        title={'LOGO DESIGN'}
        next={'printable-design'}
        nextTitle={'PRINTABLE DESIGN'}
      />
      <p>
        Corporate identity design is a set of works that make you and your
        business unique, make you unique, customize and personalize you. An
        identity design that is understandable, memorable, intriguing and
        effective for both small and large-scale companies has become a
        necessity rather than an extra in today's world. A design that
        symbolizes, describes, describes and identifies with you allows you to
        create a perception on your business cards, invoices, files, all your
        printed products and in the virtual world, which brings you together
        with the outside world. The perception you create should not be
        short-term, but long-term, sustainable and should be renewed when
        necessary.
        <br />
        <br />
        Your corporate identity design should be classic and permanent, as well
        as in line with trends and innovations. Nobody wants their ID to be
        invalid after 6 months, right? Of course, if there is an identity that
        has completed its life, it is necessary to renew this identity before
        leaving the brand.
      </p>
      <div className={style.logoWrapper}>
        <img src={Logo1} alt="logo-1" />
        <img src={Logo2} alt="logo-2" />
        <img src={Logo3} alt="logo-3" />
        <img src={Logo4} alt="logo-4" />
        <img src={Logo5} alt="logo-5" />
      </div>
      <MainButton link={`/graphic-department`} text="CONTACT FOR DESIGN" />
    </div>
  );
};

const Menu = () => {
  return (
    <div className={style.menu}>
      <h1>DESIGNS TO MAKE YOU STAND OUT.</h1>
      <div className={style.cardWrapper}>
        <div className={style.top}>
          <a href="/graphic-department/logo-design">
            <h3>LOGO DESIGN</h3>
            <img src={LogoDesignCover} alt="logo-design-cover" />
          </a>
          <a
            href="/graphic-department/printable-design"
            className={style.right}
          >
            <h3>PRINTABLE DESIGN</h3>
            <img src={PrintableDesignCover} alt="logo-design-cover" />
          </a>
        </div>
        <div className={style.bottom}>
          <a href="/graphic-department/digital-design">
            <h3>DIGITAL DESIGN</h3>
            <img src={DigitalDesignCover} alt="logo-design-cover" />
          </a>
          <a
            href="/graphic-department/social-media-design"
            className={style.right}
          >
            <h3>SOCIAL MEDIA DESIGN</h3>
            <img src={SocialMediaDesignCover} alt="logo-design-cover" />
          </a>
        </div>
      </div>
    </div>
  );
};

const GraphicDepartment = () => {
  const history = useHistory();
  const section = queryString.parse(history.location.search).section;
  const isDetail = history.location.pathname.split('/')[2];
  console.log(isDetail);

  return (
    <>
      <div className={style.graphicDepartment}>
        {section || isDetail ? null : (
          <Person
            image={AnilImage}
            title={'"Graphic Designer"'}
            personName={'ANIL'}
            department={'GRAPHIC DEPARTMENT'}
            subTitle={`"we build brands"`}
            info={`We are a creative team with a design vision together with our customers who trust our abilities, know the importance of our experience, and sincerely add their own intuition and knowledge to our story.`}
          />
        )}
        {section === 'menu' ? <Menu /> : null}

        {isDetail === 'logo-design' ? (
          <LogoDesign />
        ) : isDetail === 'printable-design' ? (
          <PrintableDesign />
        ) : isDetail === 'digital-design' ? (
          <DigitalDesign />
        ) : isDetail === 'social-media-design' ? (
          <SocialMediaDesign />
        ) : null}
      </div>
    </>
  );
};

export default GraphicDepartment;
