import style from './contractdepartment.module.scss';
import { MainButton, Person } from '../../components';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { BottomArrow, WhiteInfoIcon } from '../../assets/icon';
import EmirImage from '../../assets/img/main/emir.png';
import { useSpring, animated } from 'react-spring';
import { useState } from 'react';

import HospitalityCover from '../../assets/img/contract-department/cover/hospitality.png';
import ResidenceCover from '../../assets/img/contract-department/cover/residence.png';
import RestaurantCover from '../../assets/img/contract-department/cover/restaurant.png';

//DETAIL
import Hospitality from '../../assets/img/contract-department/hospitality.png';
import Residence from '../../assets/img/contract-department/residence.png';
import Restaurant from '../../assets/img/contract-department/restaurant.png';

const Menu = () => {
  return (
    <div className={style.menu}>
      <h1>TURNKEY SERVICES</h1>
      <p>
        For turnkey projects, a proposal is prepared according to the client’s
        specifications, and includes complete construction and delivery,
        releasing the owner of the interactions during the process. The contract
        includes the design of the plans and the complete execution of the work
        up to the delivery of a product which meets the needs and performances
        determined in the contract with the customer.
      </p>
      <div className={style.cardWrapper}>
        <a href="/contract-department/residence">
          RESIDENCE
          <img src={HospitalityCover} alt="hospitality" />
        </a>
        <a href="/contract-department/hospitality">
          HOSPITALITY
          <img src={RestaurantCover} alt="restaurant" />
        </a>
        <a href="/contract-department/restaurant">
          RESTAURANT
          <img src={ResidenceCover} alt="residence" />
        </a>
      </div>
    </div>
  );
};

const DetailCard = ({ value, styles }) => {
  return (
    <animated.div style={{ ...styles }} className={style.detailCard}>
      <p dangerouslySetInnerHTML={{ __html: value }}></p>
    </animated.div>
  );
};

const Detail = ({ data }) => {
  const [isOpen, setOpen] = useState(false);
  const [styles, api] = useSpring(() => ({
    from: {
      translateY: -1000,
    },
  }));

  return (
    <div className={style.detail}>
      <h1>{data.title}</h1>
      <h3>{data.subTitle}</h3>
      <div className={style.image}>
        <img src={data.image} alt="title" />
        <DetailCard value={data.info} styles={styles} />
        {data.info ? (
          <div className={style.infoButton}>
            <BottomArrow />
            <button
              onClick={() => {
                if (isOpen) {
                  api({
                    translateY: -1000,
                  });
                  setOpen(false);
                } else {
                  api({
                    translateY: 0,
                  });
                  setOpen(true);
                }
              }}
            >
              <WhiteInfoIcon />
            </button>
          </div>
        ) : null}
      </div>
      <div className={style.buttonWrapper}>
        <MainButton
          link={'/design-department?section=menu'}
          text="SEE PROJECTS"
        />
        <h5>{'&'}</h5>
        <MainButton
          link={'/trade-department?section=menu'}
          text="ORDER WHAT YOU NEED"
        />
      </div>
    </div>
  );
};

const Data = [
  {
    title: 'RESIDENCE',
    link: 'residence',
    subTitle: `"we equip your home from scratch"`,
    image: Residence,
    info: `<h4>A finished home, ready for you to move into, will usually include:</h4>
    <br/><br/>
    Concept design: Developing your ideas and securing planning permission.
    Full Building Regulations service: Including drawings and obtaining consent for you.
    Service connections: Installation and all liaisons with the utility companies.
    Foundations: Full foundation design and installation (some suppliers may not provide this service).
    Walls, floors and roof: We form the shell of your home using a high performance, green and sustainable timber frame.
    Insulation: If using timber frame this can be factory-installed inside the panels to achieve the thermal performance you require.
    Windows and doors: High-quality external windows and doors, factory glazed and painted your colour choice.
    Roof finishes: Whatever the design dictates, plus fascia, soffits and rainwater goods.
    External cladding: Bricks, stone, render, timber or whatever your design requires.
    Heating and plumbing: Heating systems incorporating renewable sources if required.
    Electrics: First and second fix installation.
    Internal carpentry: Stairs, handrails, skirting and architraves.
    Kitchens and bathrooms: As chosen by you (some suppliers may not provide this service).
    Decoration: Painting and decorating.
    Landscaping: External hard landscaping.`,
  },
  {
    title: 'HOSPITALITY',
    link: 'hospitality',
    subTitle: `"we build the most comfort for your customers"`,
    image: Hospitality,
    info: `From your initial enquiry we will liaise directly with you to produce a concept design. Even the most ambitious or vague concepts can be visualised by our experienced technicians, who can offer their expert advice to ensure you get the building you want. Design can be undertaken fully in-house or we can work with third party architects to combine the flexibility of modular with the aesthetics of architectural design. Wernick Buildings are BIM level 2 compliant.
<br/><br/>
    <h4>External</h4>
    <br/><br/>
    We offer a wide range of external cladding to help your building blend with its surroundings.
    <br/><br/>
    The actual building, though, is only one aspect of the overall aesthetic; the surrounding landscape also plays an important role. We offer a wide range of landscaping services, from play areas to gardens. We will also take care of more functional aspects, for example parking facilities or access roads.
    <br/><br/>
    <h4> Internal</h4>
    <br/><br/>
    Modular construction in no way limits what you can achieve with the interior of your building, and as part of our turnkey service we offer a full range of internal finishes.
    <br/><br/>
    Room layouts can be given added flexibility with the addition of partitioning, including full or half glazed.
    <br/><br/>
    Due to the wide range of sectors we serve, we also offer specialist fittings depending on your needs. Fittings specific to healthcare, childcare or even custodial suites are available. We pride ourselves on accommodating everyone, so please feel free to contact us if you are unsure if we can meet your requirements.`,
  },
  {
    title: 'RESTAURANT',
    link: 'restaurant',
    subTitle: `"build your dream restaurant today "`,
    image: Restaurant,
    info: ``,
  },
];

const ContractDepartment = () => {
  const history = useHistory();
  const section = queryString.parse(history.location.search).section;
  const isDetail = history.location.pathname.split('/')[2];

  return (
    <>
      <div className={style.contractDepartment}>
        {section || isDetail ? null : (
          <Person
            image={EmirImage}
            title={'"Chief Operating Officer"'}
            personName={'EMİR'}
            department={'CONTRACT DEPARTMENT'}
            subTitle={`"we have Turnkey Services"`}
            info={`We create production drawings, bill of quantities, specifications and the entire documentation required for the realization of a project.`}
          />
        )}
        {section === 'menu' && !isDetail ? <Menu /> : null}
        {isDetail ? (
          <Detail data={Data.filter(i => i.link === isDetail)[0]} />
        ) : null}
      </div>
    </>
  );
};

export default ContractDepartment;
