import style from './slider.module.scss';
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import { useState } from 'react';
import { LeftArrow, RightArrow } from '../../assets/icon';

const Slider = ({ data, prev, next }) => {
  const [slide, setSlide] = useState({
    value: 0,
    slides: data.images.map((i, key) => (
      <img onClick={() => slideChange(key)} src={i} alt="detail" />
    )),
  });

  const slideChange = value => {
    setSlide({ ...slide, value });
  };

  return (
    <div className={style.slider}>
      <div className={style.wrapper}>
        <div className={style.head}>
          {prev ? (
            <a className={style.left} href={prev}>
              <LeftArrow />
            </a>
          ) : null}
          <div className={style.info}>
            <h1>{data.title}</h1>
            <h2>{data.subTitle ? data.subTitle : null}</h2>
            <h4>{data.subInfo}</h4>
          </div>
          {next ? (
            <a className={style.right} href={next}>
              <RightArrow />
            </a>
          ) : null}
        </div>
        <h5>{data.info}</h5>
      </div>
      <Carousel
        plugins={[
          'centered',
          'clickToChange',
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 1.4,
            },
          },
        ]}
        offset={30}
        slides={slide.slides}
        value={slide.value}
        onChange={e => slideChange(e)}
        className={style.sliderModule}
      />
    </div>
  );
};

export default Slider;
