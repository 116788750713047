//SOFA
import Sofa1 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-1.png';
import Sofa2 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-2.png';
import Sofa3 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-3.png';
import Sofa4 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-4.png';
import Sofa5 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-5.png';
import Sofa6 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-6.png';
import Sofa7 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-7.png';
import Sofa8 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-8.png';
import Sofa9 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-9.png';
import Sofa10 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-10.png';
import Sofa11 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-11.png';
import Sofa12 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-12.png';
import Sofa13 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-13.png';
import Sofa14 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-14.png';
import Sofa15 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-15.png';
import Sofa16 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-16.png';
import Sofa17 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-17.png';
import Sofa18 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-18.png';
import Sofa19 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-19.png';
import Sofa20 from '../../assets/img/trade-department/products/furniture/home/sofa/sofa-20.png';

//KITCHEN
import SpecialKitchen1 from '../../assets/img/trade-department/products/furniture/home/special-kitchen/special-kitchen-1.png';
import SpecialKitchen2 from '../../assets/img/trade-department/products/furniture/home/special-kitchen/special-kitchen-2.png';
import SpecialKitchen3 from '../../assets/img/trade-department/products/furniture/home/special-kitchen/special-kitchen-3.png';
import SpecialKitchen4 from '../../assets/img/trade-department/products/furniture/home/special-kitchen/special-kitchen-4.png';

//STORAGE
import SpecialStorage1 from '../../assets/img/trade-department/products/furniture/home/special-storage/special-storage-1.png';
import SpecialStorage2 from '../../assets/img/trade-department/products/furniture/home/special-storage/special-storage-2.png';
import SpecialStorage3 from '../../assets/img/trade-department/products/furniture/home/special-storage/special-storage-3.png';
import SpecialStorage4 from '../../assets/img/trade-department/products/furniture/home/special-storage/special-storage-4.png';
import SpecialStorage5 from '../../assets/img/trade-department/products/furniture/home/special-storage/special-storage-5.png';
import SpecialStorage6 from '../../assets/img/trade-department/products/furniture/home/special-storage/special-storage-6.png';
import SpecialStorage7 from '../../assets/img/trade-department/products/furniture/home/special-storage/special-storage-7.png';
import SpecialStorage8 from '../../assets/img/trade-department/products/furniture/home/special-storage/special-storage-8.png';
import SpecialStorage9 from '../../assets/img/trade-department/products/furniture/home/special-storage/special-storage-9.png';
import SpecialStorage10 from '../../assets/img/trade-department/products/furniture/home/special-storage/special-storage-10.png';

//OUTDOOR
import Outdoor1 from '../../assets/img/trade-department/products/furniture/home/outdoor/outdoor-1.png';
import Outdoor2 from '../../assets/img/trade-department/products/furniture/home/outdoor/outdoor-2.png';
import Outdoor3 from '../../assets/img/trade-department/products/furniture/home/outdoor/outdoor-3.png';
import Outdoor4 from '../../assets/img/trade-department/products/furniture/home/outdoor/outdoor-4.png';
import Outdoor5 from '../../assets/img/trade-department/products/furniture/home/outdoor/outdoor-5.png';
import Outdoor6 from '../../assets/img/trade-department/products/furniture/home/outdoor/outdoor-6.png';
import Outdoor7 from '../../assets/img/trade-department/products/furniture/home/outdoor/outdoor-7.png';

const HomeData = value => {
  if (value === 'sofas') {
    return {
      title: 'SOFA',
      link: 'sofas',
      width: 'maxWidth',
      pictures: [
        Sofa1,
        Sofa2,
        Sofa3,
        Sofa4,
        Sofa5,
        Sofa6,
        Sofa7,
        Sofa8,
        Sofa9,
        Sofa10,
        Sofa11,
        Sofa12,
        Sofa13,
        Sofa14,
        Sofa15,
        Sofa16,
        Sofa17,
        Sofa18,
        Sofa19,
        Sofa20,
      ],
    };
  } else if (value === 'special-kitchen-design') {
    return {
      title: 'SPECIAL KITCHEN DESIGN',
      link: 'special-kitchen-design',
      width: 'wide',
      pictures: [
        SpecialKitchen1,
        SpecialKitchen2,
        SpecialKitchen3,
        SpecialKitchen4,
      ],
    };
  } else if (value === 'outdoor') {
    return {
      title: 'OUTDOOR',
      link: 'outdoor',
      width: 'maxWidth',
      pictures: [
        Outdoor1,
        Outdoor2,
        Outdoor3,
        Outdoor4,
        Outdoor5,
        Outdoor6,
        Outdoor7,
      ],
    };
  } else if (value === 'special-storage-design') {
    return {
      title: 'SPECIAL STORAGE DESIGN',
      link: 'special-storage-design',
      width: 'wide',
      pictures: [
        SpecialStorage1,
        SpecialStorage2,
        SpecialStorage3,
        SpecialStorage4,
        SpecialStorage5,
        SpecialStorage6,
        SpecialStorage7,
        SpecialStorage8,
        SpecialStorage9,
        SpecialStorage10,
      ],
    };
  }
};

export default HomeData;
