// HOUSE KEEPING

import LaundryTrolleys from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/house-keeping/laundry-trolleys.png';
import HouseKeepingTrolleys from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/house-keeping/house-keeping-trolleys.png';
import HousekepingTrolleys111 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/house-keeping/house-keeping-trolleys-111.png';
import LaundryTrolleys2 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/house-keeping/house-keeping-trolleys-2.png';
import LobbyCleaningTrolleys111 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/house-keeping/lobby-cleaning-trolleys-111.png';
import LobbyCleaningTrolleys from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/house-keeping/lobby-cleaning-trolleys.png';
import LaundryTrolleys132 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/house-keeping/laundry-trolleys-132.png';
import HouseKeepingTrolleys2 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/house-keeping/house-keeping-trolleys-2.png';

// LUGGAGE

import LuggageTrolleys311 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/luggage/luggage-trolleys-311.png';
import LuggageTrolleys131 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/luggage/luggage-trolleys-131.png';
import LuggageTrolleys411 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/luggage/luggage-trolleys-411.png';
import LuggageTrolleys4112 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/luggage/luggage-trolleys-411-2.png';

// BANQUET
import Chair3030 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/chair-3030.png';
import Chair3034 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/chair-3034.png';
import Chair3036 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/chair-3036.png';
import CasualChair104 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/casual-chair-104.png';
import Chair300 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/chair-300.png';
import Chair308 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/chair-308.png';
import CornerChair250 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/corner-chair-250.png';
import LuckyChair352 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/lucky-chair-352.png';
import PyramidChair158 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/pyramid-chair-158.png';

import BanquetTable1 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/banquet-table-1.png';
import BanquetTable2 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/banquet-table-2.png';
import BanquetTable3 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/banquet-table-3.png';
import BanquetTable4 from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/banquet/banquet-table-4.png';

// KITCHEN
import ColdServiceUnit from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/cold-service-unit.png';
import CookerOven from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/cooker-oven.png';
import Cooker from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/cooker.png';
import DunnageRackPerforated from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/dunnage-rack-perforated.png';
import DunnageRack from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/dunnage-rack.png';
import FlightTypeDishwasher from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/flight-type-dishwasher.png';
import HoodDishwasher from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/hood-dishwasher.png';
import HotService from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/hot-service.png';
import PizzaPreparation from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/pizza-preparation.png';
import SingleSink from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/single-sink.png';
import SinkUnit from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/sink-unit.png';
import StorageShellFlat from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/storage-shelf-flat.png';
import StorageShell from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/storage-shelf.png';
import UndercounterDishwasher from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/undercounter-dishwasher.png';
import UprightRefrigerator from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/upright-refrigerator.png';
import WireStackingRackChrome from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/wire-stacking-rack-chrome.png';
import WireStackingRack from '../../assets/img/trade-department/products/contract/hotel/back-of-the-house/kitchen/wire-stacking-rack.png';

const HouseKeepingData = () => {
  return [
    {
      title: 'LAUNDRY TROLLEYS',
      cover: LaundryTrolleys,
      text: [
        `Dimensions : 1050x550x1835 mm`,
        `DKP+EPC frame`,
        `Two mobile middle shelves`,
        `2 lids at front`,
        `4 pivoting wheels with plastic body`,
        `Weight : 45 kg`,
      ],
    },
    {
      title: 'HOUSE KEEPING TROLLEYS',
      cover: HouseKeepingTrolleys,
      text: [
        `Dimensions : 620x515x1080 mm`,
        `Ø21 mm welded construction`,
        `Cotton / polyester bags`,
        `DKP+EPC or tubular stainless steel construction`,
        `4 pivoting wheels with plastic body and rubber surround. (Brake wheels are optional)`,
        `Press-stud bag`,
        `Plastic disc bumpers`,
        `Standard tube bar colours: metallic gray-cream-black. Colour options must be specified when ordering.`,
        `Weight : 9 kg`,
        `Load Capacity : 120 lt`,
      ],
    },
    {
      title: 'HOUSE KEEPING TROLLEYS - 111',
      cover: HousekepingTrolleys111,
      text: [
        `Dimensions : 955x535x985 mm`,
        `Æ20x20 mm welded construction with laminated shelve panels`,
        `Cotton / polyester bags`,
        `DKP+EPC or tubular stainless steel construction`,
        `Press-stud bag`,
        `Plastic disc bumpers`,
        `Colour options must be specified when ordering.`,
        `Weight : 20 kg`,
        `Load Capacity : 95 lt`,
      ],
    },
    {
      title: 'LAUNDRY TROLLEYS',
      cover: LaundryTrolleys2,
      text: [
        `Dimensions : 970x630x930 mm`,
        `Æ 21 mm welded construction`,
        `Cotton / polyester bags`,
        `DKP+EPC or tubular stainless steel construction`,
        `4 pivoting wheels with plastic body and rubber surround.`,
        `(Brake wheels are optional)`,
        `Press-stud bag`,
        ` Plastic disc bumpers`,
        `Standard tube bar colours: metallic gray-cream-black. Colour options must be specified when ordering.`,
        `Weight : 11,5 kg`,
        `Load Capacity : 260 lt`,
      ],
    },
    {
      title: 'LOBBY CLEANING TROLLEYS - 111',
      cover: LobbyCleaningTrolleys111,
      text: [
        `Dimensions : 705x405x1075 cm`,
        `Dismantling frame`,
        `DKP+EPC frame`,
        `Plastic disc bumpers`,
        `Wheels with plastic body and rubber surround`,
        `Plastic double buckets and press`,
        `Weight : 6,5 kg`,
      ],
    },
    {
      title: 'LOBBY CLEANING TROLLEYS ',
      cover: LobbyCleaningTrolleys,
      text: [
        `Dimensions : 955x535x985 mm`,
        `Æ20x20 mm welded construction with laminated shelve panels`,
        `Cotton / polyester bags`,
        `DKP+EPC or tubular stainless steel construction`,
        `Press-stud bag`,
        `Plastic disc bumpers`,
        `Colour options must be specified when ordering.`,
        `Weight : 20 kg`,
        `Load Capacity : 95 lt`,
      ],
    },
    {
      title: 'LAUNDRY TROLLEYS - 132',
      cover: LaundryTrolleys132,
      text: [
        `Dimensions : 1095x940x920 mm`,
        `DKP+EPC frame`,
        `Polyester body`,
        `Drainage valve`,
        `2 fixed and 2 pivoting wheels`,
        `Weight : 28 kg`,
        `Load Capacity : 500 lt`,
      ],
    },
    {
      title: 'HOUSE KEEPING TROLLEYS',
      cover: HouseKeepingTrolleys2,
      text: [
        `Dimensions : 970x600x1100 mm`,
        `Æ 21 mm welded construction`,
        `Cotton / polyester bags`,
        `DKP+EPC or tubular stainless steel construction`,
        `4 pivoting wheels with plastic body and rubber surround. (Brake wheels are optional)`,
        `Press-stud bag`,
        `Plastic disc bumpers`,
        `Standard tube bar colours: metallic gray-cream-black. Colour options must be specified when ordering.`,
        `Weight : 11,5 kg`,
        `Load Capacity : 250 lt`,
      ],
    },
  ];
};

const LuggageData = () => {
  return [
    {
      title: 'LUGGAGE TROLLEYS 311',
      cover: LuggageTrolleys311,
      text: [
        `• Dimensions : 1120x620x1850 mm`,
        `• Capacity : 5-6 large size, 10-12 small size luggage,`,
        `Wheel Ø200mm, Barrier Ø32mm, Hanger Ø32mm,`,
        `Carrying tubular construction Ø45mm`,
        `• Produced either in solid brass or stainless steel.`,
        `• Surrounded with a plastic protective bumper.`,
        `• Welded lateral bars at two sides for`,
        `higher carrying capacity`,
        `• Complete dismantling tubular frame`,
        `including the hanger`,
        `• Strengthened base with MDF construction`,
        `on steel frame`,
        `• There is a hanger rod for hanging small bags`,
        `• 2 rotating and 2 fixed wheels.`,
        `• Weight : 48 kg`,
      ],
    },
    {
      title: 'LUGGAGE TROLLEYS 411',
      cover: LuggageTrolleys411,
      text: [
        `• Dimensions : 1120x620x1850 mm`,
        `• Capacity : 5-6 large size, 10-12 small size luggage,`,
        `Wheel Ø200mm, Barrier Ø32mm, Hanger`,
        `Ø25mm, Carrying tubular construction Ø45mm`,
        `• Produced either in solid brass or stainless steel.`,
        `• Surrounded with a plastic protective bumper.`,
        `• Dismantling lateral bars at two sides for higher`,
        `carrying capacity`,
        `• Welded lateral bars at two sides`,
        `• There is a hanger rod for hanging small bags`,
        `• 2 rotating and 2 fixed wheels.`,
        `• Strengthened base with MDF construction on`,
        `steel frame`,
        `• Weight : 53 kg`,
      ],
    },
    {
      title: 'LUGGAGE TROLLEYS 131',
      cover: LuggageTrolleys131,
      text: [
        `• Dimensions : 1120x620x1850 mm(PEB 111),`,
        `940x620x1850 mm(PEB 131)`,
        `• Capacity : 5-6 large size, 10-12 small size luggage,`,
        `Wheel Ø200mm, Barrier`,
        `• Ø32mm, Hanger Ø25mm, Carrying tubular `,
        `construction Ø45mm`,
        `• Produced either in solid brass or stainless steel.`,
        `• Surrounded with a plastic protective bumper.`,
        `• Welded lateral bars at two sides for higher`,
        `carrying capacity`,
        `• Complete dismantling tubular frame including`,
        `the hanger`,
        `• There is a hanger rod for hanging small bags`,
        `Welded hanger mounted on 3 mm hexagon joint`,
        `• 2 rotating and 2 fixed wheels. Metal part of wheel is`,
        `chrome plated or polished chrome plated solid brass.`,
        `It may be stainless steel as an option.`,
        `• Wheel features are listed in the chart.`,
        `• Base is strengthened with MDF construction on steel frame`,
        `• Weight : 49 kg (PEB 111), 44 kg (PEB 131)`,
      ],
    },
    {
      title: 'LUGGAGE TROLLEYS 411',
      cover: LuggageTrolleys4112,
      text: [
        `• Dimensions : 980x530x1250 mm`,
        `• Capacity : 6-7pcs., Wheel Ø200mm, carriage tubular construction Ø25mm`,
        `• Produced either in stainless steel, solid brass or Epoxy powder coated steel`,
        `• High load capacity with double tubular construction`,
        `• There is a hanger rod for hanging small bags`,
        `• Inflatable wheels with ball bearing and industrial plastic central parts provide smooth running.`,
        `• Stainless steel or solid brass wheel lids can be applicable upon request.`,
        `• Weight : 20 kg`,
      ],
    },
  ];
};

const BanquetData = () => {
  return [
    {
      title: 'CHAIR 3030',
      info: `Select is designed to be a delicate & contemporary model to bring an esthetics to your venues. It has a flex lumbar support to provide and ergonomic comfort and quality.`,
      cover: Chair3030,
    },
    {
      title: 'CHAIR 3034',
      info: `Select is designed to be a delicate & contemporary model to bring an esthetics to your venues. It has a flex lumbar support to provide and ergonomic comfort and quality.`,
      cover: Chair3034,
    },
    {
      title: 'CHAIR 3036',
      info: `Select is designed to be a delicate & contemporary model to bring an esthetics to your venues. It has a flex lumbar support to provide and ergonomic comfort and quality.`,
      cover: Chair3036,
    },
    {
      title: 'CASUAL CHAIR 104',
      info: `Our plugs, which can adapt to carpets and hard floors at the same time, move easily when pulled, and provide safe stacking against scratches in stowing, have been developed with our long researches.`,
      cover: CasualChair104,
    },
    {
      title: 'CHAIR 300',
      info: `It has a sharp and full line with many models. It provides a stylish look in your living room. Stereotyped corner plastics are the same color as the profile.`,
      cover: Chair300,
    },
    {
      title: 'CHAIR 308',
      info: `It is a comfortable model with a wide back form. It has a sharp and full line with many models. It provides a stylish look in your
        living room.`,
      cover: Chair308,
    },
    {
      title: 'CORNER CHAIR 250',
      info: `It has a sharp and full line with many models. It provides a stylish look in your living room. Stereotyped corner plastics are the same color as the profile.`,
      cover: CornerChair250,
    },
    {
      title: 'LUCKY CHAIR 104',
      info: `This model is a very “Lucky” one due to its lines and being the most sold model. Plump construction enables a comfortable seating. All models will stack 10 high. The parachute type glides does not come off. Easy to carry with built in hand hold. Its a very comfortable chair thanks to its seats that can be produced from polyurethane foam. Frame, lightweight aluminium extrusion with different profile finishes.`,
      cover: LuckyChair352,
    },
    {
      title: 'PYRAMID CHAIR 158',
      info: `It is a useful classic model that can adapt to any decor of your
        living room.
        
        Perfect details and Parachute type plugs`,
      cover: PyramidChair158,
    },
    {
      title: '',
      info: ``,
      cover: BanquetTable1,
    },
    {
      title: '',
      info: ``,
      cover: BanquetTable2,
    },
    {
      title: '',
      info: ``,
      cover: BanquetTable3,
    },
    {
      title: '',
      info: ``,
      cover: BanquetTable4,
    },
  ];
};

const KitchenData = () => {
  return [
    {
      title: 'Dunnage Rack / Flat',
      cover: DunnageRack,
      text: [
        `- Tables are manufactured from AISI304 18/8 Cr-Ni stainless steel sheet with a thickness of 1,2 mm.`,
        `- To prevent bending all table sections are two way rotational and reinforced by stainless steel sheet support from the bottom.`,
        `- Feet are manufactured from 40*40*1,2 mm stainless steel closed sections and they have adjustable aluminium joint balls.`,
        `- 2 Flat and 2 Perforated shelves are available.`,
        `- Shelf heights can be adjusted in desired sizes.`,
        `- Top parts of feet are covered with plastic blocks to provide hygiene.`,
        `- They have height adjustable stainless feet.`,
      ],
    },
    {
      title: 'Dunnage Rack / Perforated',
      cover: DunnageRackPerforated,
      text: [
        `- Tables are manufactured from AISI304 18/8 Cr-Ni stainless steel sheet with a thickness of 1,2 mm.`,
        `- It provides ease of storage and usage with its functional design.`,
        `- To prevent bending all table sections are two way rotational and reinforced by stainless steel sheet support from the bottom.`,
        `- Feet are manufactured from 40*40*1,2 mm stainless steel closed sections and they have adjustable aluminium joint balls.`,
        `- One perforated shelf is available.`,
        `- Top parts of feet are covered with plastic blocks to provide hygiene`,
      ],
    },
    {
      title: 'Storage Shelf / Flat and Perforated',
      cover: StorageShell,
      text: [
        `- Tables are manufactured from AISI304 18/8 Cr-Ni stainless steel sheet with a thickness of 1,2 mm.`,
        `- It provides ease of storage and usage with its functional design.`,
        `- To prevent bending all table sections are two way rotational and reinforced by stainless steel sheet support from the bottom.`,
        `- Feet are manufactured from 40*40*1,2 mm stainless steel closed sections and they have adjustable aluminium joint balls.`,
        `- 2 Flat and 2 Perforated shelves are available.`,
        `- Top parts of feet are covered with plastic blocks to provide hygiene.`,
      ],
    },
    {
      title: 'Storage Shelf / Disassembled / Flat',
      cover: StorageShellFlat,
      text: [
        `- Tables are manufactured from AISI304 18/8 Cr-Ni stainless steel sheet with a thickness of 1,2 mm.`,
        `- It provides ease of storage and usage with its functional design.`,
        `- To prevent bending all table sections are two way rotational and reinforced by stainless steel sheet support from the bottom.`,
        `- Feet are manufactured from 40*40*1,2 mm stainless steel closed sections and they have adjustable aluminium joint balls.`,
        `- Flat shelves are available.`,
        `- Shelf heights can be adjusted in desired sizes.`,
        `- Top parts of feet are covered with plastic blocks to provide hygiene.`,
        `- They have height adjustable stainless feet.`,
      ],
    },
    {
      title: 'Wire stacking rack / CHROME',
      cover: WireStackingRackChrome,
      text: [
        `- Wire racks are manufactured from chrome coated plate sheet.- The rack body consists Ø 5.8 mm thick rods on the sides and the middle and Ø 3.5 mm thick rods at the top with 20 mm intervals`,
        `- Ø 5.8 mm frame rods on the sides and Ø 4.5 mm wire parts with mesh pattern are mounted to increase durability.`,
        `- On each corner of the racks, cone parts manufactured from 2mm thick and Ø 32 mm diameter pipes are welded.`,
        ` - To strengthen the tall and wide racks’ thick rods in the middle section, additional parts made of mesh and Ø 5.8 rods are mounted.`,
        `- The racks are treated with electro polishing and its surface is strengthened against corrosion.`,
        `- Rack unit console is manufactured with chrome coated at 25 mm intervals on a Ø 25 mm diameter pipe.`,
        `- The console has the same surface treatment of the racks.`,
        `- After the electro polishing treatment, toothed zamak parts are mounted on the lower section of the console and bolts are mounted for height adjustment.`,
        `- Black plastic plugs are mounted on the bolts and the upper sections of the console.`,
        `- Mounting the racks on the console is done by mounting the special formatted polyamide parts on the desired numbered grooves and mounting the racks on the cone parts at the corners.`,
      ],
    },
    {
      title: 'A Wire stacking rack / ANTIBACTERIAL',
      cover: WireStackingRack,
      text: [
        `- Wire racks are manufactured from antibacterial coated plate sheet.`,
        `- The rack body consists Ø 5.8 mm thick rods on the sides and the middle and Ø 3.5 mm thick rods at the top with 20 mm intervals`,
        `- Ø 5.8 mm frame rods on the sides and Ø 4.5 mm wire parts with mesh pattern are mounted to increase durability.`,
        `- On each corner of the racks, cone parts manufactured from 2mm thick and Ø 32 mm diameter pipes are welded.`,
        `- To strengthen the tall and wide racks’ thick rods in the middle section, additional parts made of mesh and Ø 5.8 rods are mounted.`,
        `- The racks are treated with electro polishing and its surface is strengthened against corrosion.`,
        `- Rack unit console is manufactured with antibacterial coated at 25 mm intervals on a Ø 25 mm diameter pipe.`,
        `- The console has the same surface treatment of the racks.`,
        `- After the electro polishing treatment, toothed zamak parts are mounted on the lower section of the console and bolts are mounted for height adjustment.`,
        `- Black plastic plugs are mounted on the bolts and the upper sections of the console.`,
        `- Mounting the racks on the console is done by mounting the special formatted polyamide parts on the desired numbered grooves and mounting the racks on the cone parts at the corners.`,
      ],
    },
    {
      title: 'Sink Unit / Single Sink',
      cover: SingleSink,
      text: [
        `- It is completely manufactured from AISI304 18/8 Cr-Ni stainless steel.`,
        `- Sink is sloped to ease water flow through the hole to prevent water accumulation.`,
        `- Feet are manufactured from 40*40*1,2 mm stainless steel and there are adjustable joint balls on the tips.`,
        `- Top is flat and double-turn and has 60 mm of backsplash.`,
        `- It has single drain-board to drain the water of the dishes.`,
        `- There are stainless steel door handles on the doors.`,
      ],
    },
    {
      title: 'Sink Unit / Double Sink / Single Drain board',
      cover: SinkUnit,
      text: [
        `- It is completely manufactured from AISI304 18/8 Cr-Ni stainless steel.`,
        `- Sink is sloped to ease water flow through the hole to prevent water accumulation.`,
        `- Feet are manufactured from 40*40*1,2 mm stainless steel and there are adjustable joint balls on the tips.`,
        `- Top is flat and double-turn and has 60 mm of backsplash.`,
        `- It has single drain-board to drain the water of the dishes.`,
        `- There are two sinks.`,
      ],
    },
    {
      title: 'Upright Refrigerator',
      cover: UprightRefrigerator,
      text: [
        `* 60mm thick(doors 55mm) body, HFCF Free polyurethane, 45kg/m'3 density`,
        `* -2 / +8 °C temperature ranges`,
        `* Cooling system with fan blown evaporator`,
        `* Fan cooled engine block assembly`,
        `* Height adjustable PVC coated shelf`,
        `* Automatic water evaporation for defrost water`,
        `* Anti-frost defrost with electrical resistance`,
        `* All front and product contact surfaces are made of AISI 304 Cr-Ni stainless steel,`,
        `* Back sheets GALVANIZED`,
        `* 3 shelves on each door, 6 shelves in total`,
        `* Stainless adjustable feet`,
        `* Fixed hinge system at 120-135 degrees with springs on the doors`,
        `* GN 2/1 conformity`,
        `* Digital thermostat dual sensor`,
        `* Condenser cleaning alert, high low – temperature alarm, HACCP compliance, food preservation feature`,
        `* Magnetic and removable and cleanable door seals`,
        `* When the door is opened, it is ensured that the evaporator stops and the lamp starts.`,
        `* Door lock system`,
        `* In-cab lighting when the door is opened `,
      ],
    },
    {
      title: 'Pizza Preparation Unit / Marble Top',
      cover: PizzaPreparation,
      text: [
        `All external and internal coverings are made of AISI 304 quality 18/8 Cr-Ni stainless steel plate.`,
        `Upper plate is manufactured from marble material with a thickness of 30 mm. and back is manufactured from marble material with a thickness of 20 mm. and a height of 60 mm.`,
        `There are height-adjustable white plastic-coated inner wire shelves; there is one shelf in each door.`,
        `It is gastronomically complied with GN 1/1 (This feature is not available for 600 mm depth).`,
        `It refrigerates between -2/+8°C`,
        `Motor is built -in the refrigerator.`,
        `There is a blast cooling system with fan.`,
        `Garniture cooling is carried out with the aid of serpentine.`,
        `Depending on the function of the cooling units blast cooling is performed with the suitable capacity.`,
        `Defrosting water is directed to a pan and automatically evaporated.`,
        `Door handles are made of stainless steel and non-leaking door gaskets are made up of special plastic and they are isothermal.`,
        `There is a digital thermostat.`,
        `It has condenser cleaning warning, Low-High Temperature Alarm, HACCP Compliance and Food Protection feature.`,
        `There is a door switch which stops the evaporator and turns the lamp on when the door is opened.`,
        `280 lt., 465 lt. and 640 lt. capacity options`,
        `Garniture part has GN capacity of :`,
        `140 cm: 1/4GN - 7 pcs`,
        `190 cm: 1/4GN - 10 pcs`,
        `240 cm: 1/4GN - 13 pcs`,
        `Stainless steel adjustable tube feet are used.`,
        `Lighting, castors, glass doors, drawer (1/3 - 1/2 - 2/3 options), additional wire shelves and lock additions are optional in line with customer demands.`,
      ],
    },
    {
      title: 'Cooker',
      cover: Cooker,
      text: [
        `- Perfect 900 line, 1.5 module, with cabinet`,
        `- External surfaces of 18/8 Cr-Ni s/s satin finished sheet,`,
        `- All of the sheets and which are not exposed are of aluminized sheet,`,
        `- All of the  electric board and control devices including plates in conformity with the international standards,`,
        `- It produces with cabinet as standart. Cabinet are manufactured from stainless stell.`,
        `  Cabinet doors are with plastic holder.`,
        `- Separate operation of the plates at different positions by 4 step switches,`,
        `- It is manufactured with cabinet as standart. Cabinet is manufactured from stainless stell.`,
        `  Cabinet doors are with plastic holder,`,
        `- On cupboard (with or without doors) suitable for multi-pupose use or on cantilever for island type installations .`,
        `- Adjustable feet of stainless stell pipe , carrier sheet is 2 mm aluminized sheet.`,
      ],
    },
    {
      title: 'Cooker with oven',
      cover: CookerOven,
      text: [
        `- Perfect 900 line, 1.5 module, counter top,`,
        `- External surfaces of 18/8 Cr-Ni s/s satin finished sheet,`,
        `- All of the internal sheets are aluminized sheet,`,
        `- All of the electric board and control devices including plates in conformity with the international standards,`,
        `- Seperate operations of the plates at different positions by 4 step switches,`,
        `- Frame manufactured of alumnized sheet with a thickness of 1,5 mm`,
        `- Adjustable feet of stainless pipe,`,
        `- All of the electric control devices in conformity with the international standards,`,
        `- Double walled oven door, s/s, and insulated,`,
        `- Temperature control between 50-300 ° C and independent control of the bottom and top heating elements,`,
        `- Insulated oven chamber for minimizing heat loss,`,
        `- Oven capacity of 1 tray of GN 2/1 dimensions,`,
        `- Inside of AISI 430 BA sheet and with 3 tray slides,`,
        `- It has turbo cooking property,`,
        `- AISI 430 BA bottom sheet specially designed for homogeneous heat distribution`,
      ],
    },
    {
      title: 'Undercounter Dishwasher',
      cover: UndercounterDishwasher,
      text: [
        `* Suitable for undercounter installation`,
        `* Controlled by electronic card`,
        `* Digital displays  indicating  the  temperature of washing and rinsing  water`,
        `* Easy to dismount and mount washing / rinsing arms  and filters  for cleaning purposes,`,
        `* Safety system to stop the operation when the door is opened,`,
        `* Rack dimensions  500x500 mms.`,
        `* Temperature safety thermostats  of tank and boiler ,`,
        `* AISI 304 Cr-Ni S/S`,
        `* Complete with 1 pc glass rack`,
        `* 1 pc plate rack`,
        `* Rinsing pump included to  the machine.`,
        `* When  necessary, possibility to install rinsing pump where the water pressure is not sufficient  and drain pump`,
        `   where the level of drain is high ,`,
        `* Optional rinsing, detergent  and draining pumps .`,
        `* Water level in the tank controlled by presosstat automatically ,`,
        `* Starting the machine  through door .`,
      ],
    },
    {
      title: 'Flight Type Dishwasher',
      cover: FlightTypeDishwasher,
      text: [
        `* Electromechanically controlled,`,
        `* Pre-washing unit, forced, with 11 washing arms ,`,
        `* Duo washing module with 11 washing  arms,`,
        `* Duo rinse system and internal   rinsing pump ,`,
        `* Easy to dismount/mount washing/ rinsing arms and filters for cleaning purposes ,`,
        `* Possibility of washing kitchen utensils with a height of max.  400 mms ,`,
        `* Safety system to stop the operation of the dishwasher when the door  is opened,`,
        `* Rack dimensions of 500x500 mms ,`,
        `* Temperature safety thermostats of boiler,`,
        `* Temperature safety thermostats of well,`,
        `* Parts in contact with water manufactured of  AISI 304 Cr-Ni s/s sheet, `,
        `* 2 pcs cutlery rack ,`,
        `* Optional: Detergent pump, rinse aid pump , heat recovery  system,`,
        `* Water level in the tank controlled pressostat automatically,`,
        `* Pallet type belt system for the transportation  of the soiled utensils with a `,
        `  reducer of speed increase ,`,
        `* Digital thermometers displaying the water temperature ,`,
        `* Modular system  (entry, prewashing, washing, duo rinse,  drying, exit)`,
      ],
    },
    {
      title: 'Hood Type Dishwasher',
      cover: HoodDishwasher,
      text: [
        `All parts; which are in contact with the body of the machine and the water; are produced with AISI 304 quality 18/8 Cr-Ni stainless steel plate.`,
        `It has 3 different washing programs according to the type and degree of dirtiness of the dish.`,
        `Wash time, rinse time and temperature can be changed from the electronic card service menu based user's choice.`,
        `When the door is opened; there is a safety system to stop the operation.`,
        `The machine can wash kitchen equipment at a maximum height of 265 mm.`,
        `Glass and plate racks are made of heat resistant plastic material.`,
        `Washing and rinse arms and filters can be easily disassembled for cleaning.`,
        `As standard accessories; there are 1 plate, 1 cutlery with 500*500 mm dimensions`,
        `Water Input Pressure: 2-4 BAR`,
        `Heat Capacity:`,
        `Tank: 2.8 kW`,
        `Boiler: 8 kW`,
        `Max. Power consumption: 11,4 kW`,
        ``,
        `Rinse Water Consumption: 3 lt/rack`,
        `Power Input: 380-400V 3N AC 50-60 Hz`,
        `Cable cross section: 5*4 mm`,
        `Capacity:`,
        `Plate Capacity: 1040 pcs/h`,
        `Glass Capacity: 1625 pcs/h`,
        `Dimensions: 740*730*1580 (door closed) mm / 740*730*1925 (door open) mm`,
        `Net Weight: 113 kg`,
      ],
    },
    {
      title: 'Hot Service Unit',
      cover: HotService,
      text: [
        `Electrical`,
        `It is completely manufactured from AISI304 18/8 Cr-Ni stainless steel.`,
        `Top is manufactured from stainless steel sheet with a thickness of 1,50 mm`,
        `Service shelf with light, intermediate shelf and tray slides are optional in line with customer demands.`,
        `With the heater placed in the pool, the water is heated up and the vapors of the water heat the food containers.`,
        `The pool heats up to + 80-900C`,
        `3 or 4 pieces of GN1/1*150 gastronome container`,
        `The Pool water evacuates with the help of drainage pipes.`,
        `Cook, service side is open (without door).`,
        `Feet are manufactured from 50 mm stainless steel tube feet`,
        `The front panel can be done covered with wood in line with customer demands.`,
      ],
    },
    {
      title: 'Cold Service Unit / With Flat Top',
      cover: ColdServiceUnit,
      text: [
        `Electrical`,
        `It is completely manufactured from AISI304 18/8 Cr-Ni stainless steel.`,
        `Upper plate is manufactured from stainless steel sheet with a thickness of 1,5 mm.`,
        `Service shelf with light, intermediate shelf and tray slides are optional in line with customer demands.`,
        `The shelves within the cabinet are stainless steel.`,
        `It refrigerates between -2/+2°C`,
        `Motor is built -in the refrigerator.`,
        `Prevents overflowing of defrost water by evaporating in the evaporator.`,
        `Depending on the function of the cooling units static or an evaporators are used with the suitable capacity.`,
        `Defrosting water is directed to a pan and automatically evaporated.`,
        `Feet are manufactured from 50 mm stainless steel tube feet.`,
        `The front panel can be done covered with wood in line with customer demands.`,
      ],
    },
  ];
};

export { HouseKeepingData, LuggageData, BanquetData, KitchenData };
