const LeftArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <path
        id="Icon_awesome-arrow-circle-right"
        data-name="Icon awesome-arrow-circle-right"
        d="M28.063.563a27.5,27.5,0,1,1-27.5,27.5A27.5,27.5,0,0,1,28.063.563Zm-3.2,15.923,8.372,8.028H12.982a2.655,2.655,0,0,0-2.661,2.661V28.95a2.655,2.655,0,0,0,2.661,2.661H33.23l-8.372,8.028a2.664,2.664,0,0,0-.044,3.8l1.22,1.209a2.65,2.65,0,0,0,3.759,0L37.6,36.845l6.9-6.9a2.65,2.65,0,0,0,0-3.759L29.792,11.463a2.65,2.65,0,0,0-3.759,0l-1.22,1.209a2.677,2.677,0,0,0,.044,3.815Z"
        transform="translate(55.563 55.563) rotate(180)"
        fill="#707070"
      />
    </svg>
  );
};

const RightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <path
        id="Icon_awesome-arrow-circle-right"
        data-name="Icon awesome-arrow-circle-right"
        d="M28.063.563a27.5,27.5,0,1,1-27.5,27.5A27.5,27.5,0,0,1,28.063.563Zm-3.2,15.923,8.372,8.028H12.982a2.655,2.655,0,0,0-2.661,2.661V28.95a2.655,2.655,0,0,0,2.661,2.661H33.23l-8.372,8.028a2.664,2.664,0,0,0-.044,3.8l1.22,1.209a2.65,2.65,0,0,0,3.759,0L37.6,36.845l6.9-6.9a2.65,2.65,0,0,0,0-3.759L29.792,11.463a2.65,2.65,0,0,0-3.759,0l-1.22,1.209a2.677,2.677,0,0,0,.044,3.815Z"
        transform="translate(-0.563 -0.563)"
        fill="#707070"
      />
    </svg>
  );
};

const RightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72.812"
      height="62.411"
      viewBox="0 0 72.812 62.411"
    >
      <path
        id="Icon_metro-arrow-right"
        data-name="Icon metro-arrow-right"
        d="M49.783,66.671l26-26a5.2,5.2,0,0,0,0-7.355l-26-26a5.2,5.2,0,0,0-7.355,7.355L59.554,31.789H9.7a5.2,5.2,0,1,0,0,10.4H59.554L42.428,59.316a5.2,5.2,0,1,0,7.355,7.355Z"
        transform="translate(-4.499 -5.784)"
        fill="#3d3d3d"
      />
    </svg>
  );
};

const InfoBox = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
    >
      <defs>
        <filter
          id="Icon_awesome-info-circle"
          x="0"
          y="0"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        transform="matrix(1, 0, 0, 1, 0, 0)"
        filter="url(#Icon_awesome-info-circle)"
      >
        <path
          id="Icon_awesome-info-circle-2"
          data-name="Icon awesome-info-circle"
          d="M28.063.563a27.5,27.5,0,1,0,27.5,27.5A27.5,27.5,0,0,0,28.063.563Zm0,12.2a4.657,4.657,0,1,1-4.657,4.657A4.657,4.657,0,0,1,28.063,12.76Zm6.21,28.165a1.331,1.331,0,0,1-1.331,1.331H23.183a1.331,1.331,0,0,1-1.331-1.331V38.264a1.331,1.331,0,0,1,1.331-1.331h1.331v-7.1H23.183a1.331,1.331,0,0,1-1.331-1.331V25.845a1.331,1.331,0,0,1,1.331-1.331h7.1a1.331,1.331,0,0,1,1.331,1.331V36.933h1.331a1.331,1.331,0,0,1,1.331,1.331Z"
          transform="translate(3.94 0.94)"
          fill="#3d3d3d"
        />
      </g>
    </svg>
  );
};

const BottomArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.353"
      height="40.975"
      viewBox="0 0 27.353 40.975"
    >
      <path
        id="Icon_payment-clickandbuy"
        data-name="Icon payment-clickandbuy"
        d="M60.3,27.06a4.629,4.629,0,0,1,1.218,2.966,4.606,4.606,0,0,1-1.23,2.966l-9.481,9.492a4.219,4.219,0,0,1-5.932,0,4.192,4.192,0,0,1,0-5.932l2.308-2.308H38.333a4.217,4.217,0,0,1-4.228-4.217,4.236,4.236,0,0,1,4.228-4.218h8.855l-2.308-2.3a4.6,4.6,0,0,1-1.23-2.966,4.606,4.606,0,0,1,1.23-2.966,4.192,4.192,0,0,1,5.932,0L60.3,27.06ZM26.144,24.18a6.144,6.144,0,0,0-3.958,1.639,6.144,6.144,0,0,0-1.64,3.958,6.145,6.145,0,0,0,1.639,3.958,6.145,6.145,0,0,0,3.958,1.639A6.145,6.145,0,0,0,30.1,33.735a6.145,6.145,0,0,0,1.639-3.958A6.145,6.145,0,0,0,30.1,25.819a6.143,6.143,0,0,0-3.958-1.639Z"
        transform="translate(43.702 -20.547) rotate(90)"
        fill="#f6f6f6"
      />
    </svg>
  );
};

const WhiteInfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <path
        id="Icon_awesome-info-circle"
        data-name="Icon awesome-info-circle"
        d="M28.063.563a27.5,27.5,0,1,0,27.5,27.5A27.5,27.5,0,0,0,28.063.563Zm0,12.2a4.657,4.657,0,1,1-4.657,4.657A4.657,4.657,0,0,1,28.063,12.76Zm6.21,28.165a1.331,1.331,0,0,1-1.331,1.331H23.183a1.331,1.331,0,0,1-1.331-1.331V38.264a1.331,1.331,0,0,1,1.331-1.331h1.331v-7.1H23.183a1.331,1.331,0,0,1-1.331-1.331V25.845a1.331,1.331,0,0,1,1.331-1.331h7.1a1.331,1.331,0,0,1,1.331,1.331V36.933h1.331a1.331,1.331,0,0,1,1.331,1.331Z"
        transform="translate(-0.563 -0.563)"
        fill="#f6f6f6"
      />
    </svg>
  );
};

const WhatsappIcon = () => {
  return (
    <svg
      id="Group_1560"
      data-name="Group 1560"
      xmlns="http://www.w3.org/2000/svg"
      width="27.21"
      height="29.12"
      viewBox="0 0 27.21 29.12"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_10322"
            data-name="Rectangle 10322"
            width="27.21"
            height="29.12"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_1545" data-name="Group 1545" clip-path="url(#clip-path)">
        <path
          id="Path_11807"
          data-name="Path 11807"
          d="M.871,29.12c.1-.411.177-.779.269-1.145.5-2,1-4,1.49-6a.586.586,0,0,0-.086-.426A12.768,12.768,0,0,1,.563,17.491a22.264,22.264,0,0,1-.5-2.656A10.008,10.008,0,0,1,0,13.294,13.118,13.118,0,0,1,1.194,8.012a13.245,13.245,0,0,1,2.88-4.124A13.675,13.675,0,0,1,14.277.015a13.328,13.328,0,0,1,4.708,1.1,13.6,13.6,0,0,1,8.2,13.388,13.176,13.176,0,0,1-1.154,4.662,13.648,13.648,0,0,1-8.755,7.558,13.641,13.641,0,0,1-4.657.47,12.959,12.959,0,0,1-4-.913.612.612,0,0,0-.411-.012q-3.39,1.316-6.773,2.652c-.164.064-.331.12-.563.2m.834-.954c.1-.031.161-.047.224-.072,1.992-.778,3.989-1.546,5.973-2.343a1.268,1.268,0,0,1,1.026.018,11.931,11.931,0,0,0,4.315.856c.67,0,1.341-.061,2.011-.112a10.951,10.951,0,0,0,3.429-.9,12.767,12.767,0,0,0,4.685-3.377,12.924,12.924,0,0,0,3.249-7.871,12.14,12.14,0,0,0-.363-3.906A13.066,13.066,0,0,0,14.187.586,12.492,12.492,0,0,0,11,.835,12.623,12.623,0,0,0,7.09,2.316,13.131,13.131,0,0,0,2.02,7.621,12.986,12.986,0,0,0,.574,12.986a12.74,12.74,0,0,0,.314,3.528,13.355,13.355,0,0,0,2.3,4.967.538.538,0,0,1,.076.4c-.51,2.085-1.032,4.166-1.562,6.29"
          transform="translate(0.001 0)"
          fill="#fff"
        />
        <path
          id="Path_11808"
          data-name="Path 11808"
          d="M42.731,64.477c.153-.53.291-1.007.427-1.485.233-.819.474-1.636.683-2.462a.671.671,0,0,0-.153-.43c-.43-.66-.905-1.293-1.306-1.97a8.677,8.677,0,0,1-1.1-3.179A13.534,13.534,0,0,1,41.1,52.36a10.848,10.848,0,0,1,2.336-6.273,11.145,11.145,0,0,1,9.624-4.28A10.848,10.848,0,0,1,59.3,44.288a11.018,11.018,0,0,1,3.8,6.053,10.8,10.8,0,0,1,.287,3.321,11.183,11.183,0,0,1-8,9.98,14.05,14.05,0,0,1-2.226.4A10.485,10.485,0,0,1,48,63.266a6.393,6.393,0,0,1-.745-.355.523.523,0,0,0-.493-.017c-1.319.525-2.642,1.04-4.026,1.582m13.792-4.885a6.15,6.15,0,0,0,.882-.206A3.548,3.548,0,0,0,58.978,58a.589.589,0,0,0,.144-.308c-.02-.423-.065-.846-.113-1.267a.248.248,0,0,0-.132-.168q-1.506-.587-3.017-1.16a.55.55,0,0,0-.63.1c-.345.326-.712.628-1.053.958-.146.141-.261.188-.431.056-.606-.472-1.235-.918-1.821-1.415a11.112,11.112,0,0,1-2.17-2.571.307.307,0,0,1,.059-.44c.265-.267.494-.572.775-.819a.883.883,0,0,0,.19-1.144c-.327-.788-.642-1.583-.926-2.387-.111-.314-.22-.539-.6-.525s-.834-.145-1.132.01a3.123,3.123,0,0,0-1.579,1.76,3.936,3.936,0,0,0,.017,2.019,9.846,9.846,0,0,0,2.6,4.924,14.054,14.054,0,0,0,3.62,2.845,9.059,9.059,0,0,0,3.747,1.118"
          transform="translate(-38.645 -39.288)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

const CircleBottomArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66.645"
      height="66.645"
      viewBox="0 0 66.645 66.645"
    >
      <path
        id="Icon_awesome-arrow-circle-down"
        data-name="Icon awesome-arrow-circle-down"
        d="M67.207,33.885A33.322,33.322,0,1,1,33.885.562,33.317,33.317,0,0,1,67.207,33.885ZM47.913,30,38.185,40.146V15.611a3.217,3.217,0,0,0-3.225-3.225H32.81a3.217,3.217,0,0,0-3.225,3.225V40.146L19.857,30a3.228,3.228,0,0,0-4.609-.054l-1.465,1.478a3.211,3.211,0,0,0,0,4.555L31.6,53.811a3.211,3.211,0,0,0,4.555,0l17.83-17.83a3.211,3.211,0,0,0,0-4.555l-1.465-1.478A3.228,3.228,0,0,0,47.913,30Z"
        transform="translate(-0.563 -0.563)"
        fill="#3d3d3d"
      />
    </svg>
  );
};

const HamburgerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="21"
      viewBox="0 0 30 21"
    >
      <g
        id="Icon_feather-menu"
        data-name="Icon feather-menu"
        transform="translate(-3 -7.5)"
      >
        <path
          id="Path_1"
          data-name="Path 1"
          d="M4.5,18h27"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M4.5,9h27"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M4.5,27h27"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </svg>
  );
};

const PlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        id="Icon_material-play-circle-outline"
        data-name="Icon material-play-circle-outline"
        d="M15,24.75,24,18l-9-6.75ZM18,3A15,15,0,1,0,33,18,15.005,15.005,0,0,0,18,3Zm0,27A12,12,0,1,1,30,18,12.016,12.016,0,0,1,18,30Z"
        transform="translate(-3 -3)"
        fill="#680508"
      />
    </svg>
  );
};

export {
  LeftArrow,
  RightArrow,
  RightIcon,
  InfoBox,
  BottomArrow,
  WhiteInfoIcon,
  WhatsappIcon,
  CircleBottomArrow,
  HamburgerIcon,
  PlayIcon,
};
