import style from './home.module.scss';
import { useState } from 'react';

import architectDesignDepartmentCover from '../../assets/img/main/cover/architect-design-department-cover.png';
import contractDepartmentCover from '../../assets/img/main/cover/contract-department-cover.png';
import tradeDepartmentCover from '../../assets/img/main/cover/trade-department-cover.png';
import graphicDesignDepartmentCover from '../../assets/img/main/cover/graphic-design-department-cover.png';
import { useSpring, animated } from 'react-spring';

const RightPanel = () => {
  const [isOpen, setOpen] = useState(false);
  const [styles, api] = useSpring(() => ({
    from: {
      translateX: 1000,
    },
  }));

  return (
    <>
      <div
        className={`${style.rightPanel} ${isOpen ? style.heightFull : null}`}
      >
        <button
          onClick={() => {
            if (isOpen) {
              api({
                translateX: 1000,
              });
              setOpen(false);
            } else {
              api({
                translateX: 0,
              });
              setOpen(true);
            }
          }}
        >
          <h1>WHO IS LINEARE?</h1>
        </button>

        <animated.div
          onClick={() => {
            if (isOpen) {
              api({
                translateX: 1000,
              });
              setOpen(false);
            } else {
              api({
                translateX: 0,
              });
              setOpen(true);
            }
          }}
          style={{ ...styles }}
          className={style.rightCard}
        >
          <h3>Who is Lineare</h3>

          <p>
            We (LINEARE) are a multi-disciplinary company of creatives and
            thinkers, creating and realizing meaningful design solutions and
            experiences for brands and audiences.
            <br />
            <br />
            Our work is guided by a strong belief in design as a problem-solving
            tool, as a way of recognizing and forming relationships between
            ideas and reality, and as a method for improving the connections
            between people and the products they use.
            <br />
            <br />
            We have the Skills & knowhow to design, create and manufacture any
            architectural space especially specializing in hotels, restaurants
            and residential projects.
            <br />
            <br />
            Apart from design we are also specialized providing materials,
            consultations on site and providing services with our vast
            connection of business partners, builders, artists and whatever is
            required to realize a project in the most efficient way.
            <br />
            <br />
            We are a flexible company thereby we create and implement effective,
            inspiring and long-lasting design solutions for every type of
            budget. We believe a good design does not always mean expensive but
            instead clever and creative approach and a good understanding of the
            space.
            <br />
            <br />
            We know every customer is special and we value their ideas as we
            believe a successful design depends on finding solutions through
            customers input and refine them in our vision of design.
          </p>
        </animated.div>
      </div>
      {isOpen ? (
        <div
          onClick={() => {
            if (isOpen) {
              api({
                translateX: 1000,
              });
              setOpen(false);
            } else {
              api({
                translateX: 0,
              });
              setOpen(true);
            }
          }}
          className={style.shadowCover}
        ></div>
      ) : null}
    </>
  );
};

const Home = () => {
  return (
    <>
      <RightPanel />
      <div className={style.home}>
        <h1>WHAT DO YOU NEED?</h1>
        <h5>"everything is inside"</h5>
        <div className={style.menu}>
          <div className={style.cardWrapper}>
            <div className={style.top}>
              <a href="/design-department">
                <h3>ARCHITECT DESIGN DEPARTMENT</h3>
                <img
                  src={architectDesignDepartmentCover}
                  alt="architect-design-department-cover"
                />
              </a>
              <a href="/contract-department" className={style.right}>
                <h3>CONTRACT DEPARTMENT</h3>
                <img
                  src={contractDepartmentCover}
                  alt="contract-department-cover"
                />
              </a>
            </div>
            <div className={style.bottom}>
              <a href="/trade-department" className={style.bottomLeft}>
                <h3>TRADE DEPARTMENT</h3>
                <img src={tradeDepartmentCover} alt="trade-department-cover" />
              </a>
              <a href="/graphic-department" className={style.bottomRight}>
                <h3>GRAPHIC DESIGN DEPARTMENT</h3>
                <img
                  src={graphicDesignDepartmentCover}
                  alt="graphic-design-department-cover"
                />
              </a>
            </div>
          </div>
        </div>
        <footer className={style.footer}>
          <h4>
            Odunluk Mh. İbrahim İşsevenler Cd. No/12 Ofis Kare Kat:5 D:37-38
            Nilüfer/Bursa/Türkiye
          </h4>
          <h4>
            +90 224 502 81 55 <span>info@linearedsgn.com</span>
          </h4>
        </footer>
      </div>
    </>
  );
};

export default Home;
