// TABLES
import table1 from '../../assets/img/trade-department/products/furniture/office/tables/tables-1.png';
import table2 from '../../assets/img/trade-department/products/furniture/office/tables/tables-2.png';
import table3 from '../../assets/img/trade-department/products/furniture/office/tables/tables-3.png';
import table4 from '../../assets/img/trade-department/products/furniture/office/tables/tables-4.png';

// STORAGE
import storage1 from '../../assets/img/trade-department/products/furniture/office/storage/storage-1.png';
import storage2 from '../../assets/img/trade-department/products/furniture/office/storage/storage-2.png';
import storage3 from '../../assets/img/trade-department/products/furniture/office/storage/storage-3.png';
import storage4 from '../../assets/img/trade-department/products/furniture/office/storage/storage-4.png';
import storage5 from '../../assets/img/trade-department/products/furniture/office/storage/storage-5.png';
import storage6 from '../../assets/img/trade-department/products/furniture/office/storage/storage-6.png';
import storage7 from '../../assets/img/trade-department/products/furniture/office/storage/storage-7.png';

// WAITING ZONE
import waitingZone1 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-1.png';
import waitingZone2 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-2.png';
import waitingZone3 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-3.png';
import waitingZone4 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-4.png';
import waitingZone5 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-5.png';
import waitingZone6 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-6.png';
import waitingZone7 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-7.png';
import waitingZone8 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-8.png';
import waitingZone9 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-9.png';
import waitingZone10 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-10.png';
import waitingZone11 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-11.png';
import waitingZone12 from '../../assets/img/trade-department/products/furniture/office/waiting-zone/waiting-zone-12.png';

// CHAIRS

// GUEST-CHAIRS
import guestChairs1 from '../../assets/img/trade-department/products/furniture/office/chairs/guest-chairs/guest-chairs-1.png';
import guestChairs2 from '../../assets/img/trade-department/products/furniture/office/chairs/guest-chairs/guest-chairs-2.png';
import guestChairs3 from '../../assets/img/trade-department/products/furniture/office/chairs/guest-chairs/guest-chairs-3.png';
import guestChairs4 from '../../assets/img/trade-department/products/furniture/office/chairs/guest-chairs/guest-chairs-4.png';
import guestChairs5 from '../../assets/img/trade-department/products/furniture/office/chairs/guest-chairs/guest-chairs-5.png';
import guestChairs6 from '../../assets/img/trade-department/products/furniture/office/chairs/guest-chairs/guest-chairs-6.png';

// MEETING-CHAIRS
import meetingChairs1 from '../../assets/img/trade-department/products/furniture/office/chairs/meeting-chairs/meeting-chairs-1.png';
import meetingChairs2 from '../../assets/img/trade-department/products/furniture/office/chairs/meeting-chairs/meeting-chairs-2.png';

// MANAGER-CHAIRS
import managerChairs1 from '../../assets/img/trade-department/products/furniture/office/chairs/manager-chairs/manager-chairs-1.png';
import managerChairs2 from '../../assets/img/trade-department/products/furniture/office/chairs/manager-chairs/manager-chairs-2.png';

const OfficeData = value => {
  if (value === 'tables') {
    return {
      title: 'OFFICE TABLES',
      link: 'tables',
      width: 'wide',
      pictures: [table1, table2, table3, table4],
    };
  } else if (value === 'storage') {
    return {
      title: 'STORAGE',
      link: 'storage',
      width: 'wide',
      pictures: [
        storage6,
        storage1,
        storage5,
        storage3,
        storage7,
        storage4,
        storage2,
      ],
    };
  } else if (value === 'waiting-zone') {
    return {
      title: 'WAITING ZONE',
      link: 'waiting-zone',
      width: 'wide',
      pictures: [
        waitingZone1,
        waitingZone2,
        waitingZone3,
        waitingZone4,
        waitingZone5,
        waitingZone6,
        waitingZone7,
        waitingZone8,
        waitingZone9,
        waitingZone10,
        waitingZone11,
        waitingZone12,
      ],
    };
  } else if (value === 'chairs') {
    return {
      title: 'multiple',
      data: [
        {
          title: 'MANAGER CHAIRS',
          pictures: [managerChairs1, managerChairs2],
        },
        {
          title: 'GUEST CHAIRS',
          pictures: [
            guestChairs1,
            guestChairs2,
            guestChairs3,
            guestChairs4,
            guestChairs5,
            guestChairs6,
          ],
        },
        {
          title: 'MEETING CHAIRS',
          pictures: [meetingChairs1, meetingChairs2],
        },
      ],
    };
  }
};

export default OfficeData;
