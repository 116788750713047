import oztimurlarOfficeCover from '../../assets/img/design-department/office-store/cover/oztimurlar-office-cover.png';
import oztimurlarOffice1 from '../../assets/img/design-department/office-store/oztimurlar-office/oztimurlar-office-1.png';
import oztimurlarOffice2 from '../../assets/img/design-department/office-store/oztimurlar-office/oztimurlar-office-2.png';
import oztimurlarOffice3 from '../../assets/img/design-department/office-store/oztimurlar-office/oztimurlar-office-3.png';

import akarcaTextileCover from '../../assets/img/design-department/office-store/cover/akarca-textile-cover.png';
import akarcaTextile1 from '../../assets/img/design-department/office-store/akarca-textile/akarca-textile-1.png';
import akarcaTextile2 from '../../assets/img/design-department/office-store/akarca-textile/akarca-textile-2.png';
import akarcaTextile3 from '../../assets/img/design-department/office-store/akarca-textile/akarca-textile-3.png';

import dreamHeadquartersCover from '../../assets/img/design-department/office-store/cover/dream-headquarters-cover.png';
import dreamHeadquarters1 from '../../assets/img/design-department/office-store/dream-headquarters/dream-headquarters-1.png';
import dreamHeadquarters2 from '../../assets/img/design-department/office-store/dream-headquarters/dream-headquarters-2.png';
import dreamHeadquarters3 from '../../assets/img/design-department/office-store/dream-headquarters/dream-headquarters-3.png';
import dreamHeadquarters4 from '../../assets/img/design-department/office-store/dream-headquarters/dream-headquarters-4.png';

import lineareStudioCover from '../../assets/img/design-department/office-store/cover/lineare-studio-cover.png';
import lineareStudio1 from '../../assets/img/design-department/office-store/lineare-studio/lineare-studio-1.png';
import lineareStudio2 from '../../assets/img/design-department/office-store/lineare-studio/lineare-studio-2.png';
import lineareStudio3 from '../../assets/img/design-department/office-store/lineare-studio/lineare-studio-3.png';

import movemRetailCover from '../../assets/img/design-department/office-store/cover/movem-retail-cover.png';
import movemRetail1 from '../../assets/img/design-department/office-store/movem-retail/movem-retail-1.png';
import movemRetail2 from '../../assets/img/design-department/office-store/movem-retail/movem-retail-2.png';
import movemRetail3 from '../../assets/img/design-department/office-store/movem-retail/movem-retail-3.png';
import movemRetail4 from '../../assets/img/design-department/office-store/movem-retail/movem-retail-4.png';

import dohaRetailCover from '../../assets/img/design-department/office-store/cover/doha-retail-cover.png';
import dohaRetail1 from '../../assets/img/design-department/office-store/doha-retail/doha-retail-1.png';
import dohaRetail2 from '../../assets/img/design-department/office-store/doha-retail/doha-retail-2.png';
import dohaRetail3 from '../../assets/img/design-department/office-store/doha-retail/doha-retail-3.png';
import dohaRetail4 from '../../assets/img/design-department/office-store/doha-retail/doha-retail-4.png';

import mooiStoreCover from '../../assets/img/design-department/office-store/cover/mooi-store-cover.png';
import mooiStore1 from '../../assets/img/design-department/office-store/mooi-store/mooi-store-1.png';
import mooiStore2 from '../../assets/img/design-department/office-store/mooi-store/mooi-store-2.png';
import mooiStore3 from '../../assets/img/design-department/office-store/mooi-store/mooi-store-3.png';
import mooiStore4 from '../../assets/img/design-department/office-store/mooi-store/mooi-store-4.png';

const OfficeStoreData = [
  {
    title: 'ÖZTİMURLAR OFFICE',
    link: 'oztimurlar-office',
    cover: oztimurlarOfficeCover,
    subInfo: 'Bursa, Turkey 524 m² Realized in 2017',
    info: `Located in the commercial district of Bursa, this office was designed as sleek and contemporary, with a fresh twist of colors and fabrics.
    It was designed with the intention of communicating strength and elegance. `,
    images: [oztimurlarOffice1, oztimurlarOffice2, oztimurlarOffice3],
  },
  {
    title: 'AKARCA TEXTILE',
    link: 'akarca-textile',
    cover: akarcaTextileCover,
    subInfo: 'Bursa, Turkey 752 m² Realized in 2017',
    info: `Located in the booming industrial district of Bursa, this office is designed to create a homely atmosphere, boosting the confidence of the team using it.
    It was also a priority to create an inviting environment for clients visiting the office.`,
    images: [akarcaTextile1, akarcaTextile2, akarcaTextile3],
  },
  {
    title: 'DREAM HEADQUARTERS',
    link: 'dream-headquarters',
    cover: dreamHeadquartersCover,
    subInfo: 'Istanbul, Turkey 3,750 m² Realized in 2016',
    info: `Located in the booming commercial district of Istanbul, this office was designed as a destination workplace.
    It includes meaningful spaces where plug-and-play technology supports virtual collaboration and allows team members to share information more easily.
    Meeting spaces can be used quickly and informally, while the space is transparent and provides a good perspective of all activities in the office. `,
    images: [
      dreamHeadquarters1,
      dreamHeadquarters2,
      dreamHeadquarters3,
      dreamHeadquarters4,
    ],
  },
  {
    title: 'LINEARE STUDIO',
    link: 'lineare-studio',
    cover: lineareStudioCover,
    subInfo: 'Bursa, Turkey 250 m² Realized in 2018',
    info: `We had the beautiful and difficult task of designing our own office in Bursa. What made it easier was the attractive and high-quality space on two floors connected by an open metal staircase. This openness is precisely the main characteristic of this design. There are no unnecessary partitions, and the existing ones are made from glass.
    Metal and marble details, greenery and light colors complete the design.`,
    images: [lineareStudio1, lineareStudio2, lineareStudio3],
  },
  {
    title: 'MOVEM RETAIL',
    link: 'movem-retail',
    cover: movemRetailCover,
    subInfo: 'Belgrade, Serbia 324 m² Realized in 2017',
    info: `This luxury menswear store hosts a variety of well-known brands.
    Bold signage and contemporary design were used to create an immersive environment that promotes brand advocacy and keeps customers coming back to the store.`,
    images: [movemRetail1, movemRetail2, movemRetail3, movemRetail4],
  },
  {
    title: 'DOHA RETAIL',
    link: 'doha-retail',
    cover: dohaRetailCover,
    subInfo: 'Doha, Qatar 72 m² Realized in 2014',
    info: `This luxury store in the Qatari capital of Doha was designed to reflect the air and style of the premium goods sold there.
    Furniture and displays were used to create a clear path through the store for the customer, creating a natural flow through the space.`,
    images: [dohaRetail1, dohaRetail2, dohaRetail3, dohaRetail4],
  },
  {
    title: 'MOOI STORE',
    link: 'mooi-store',
    cover: mooiStoreCover,
    subInfo: 'Bursa, Turkey 324 m² Realized in 2018',
    info: `This bookstore, which sells toys, books and office supplies, also features a café with a terrace. The intention was to create a cozy space where customers feel at home and relaxed, which was accomplished with the use of different fabrics, wood, and brick walls. A notable feature of this interior are bookshelves which span booth floors of the store. `,
    images: [mooiStore1, mooiStore2, mooiStore3, mooiStore4],
  },
];

export default OfficeStoreData;
