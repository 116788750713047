import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useLocation,
  withRouter,
  useHistory,
} from 'react-router-dom';
import routes from './routes';
import ProtectedRoute from './routes/ProtectedRoute';
import { Header } from './components';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const App = () => {
  const Auth = false;
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          window.location.reload();
        } else {
          setLocationKeys(keys => [location.key, ...keys]);

          // Handle back event
        }
      }
    });
  }, [locationKeys, history]);

  return (
    <ScrollToTop>
      <Header />
      <div
        style={{
          paddingTop: '70px',
        }}
      >
        <Switch>
          {routes.map((route, key) =>
            route.protected ? (
              <ProtectedRoute
                component={() => (
                  <route.component
                    section={route.name}
                    title={route.title}
                    subtitle={route.subtitle}
                  />
                )}
                isAuth={Auth}
                path={route.path}
                exact={route.exact}
                key={key}
              />
            ) : (
              <Route
                exact={route.exact}
                path={route.path}
                component={route.component}
                key={key}
              />
            )
          )}
        </Switch>
      </div>
    </ScrollToTop>
  );
};

const AppWithRouter = withRouter(App);

export default AppWithRouter;
