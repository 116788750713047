import blueKotorBayCover from '../../assets/img/design-department/hotels/cover/blue-kotor-bay-cover.png';
import blueKotorBay1 from '../../assets/img/design-department/hotels/blue-kotor-bay/blue-kotor-bay-1.png';
import blueKotorBay2 from '../../assets/img/design-department/hotels/blue-kotor-bay/blue-kotor-bay-2.png';
import blueKotorBay3 from '../../assets/img/design-department/hotels/blue-kotor-bay/blue-kotor-bay-3.png';
import blueKotorBay4 from '../../assets/img/design-department/hotels/blue-kotor-bay/blue-kotor-bay-4.png';
import blueKotorBay5 from '../../assets/img/design-department/hotels/blue-kotor-bay/blue-kotor-bay-5.png';

import butuaCover from '../../assets/img/design-department/hotels/cover/butua-cover.png';
import butua1 from '../../assets/img/design-department/hotels/butua/butua-1.png';
import butua2 from '../../assets/img/design-department/hotels/butua/butua-2.png';
import butua3 from '../../assets/img/design-department/hotels/butua/butua-3.png';
import butua4 from '../../assets/img/design-department/hotels/butua/butua-4.png';
import butua5 from '../../assets/img/design-department/hotels/butua/butua-5.png';

import momentumCover from '../../assets/img/design-department/hotels/cover/momentum-cover.png';
import momentum1 from '../../assets/img/design-department/hotels/momentum/momentum-1.png';
import momentum2 from '../../assets/img/design-department/hotels/momentum/momentum-2.png';
import momentum3 from '../../assets/img/design-department/hotels/momentum/momentum-3.png';
import momentum4 from '../../assets/img/design-department/hotels/momentum/momentum-4.png';
import momentum5 from '../../assets/img/design-department/hotels/momentum/momentum-5.png';

import wildBeautyCover from '../../assets/img/design-department/hotels/cover/wild-beauty-cover.png';
import wildBeauty1 from '../../assets/img/design-department/hotels/wild-beauty/wild-beauty-1.png';
import wildBeauty2 from '../../assets/img/design-department/hotels/wild-beauty/wild-beauty-2.png';
import wildBeauty3 from '../../assets/img/design-department/hotels/wild-beauty/wild-beauty-3.png';
import wildBeauty4 from '../../assets/img/design-department/hotels/wild-beauty/wild-beauty-4.png';
import wildBeauty5 from '../../assets/img/design-department/hotels/wild-beauty/wild-beauty-5.png';

import kipasBoutiqueCover from '../../assets/img/design-department/hotels/cover/kipas-boutique-cover.png';
import kipasBoutique1 from '../../assets/img/design-department/hotels/kipas-boutique/kipas-boutique-1.png';
import kipasBoutique2 from '../../assets/img/design-department/hotels/kipas-boutique/kipas-boutique-2.png';
import kipasBoutique3 from '../../assets/img/design-department/hotels/kipas-boutique/kipas-boutique-3.png';
import kipasBoutique4 from '../../assets/img/design-department/hotels/kipas-boutique/kipas-boutique-4.png';
import kipasBoutique5 from '../../assets/img/design-department/hotels/kipas-boutique/kipas-boutique-5.png';

import holidayInnTuzlaCover from '../../assets/img/design-department/hotels/cover/holiday-inn-tuzla-cover.png';
import holidayInnTuzla1 from '../../assets/img/design-department/hotels/holiday-inn-tuzla/holiday-inn-tuzla-1.png';
import holidayInnTuzla2 from '../../assets/img/design-department/hotels/holiday-inn-tuzla/holiday-inn-tuzla-2.png';
import holidayInnTuzla3 from '../../assets/img/design-department/hotels/holiday-inn-tuzla/holiday-inn-tuzla-3.png';
import holidayInnTuzla4 from '../../assets/img/design-department/hotels/holiday-inn-tuzla/holiday-inn-tuzla-4.png';
import holidayInnTuzla5 from '../../assets/img/design-department/hotels/holiday-inn-tuzla/holiday-inn-tuzla-5.png';

import radissonCover from '../../assets/img/design-department/hotels/cover/radisson-cover.png';
import radisson1 from '../../assets/img/design-department/hotels/radisson/radisson-1.png';
import radisson2 from '../../assets/img/design-department/hotels/radisson/radisson-2.png';
import radisson3 from '../../assets/img/design-department/hotels/radisson/radisson-3.png';
import radisson4 from '../../assets/img/design-department/hotels/radisson/radisson-4.png';
import radisson5 from '../../assets/img/design-department/hotels/radisson/radisson-5.png';

import muminCover from '../../assets/img/design-department/hotels/cover/mumin-cover.png';
import mumin1 from '../../assets/img/design-department/hotels/mumin/mumin-1.png';
import mumin2 from '../../assets/img/design-department/hotels/mumin/mumin-2.png';
import mumin3 from '../../assets/img/design-department/hotels/mumin/mumin-3.png';

import majesticBoutiqueCover from '../../assets/img/design-department/hotels/cover/majestic-boutique-cover.png';
import majesticBoutique1 from '../../assets/img/design-department/hotels/majestic-boutique/majestic-boutique-1.png';
import majesticBoutique2 from '../../assets/img/design-department/hotels/majestic-boutique/majestic-boutique-2.png';
import majesticBoutique3 from '../../assets/img/design-department/hotels/majestic-boutique/majestic-boutique-3.png';
import majesticBoutique4 from '../../assets/img/design-department/hotels/majestic-boutique/majestic-boutique-4.png';

import montaniaCover from '../../assets/img/design-department/hotels/cover/montania-cover.png';
import montania1 from '../../assets/img/design-department/hotels/montania/montania-1.png';
import montania2 from '../../assets/img/design-department/hotels/montania/montania-2.png';
import montania3 from '../../assets/img/design-department/hotels/montania/montania-3.png';
import montania4 from '../../assets/img/design-department/hotels/montania/montania-4.png';

import holidayInnBungalovCover from '../../assets/img/design-department/hotels/cover/holiday-inn-bungalov-cover.png';
import holidayInnBungalov1 from '../../assets/img/design-department/hotels/holiday-inn-bungalov/holiday-inn-bungalov-1.png';
import holidayInnBungalov2 from '../../assets/img/design-department/hotels/holiday-inn-bungalov/holiday-inn-bungalov-2.png';
import holidayInnBungalov3 from '../../assets/img/design-department/hotels/holiday-inn-bungalov/holiday-inn-bungalov-3.png';
import holidayInnBungalov4 from '../../assets/img/design-department/hotels/holiday-inn-bungalov/holiday-inn-bungalov-4.png';

import avantgardeCover from '../../assets/img/design-department/hotels/cover/avantgarde-cover.png';
import avantgarde1 from '../../assets/img/design-department/hotels/avantgarde/avantgarde-1.png';
import avantgarde2 from '../../assets/img/design-department/hotels/avantgarde/avantgarde-2.png';
import avantgarde3 from '../../assets/img/design-department/hotels/avantgarde/avantgarde-3.png';
import avantgarde4 from '../../assets/img/design-department/hotels/avantgarde/avantgarde-4.png';
import avantgarde5 from '../../assets/img/design-department/hotels/avantgarde/avantgarde-5.png';

import hiltonGardenInnCover from '../../assets/img/design-department/hotels/cover/hilton-garden-inn-cover.png';
import hiltonGardenInn1 from '../../assets/img/design-department/hotels/hilton-garden-inn/hilton-garden-inn-1.png';
import hiltonGardenInn2 from '../../assets/img/design-department/hotels/hilton-garden-inn/hilton-garden-inn-2.png';
import hiltonGardenInn3 from '../../assets/img/design-department/hotels/hilton-garden-inn/hilton-garden-inn-3.png';
import hiltonGardenInn4 from '../../assets/img/design-department/hotels/hilton-garden-inn/hilton-garden-inn-4.png';

import bristolCover from '../../assets/img/design-department/hotels/cover/bristol-cover.png';
import bristol1 from '../../assets/img/design-department/hotels/bristol/bristol-1.png';
import bristol2 from '../../assets/img/design-department/hotels/bristol/bristol-2.png';
import bristol3 from '../../assets/img/design-department/hotels/bristol/bristol-3.png';
import bristol4 from '../../assets/img/design-department/hotels/bristol/bristol-4.png';

import hotelSevenCover from '../../assets/img/design-department/hotels/cover/hotel-seven-cover.png';
import hotelSeven1 from '../../assets/img/design-department/hotels/hotel-seven/hotel-seven-1.png';
import hotelSeven2 from '../../assets/img/design-department/hotels/hotel-seven/hotel-seven-2.png';
import hotelSeven3 from '../../assets/img/design-department/hotels/hotel-seven/hotel-seven-3.png';
import hotelSeven4 from '../../assets/img/design-department/hotels/hotel-seven/hotel-seven-4.png';

import lunaLibreCover from '../../assets/img/design-department/hotels/cover/luna-libre-cover.png';
import lunaLibre1 from '../../assets/img/design-department/hotels/luna-libre/luna-libre-1.png';
import lunaLibre2 from '../../assets/img/design-department/hotels/luna-libre/luna-libre-2.png';
import lunaLibre3 from '../../assets/img/design-department/hotels/luna-libre/luna-libre-3.png';
import lunaLibre4 from '../../assets/img/design-department/hotels/luna-libre/luna-libre-4.png';
import lunaLibre5 from '../../assets/img/design-department/hotels/luna-libre/luna-libre-5.png';

import blueKotorBay2Cover from '../../assets/img/design-department/hotels/cover/blue-kotor-bay-2-cover.png';
import blueKotorBay21 from '../../assets/img/design-department/hotels/blue-kotor-bay-2/blue-kotor-bay-2-1.png';
import blueKotorBay22 from '../../assets/img/design-department/hotels/blue-kotor-bay-2/blue-kotor-bay-2-2.png';
import blueKotorBay23 from '../../assets/img/design-department/hotels/blue-kotor-bay-2/blue-kotor-bay-2-3.png';
import blueKotorBay24 from '../../assets/img/design-department/hotels/blue-kotor-bay-2/blue-kotor-bay-2-4.png';
import blueKotorBay25 from '../../assets/img/design-department/hotels/blue-kotor-bay-2/blue-kotor-bay-2-5.png';

import countrySideCover from '../../assets/img/design-department/hotels/cover/country-side-cover.png';
import countrySide1 from '../../assets/img/design-department/hotels/country-side/country-side-1.png';
import countrySide2 from '../../assets/img/design-department/hotels/country-side/country-side-2.png';
import countrySide3 from '../../assets/img/design-department/hotels/country-side/country-side-3.png';
import countrySide4 from '../../assets/img/design-department/hotels/country-side/country-side-4.png';
import countrySide5 from '../../assets/img/design-department/hotels/country-side/country-side-5.png';

const HotelData = [
  {
    title: 'BLUE KOTOR BAY',
    link: 'blue-kotor-bay',
    cover: blueKotorBayCover,
    subInfo: 'Kotor, Montenegro 10,250 m² Realized in 2020',
    info: 'Set in Kotor, a few steps from Markov Rt Beach, Blue Kotor Bay Premium Resort offers accommodation with a restaurant, free private parking, a fitness centre and a bar. Among the various facilities are a shared lounge, a garden, as well as a private beach area. The hotel provides an indoor pool, hot tub, evening entertainment and a 24-hour front desk.',
    images: [
      blueKotorBay1,
      blueKotorBay2,
      blueKotorBay3,
      blueKotorBay4,
      blueKotorBay5,
    ],
  },
  {
    title: 'BUTUA',
    link: 'butua',
    cover: butuaCover,
    subInfo: 'Budva, Montenegro 8,000 m² Realized in 2019',
    info: `Butua Hotel provides stunning panoramic views from its unique location in the heart of the town of Budva, the center of Montenegrin tourism.
    The hotel features a sleek design complemented by impressive 20th century architecture, including a triangular lobby spanning all six floors. The hotel has 120 rooms and suites.`,
    images: [butua1, butua2, butua3, butua4, butua5],
  },
  {
    title: 'LUNA LIBRE',
    link: 'luna-libre',
    cover: lunaLibreCover,
    subInfo: 'Kotor, Montenegro 10,250 m² Realized in 2020',
    info: `Set in Kotor, a few steps from Markov Rt Beach, Blue Kotor Bay Premium Resort offers accommodation with a restaurant, free private parking, a fitness centre and a bar. Among the various facilities are a shared lounge, a garden, as well as a private beach area. The hotel provides an indoor pool, hot tub, evening entertainment and a 24-hour front desk.`,
    images: [lunaLibre1, lunaLibre2, lunaLibre3, lunaLibre4, lunaLibre5],
  },
  {
    title: 'BLUE KOTOR BAY-2',
    link: 'blue-kotor-bay-2',
    cover: blueKotorBay2Cover,
    subTitle: 'Second Phase',
    subInfo: 'Kotor, Montenegro 10,250 m² Realized in 2020',
    info: 'Set in Kotor, a few steps from Markov Rt Beach, Blue Kotor Bay Premium Resort offers accommodation with a restaurant, free private parking, a fitness centre and a bar. Among the various facilities are a shared lounge, a garden, as well as a private beach area. The hotel provides an indoor pool, hot tub, evening entertainment and a 24-hour front desk.',
    images: [
      blueKotorBay21,
      blueKotorBay22,
      blueKotorBay23,
      blueKotorBay24,
      blueKotorBay25,
    ],
  },
  {
    title: 'MOMENTUM',
    link: 'momentum',
    cover: momentumCover,
    subInfo: 'Budva, Montenegro 2,400 m² Realized in 2019',
    info: `This boutique hotel aims to create the sensation of comfort and luxury in the midst of a glorious forest location.`,
    images: [momentum1, momentum2, momentum3, momentum4, momentum5],
  },
  {
    title: 'WILD BEAUTY',
    link: 'wild-beauty',
    cover: wildBeautyCover,
    subInfo: 'Reževići, Montenegro 2,550 m² Realized in 2019',
    info: `This contemporary hotel featuring simple forms is part of the “Wild Beauty” complex which also includes exclusive villas. Local stone and wood from the area of Reževići were used in order for the complex to be in harmony with its surroundings and not to disturb its simplicity. The hotel also features a spa and an open air swimming pool surrounded by olive trees.`,
    images: [wildBeauty1, wildBeauty2, wildBeauty3, wildBeauty4, wildBeauty5],
  },
  {
    title: 'KIPAS BOUTIQUE',
    link: 'kipas-boutique',
    cover: kipasBoutiqueCover,
    subInfo: 'Kahramanmaraş, Turkey 2,100 m² Realized in 2018',
    info: `The atmosphere of this boutique hotel, designed for special guests and friends of Turkish company Kipas Holding, is intended to convey a sense of being at home. Besides seven bedrooms and suites, the hotel features a restaurant with a living room, a kitchen and a spa, including a gym and swimming pool, all with a beautiful view of the lake in Kahramanmaraş. `,
    images: [
      kipasBoutique1,
      kipasBoutique2,
      kipasBoutique3,
      kipasBoutique4,
      kipasBoutique5,
    ],
  },
  {
    title: 'HOLIDAY INN / TUZLA',
    link: 'holiday-inn-tuzla',
    cover: holidayInnTuzlaCover,
    subInfo: 'Tuzla, Istanbul, Turkey 8,000 m² Realized in 2016',
    info: `The design of this hotel in the Tuzla area of Istanbul was inspired by Holiday Inn’s new approach to their hotels – they are intended to feel more like a home than a hotel.
    We aimed to create a warm and inviting space with familiar yet modern touches, a space that is young, energetic and open.`,
    images: [
      holidayInnTuzla1,
      holidayInnTuzla2,
      holidayInnTuzla3,
      holidayInnTuzla4,
      holidayInnTuzla5,
    ],
  },
  {
    title: 'RADISSON',
    link: 'radisson',
    cover: radissonCover,
    subInfo: 'Qurayyat, Saudi Arabia 6,000 m² Realized in 2018',
    info: `This five-star hotel in the Saudi city of Qurayyat, covering a total of 6,000 m2, has an electric, exciting, and inviting ambiance.
    It features bold and innovative lobbies, fashion-inspired guest rooms, and flexible, inspirational public spaces. `,
    images: [radisson1, radisson2, radisson3, radisson4, radisson5],
  },
  {
    title: 'MUMIN HOTEL',
    link: 'mumin-hotel',
    cover: muminCover,
    subInfo: 'Žabljak, Montenegro 1,250 m² Realized in 2019',
    info: `Tucked at the base of Durmitor mountain  in Žabljak village in Montenegro, Hotel Mumin is as stunning as the nature surrounding it.
    Rooms each with incredible mountain views, the hotel structure simply made of wood, glass and steel, takes ‘mountain resort’ to a whole new level.`,
    images: [mumin1, mumin2, mumin3],
  },
  {
    title: 'MAJESTIC BOUTIQUE',
    link: 'majestic-boutique',
    cover: majesticBoutiqueCover,
    subInfo: 'Budva, Montenegro 1,500 m² Realized in 2011',
    info: `Located in the heart of the town of Budva, the center of Montenegrin tourism, Majestic Hotel is a four-star boutique hotel with an individual flare. The hotel was designed in harmony with traditional Mediterranean architecture, combined with a modern approach, eccentric and exclusive. The hotel has 25 rooms, each uniquely designed.`,
    images: [
      majesticBoutique1,
      majesticBoutique2,
      majesticBoutique3,
      majesticBoutique4,
    ],
  },
  {
    title: 'MONTANIA',
    link: 'montania',
    cover: montaniaCover,
    subInfo: 'Mudanya, Turkey 5,000 m² Realized in 2011',
    info: `Located in a historical railway building from the 19th century in the seaside town of Mudanya, Montania Hotel was designed respecting the building’s architectural elements in coordination with the Turkish Ministry of Culture. Each room’s unique design is inspired by a different railway station from around the world.`,
    images: [montania1, montania2, montania3, montania4],
  },
  {
    title: 'AVANTGARDE',
    link: 'avantgarde',
    cover: avantgardeCover,
    subInfo: 'Budva, Montenegro 7,500 m² Realized in 2019',
    info: `The avant-garde are people or works that are experimental, radical, or unorthodox with respect to art, culture, or society.
    This hotel overlooking the beautiful panoramic view Budva seacoast created by combining contemporary with traditional aspects.`,
    images: [avantgarde1, avantgarde2, avantgarde3, avantgarde4, avantgarde5],
  },
  {
    title: 'HILTON GARDEN INN',
    link: 'hilton-garden-inn',
    cover: hiltonGardenInnCover,
    subInfo: 'Izmir, Turkey  10,000 m² Realized in 2017',
    info: `Accessibility, simplicity, open and inviting atmosphere – these are the keywords of the Hilton Garden Inn brand.
    These, combined with the culture of the coastal city of Izmir, resulted in a fresh, open and attractive business hotel design catering to user needs.  `,
    images: [
      hiltonGardenInn1,
      hiltonGardenInn2,
      hiltonGardenInn3,
      hiltonGardenInn4,
    ],
  },
  {
    title: 'HOLIDAY INN BUNGALOV',
    link: 'holiday-inn-bungalov',
    cover: holidayInnBungalovCover,
    subInfo: 'Ulcinj, Montenegro 9,250 m² Realized in 2020',
    info: `The avant-garde are people or works that are experimental, radical, or unorthodox with respect to art, culture, or society.
    This hotel overlooking the beautiful panoramic view Budva seacoast created by combining contemporary with traditional aspects.`,
    images: [
      holidayInnBungalov1,
      holidayInnBungalov2,
      holidayInnBungalov3,
      holidayInnBungalov4,
    ],
  },
  {
    title: 'BRISTOL',
    link: 'bristol',
    cover: bristolCover,
    subInfo: 'Podgorica, Montenegro 4,250 m² Realized in 2020',
    info: `Boutique hotel's intimate size produces its one-on-one five stat hospitality and its heady ambiance. A boutique hotel strives to be one-of-a-kind.
    Whether it's independently owned or a member of a luxury hotel brand or association, it has an independent attitude and works hard to not feel like a corporate hotel.`,
    images: [bristol1, bristol2, bristol3, bristol4],
  },
  {
    title: 'HOTEL SEVEN',
    link: 'hotel-seven',
    cover: hotelSevenCover,
    subInfo: 'Zrenjanin, Serbia 750 m² Realized in 2020',
    info: `This type of lodging facility gains its uniqueness through aesthetics, atmosphere, and a level of personalized service that just can’t be found in larger establishments.
    Therefore, the size is another important feature of a boutique hotel, and bigger is not necessarily better.`,
    images: [hotelSeven1, hotelSeven2, hotelSeven3, hotelSeven4],
  },
  {
    title: 'COUNTRY SIDE',
    link: 'country-side',
    cover: countrySideCover,
    subInfo: 'Kemalpaşa, İzmir 5,172 m² Realized in 2021',
    info: `Set in Kotor, a few steps from Markov Rt Beach, Blue Kotor Bay Premium Resort offers accommodation with a restaurant, free private parking, a fitness centre and a bar. Among the various facilities are a shared lounge, a garden, as well as a private beach area. The hotel provides an indoor pool, hot tub, evening entertainment and a 24-hour front desk.`,
    images: [
      countrySide1,
      countrySide2,
      countrySide3,
      countrySide4,
      countrySide5,
    ],
  },
];

export default HotelData;
