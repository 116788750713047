import romeoJulietCover from '../../assets/img/design-department/restaurant-cafe/cover/romeo-juliet-cover.png';
import romeoJuliet1 from '../../assets/img/design-department/restaurant-cafe/romeo-juliet/romeo-juliet-1.png';
import romeoJuliet2 from '../../assets/img/design-department/restaurant-cafe/romeo-juliet/romeo-juliet-2.png';
import romeoJuliet3 from '../../assets/img/design-department/restaurant-cafe/romeo-juliet/romeo-juliet-3.png';
import romeoJuliet4 from '../../assets/img/design-department/restaurant-cafe/romeo-juliet/romeo-juliet-4.png';
import romeoJuliet5 from '../../assets/img/design-department/restaurant-cafe/romeo-juliet/romeo-juliet-5.png';

import soulBySamaCover from '../../assets/img/design-department/restaurant-cafe/cover/soul-by-sama-cover.png';
import soulBySama1 from '../../assets/img/design-department/restaurant-cafe/soul-by-sama/soul-by-sama-1.png';
import soulBySama2 from '../../assets/img/design-department/restaurant-cafe/soul-by-sama/soul-by-sama-2.png';
import soulBySama3 from '../../assets/img/design-department/restaurant-cafe/soul-by-sama/soul-by-sama-3.png';
import soulBySama4 from '../../assets/img/design-department/restaurant-cafe/soul-by-sama/soul-by-sama-4.png';
import soulBySama5 from '../../assets/img/design-department/restaurant-cafe/soul-by-sama/soul-by-sama-5.png';

import asanteCover from '../../assets/img/design-department/restaurant-cafe/cover/asante-cover.png';
import asante1 from '../../assets/img/design-department/restaurant-cafe/asante/asante-1.png';
import asante2 from '../../assets/img/design-department/restaurant-cafe/asante/asante-2.png';
import asante3 from '../../assets/img/design-department/restaurant-cafe/asante/asante-3.png';
import asante4 from '../../assets/img/design-department/restaurant-cafe/asante/asante-4.png';
import asante5 from '../../assets/img/design-department/restaurant-cafe/asante/asante-5.png';

import cocoBarCover from '../../assets/img/design-department/restaurant-cafe/cover/coco-bar-cover.png';
import cocoBar1 from '../../assets/img/design-department/restaurant-cafe/coco-bar/coco-bar-1.png';
import cocoBar2 from '../../assets/img/design-department/restaurant-cafe/coco-bar/coco-bar-2.png';
import cocoBar3 from '../../assets/img/design-department/restaurant-cafe/coco-bar/coco-bar-3.png';
import cocoBar4 from '../../assets/img/design-department/restaurant-cafe/coco-bar/coco-bar-4.png';
import cocoBar5 from '../../assets/img/design-department/restaurant-cafe/coco-bar/coco-bar-5.png';

import gurkanChefRiyadhCover from '../../assets/img/design-department/restaurant-cafe/cover/gurkan-chef-riyadh-cover.png';
import gurkanChefRiyadh1 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-riyadh/gurkan-chef-riyadh-1.png';
import gurkanChefRiyadh2 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-riyadh/gurkan-chef-riyadh-2.png';
import gurkanChefRiyadh3 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-riyadh/gurkan-chef-riyadh-3.png';
import gurkanChefRiyadh4 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-riyadh/gurkan-chef-riyadh-4.png';
import gurkanChefRiyadh5 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-riyadh/gurkan-chef-riyadh-5.png';

import gurkanChefJeddahCover from '../../assets/img/design-department/restaurant-cafe/cover/gurkan-chef-jeddah-cover.png';
import gurkanChefJeddah1 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-jeddah/gurkan-chef-jeddah-1.png';
import gurkanChefJeddah2 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-jeddah/gurkan-chef-jeddah-2.png';
import gurkanChefJeddah3 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-jeddah/gurkan-chef-jeddah-3.png';
import gurkanChefJeddah4 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-jeddah/gurkan-chef-jeddah-4.png';
import gurkanChefJeddah5 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-jeddah/gurkan-chef-jeddah-5.png';

import gurkanChefEtilerCover from '../../assets/img/design-department/restaurant-cafe/cover/gurkan-chef-etiler-cover.png';
import gurkanChefEtiler1 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-etiler/gurkan-chef-etiler-1.png';
import gurkanChefEtiler2 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-etiler/gurkan-chef-etiler-2.png';
import gurkanChefEtiler3 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-etiler/gurkan-chef-etiler-3.png';
import gurkanChefEtiler4 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-etiler/gurkan-chef-etiler-4.png';
import gurkanChefEtiler5 from '../../assets/img/design-department/restaurant-cafe/gurkan-chef-etiler/gurkan-chef-etiler-5.png';

import pauseCover from '../../assets/img/design-department/restaurant-cafe/cover/pause-cover.png';
import pause1 from '../../assets/img/design-department/restaurant-cafe/pause/pause-1.png';
import pause2 from '../../assets/img/design-department/restaurant-cafe/pause/pause-2.png';
import pause3 from '../../assets/img/design-department/restaurant-cafe/pause/pause-3.png';
import pause4 from '../../assets/img/design-department/restaurant-cafe/pause/pause-4.png';
import pause5 from '../../assets/img/design-department/restaurant-cafe/pause/pause-5.png';

import loungeBarPalmaCover from '../../assets/img/design-department/restaurant-cafe/cover/lounge-bar-palma-cover.png';
import loungeBarPalma1 from '../../assets/img/design-department/restaurant-cafe/lounge-bar-palma/lounge-bar-palma-1.png';
import loungeBarPalma2 from '../../assets/img/design-department/restaurant-cafe/lounge-bar-palma/lounge-bar-palma-2.png';
import loungeBarPalma3 from '../../assets/img/design-department/restaurant-cafe/lounge-bar-palma/lounge-bar-palma-3.png';
import loungeBarPalma4 from '../../assets/img/design-department/restaurant-cafe/lounge-bar-palma/lounge-bar-palma-4.png';
import loungeBarPalma5 from '../../assets/img/design-department/restaurant-cafe/lounge-bar-palma/lounge-bar-palma-5.png';

const RestaurantCafeData = [
  {
    title: 'SOUL BY SAMA',
    link: 'soul-by-sama',
    cover: soulBySamaCover,
    subInfo: 'Jeddah, Saudi Arabia - Realized in 2021 Area : 650 m2',
    info: `The restaurant is divided into the café area and the more intimate restaurant area.`,
    images: [soulBySama1, soulBySama2, soulBySama3, soulBySama4, soulBySama5],
  },
  {
    title: 'ROMEO & JULİET',
    link: 'romeo-juliet',
    cover: romeoJulietCover,
    subInfo: 'Herceg Novi, Montenegro Realized in 2021 Area : 250 m2',
    info: 'The restaurant is divided into the café area and the more intimate restaurant area.',
    images: [
      romeoJuliet1,
      romeoJuliet2,
      romeoJuliet3,
      romeoJuliet4,
      romeoJuliet5,
    ],
  },
  {
    title: 'ASANTE CAFE',
    link: 'asante-cafe',
    cover: asanteCover,
    subInfo: 'Mecca, Saudi Arabia 375 m² Realized in 2018',
    info: `Café Asante is a new café brand, intended to be developed into a chain throughout Saudi Arabia, the United Arab Emirates, Kuwait, Qatar, Bahrain, and Oman.
    As the café is intended for a young audience, the interior design aims to reflect that with the use of black, wood, and comfortable seating,
    as well as an air conditioned terrace which can be used throughout the year, even during extremely hot summer days of Mecca’s desert climate. `,
    images: [asante1, asante2, asante3, asante4, asante5],
  },
  {
    title: 'COCO BAR',
    link: 'coco-bar',
    cover: cocoBarCover,
    subInfo: 'Budva, Montenegro 425 m² Realized in 2020',
    info: `Low-tempo music, dim lights, and warm colors all invite customers to relax, linger, and order dessert, coffee, or an extra glass of wine.
    This behavior is supported even further with seats that will keep patrons supported and comfortable over a long period of sitting.`,
    images: [cocoBar1, cocoBar2, cocoBar3, cocoBar4, cocoBar5],
  },
  {
    title: 'GÜRKAN CHEF / RIYADH',
    link: 'gurkan-chef-riyadh',
    cover: gurkanChefRiyadhCover,
    subInfo: 'Riyadh, Saudi Arabia 1,250 m² Realized in 2019',
    info: `Another steakhouse in the Gürkan Chef chain, this two-floor restaurant is a continuation of the concept used in the Istanbul steakhouse, realized using wood, bronze metals, stone and a lot of greenery. The novelty in this restaurant are ropes, used in different elements of the interior.`,
    images: [
      gurkanChefRiyadh1,
      gurkanChefRiyadh2,
      gurkanChefRiyadh3,
      gurkanChefRiyadh4,
      gurkanChefRiyadh5,
    ],
  },
  {
    title: 'GÜRKAN CHEF / JEDDAH',
    link: 'gurkan-chef-jeddah',
    cover: gurkanChefJeddahCover,
    subInfo: 'Jeddah, Saudi Arabia Realized in 2021 Area: 1,150m2',
    info: `Another steakhouse in the Gürkan Chef chain, this two-floor restaurant is a continuation of the concept used in the Istanbul steakhouse, realized using wood, bronze metals, stone and a lot of greenery. The novelty in this restaurant are ropes, used in different elements of the interior.`,
    images: [
      gurkanChefJeddah1,
      gurkanChefJeddah2,
      gurkanChefJeddah3,
      gurkanChefJeddah4,
      gurkanChefJeddah5,
    ],
  },
  {
    title: 'GÜRKAN CHEF / ETİLER',
    link: 'gurkan-chef-etiler',
    cover: gurkanChefEtilerCover,
    subInfo: 'Istanbul, Turkey 1,250 m² Realized in 2017',
    info: `Gürkan Chef, one of the most successful celebrity chefs in Istanbul, manages premium quality steakhouse chains around the world. The design concept honors fire and the craft of grilling by using bronze and metal, while organic materials like vegetation, wood and water make guests feel comfortable in this luxurious space. `,
    images: [
      gurkanChefEtiler1,
      gurkanChefEtiler2,
      gurkanChefEtiler3,
      gurkanChefEtiler4,
      gurkanChefEtiler5,
    ],
  },
  {
    title: 'PAUSE',
    link: 'pause',
    cover: pauseCover,
    subInfo: 'Novi Sad, Serbia 400 m² Realized in 2018',
    info: `This restaurant and café in Novi Sad city center was designed in a contemporary style, incorporating the existing concrete surfaces and enriching them with wood,
    blue and green fabrics, and lots of greenery. The restaurant is divided into the café area and the more intimate restaurant area. `,
    images: [pause1, pause2, pause3, pause4, pause5],
  },
  {
    title: 'LOUNGE BAR PALMA',
    link: 'lounge-bar-palma',
    cover: loungeBarPalmaCover,
    subInfo: 'Budva, Montenegro 425 m² Realized in 2020',
    info: `Low-tempo music, dim lights, and warm colors all invite customers to relax, linger, and order dessert, coffee, or an extra glass of wine.
    This behavior is supported even further with seats that will keep patrons supported and comfortable over a long period of sitting.`,
    images: [
      loungeBarPalma1,
      loungeBarPalma2,
      loungeBarPalma3,
      loungeBarPalma4,
      loungeBarPalma5,
    ],
  },
];

export default RestaurantCafeData;
