// STORAGES

import Storage1 from '../../assets/img/trade-department/products/contract/hotel/rooms/storages/storages-1.png';
import Storage2 from '../../assets/img/trade-department/products/contract/hotel/rooms/storages/storages-2.png';
import Storage3 from '../../assets/img/trade-department/products/contract/hotel/rooms/storages/storages-3.png';
import Storage4 from '../../assets/img/trade-department/products/contract/hotel/rooms/storages/storages-4.png';
import Storage5 from '../../assets/img/trade-department/products/contract/hotel/rooms/storages/storages-5.png';

// TABLES CHAIRS

//TABLE-LEG
import TableLeg1 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-leg-1.png';
import TableLeg2 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-leg-2.png';
import TableLeg3 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-leg-3.png';
import TableLeg4 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-leg-4.png';
import TableLeg5 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-leg-5.png';

//TABLE-TOP
import TableTop1 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-1.png';
import TableTop2 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-2.png';
import TableTop3 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-3.png';
import TableTop4 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-4.png';
import TableTop5 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-5.png';
import TableTop6 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-6.png';
import TableTop7 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-7.png';
import TableTop8 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-8.png';
import TableTop9 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-9.png';
import TableTop10 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-10.png';
import TableTop11 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-11.png';
import TableTop12 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-12.png';
import TableTop13 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-13.png';
import TableTop14 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-14.png';
import TableTop15 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-15.png';
import TableTop16 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-16.png';
import TableTop17 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-17.png';
import TableTop18 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-18.png';
import TableTop19 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-19.png';
import TableTop20 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-20.png';
import TableTop21 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/table-top-21.png';

//CHAIRS
import Chairs1 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/chairs-1.png';
import Chairs2 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/chairs-2.png';
import Chairs3 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/chairs-3.png';
import Chairs4 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/chairs-4.png';
import Chairs5 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/chairs-5.png';
import Chairs6 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/chairs-6.png';
import Chairs7 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/chairs-7.png';
import Chairs8 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/chairs-8.png';
import Chairs9 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/chairs-9.png';

//BAR-CHAIRS
import BarChairs1 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/bar-chairs-1.png';
import BarChairs2 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/bar-chairs-2.png';
import BarChairs3 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/bar-chairs-3.png';
import BarChairs4 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/bar-chairs-4.png';
import BarChairs5 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/bar-chairs-5.png';
import BarChairs6 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/bar-chairs-6.png';

//BAR-CHAIRS
import CoffeeTable1 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/coffee-table-1.png';
import CoffeeTable2 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/coffee-table-2.png';
import CoffeeTable3 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/coffee-table-3.png';
import CoffeeTable4 from '../../assets/img/trade-department/products/contract/hotel/rooms/tables-chairs/coffee-table-4.png';

// BED BASE

import lb4301 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4301.png';
import lb4302 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4302.png';
import lb4303 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4303.png';
import lb4304 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4304.png';
import lb4305 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4305.png';
import lb4306 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4306.png';
import lb4307 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4307.png';
import lb4308 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4308.png';
import lb4309 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4309.png';
import lb43012 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4301-2.png';
import lb43022 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4302-2.png';
import lb43032 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4303-2.png';

import lb4201 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4201.png';
import lb4202 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4202.png';
import lb4203 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4203.png';
import lb4204 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4204.png';
import lb4205 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4205.png';
import lb4206 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4206.png';
import lb4207 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4207.png';
import lb4208 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4208.png';
import lb4209 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4209.png';
import lb4210 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4210.png';
import lb4211 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4211.png';

import lb4101 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4101.png';
import lb4102 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4102.png';
import lb4103 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4103.png';
import lb41012 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4101-2.png';
import lb41022 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4102-2.png';
import lb41032 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4103-2.png';
import lb41013 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4101-3.png';
import lb41023 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4102-3.png';
import lb41033 from '../../assets/img/trade-department/products/contract/hotel/rooms/beds/lb4103-3.png';

// EQUIPMENTS

import lnf2151 from '../../assets/img/trade-department/products/contract/hotel/rooms/equipments/lnf2151.png';
import lnf2152 from '../../assets/img/trade-department/products/contract/hotel/rooms/equipments/lnf2152.png';
import lnf2153 from '../../assets/img/trade-department/products/contract/hotel/rooms/equipments/lnf2153.png';
import lnf2154 from '../../assets/img/trade-department/products/contract/hotel/rooms/equipments/lnf2154.png';
import truva2012 from '../../assets/img/trade-department/products/contract/hotel/rooms/equipments/truva2012.png';
import ksk210 from '../../assets/img/trade-department/products/contract/hotel/rooms/equipments/ksk210.png';

import ttime36741 from '../../assets/img/trade-department/products/contract/hotel/rooms/equipments/t-time3674-1.png';
import ttime36742 from '../../assets/img/trade-department/products/contract/hotel/rooms/equipments/t-time3674-2.png';
import ttime36743 from '../../assets/img/trade-department/products/contract/hotel/rooms/equipments/t-time3674-3.png';

import sink1075021 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1075-021.png';
import sink141900 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1419-00.png';
import sink147801 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1478-01.png';
import sink111200 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1112-00.png';
import sink137602 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1376-02.png';
import sink100600 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1006-00.png';
import sink138401 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1384-01.png';
import sink1419002 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1419-002.png';

import closet141900 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1419-00.png';
import closet141601 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1416-01.png';
import closet137502 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1375-02.png';
import closet101702 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1017-02.png';

import rear138301 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1381-01-rear-water-inlet.png';
import photocellBattery138301 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1383-01-photocell-battery.png';
import photocellElectric138301 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1383-01-photocell-electric.png';
import rear1383012 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/1383-01-rear-water-inlet-2.png';

import acrylic32002054 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/3200-2054.png';
import acrylic32002012 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/3200-2012.png';
import acrylic32002033 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/3205-2033.png';

import furniture700613 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/7006-13.png';
import furniture700608 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/7006-08.png';
import furniture700413 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/7004-13.png';
import furniture700412 from '../../assets/img/trade-department/products/contract/hotel/rooms/bathroom/7004-12.png';

const HotelStorageData = () => {
  return [Storage1, Storage2, Storage3, Storage4, Storage5];
};

const HotelTablesChairsData = value => {
  if (value === 'table-leg') {
    return [TableLeg1, TableLeg2, TableLeg3, TableLeg4, TableLeg5];
  } else if (value === 'table-top') {
    return [
      TableTop1,
      TableTop2,
      TableTop3,
      TableTop4,
      TableTop5,
      TableTop6,
      TableTop7,
      TableTop8,
      TableTop9,
      TableTop10,
      TableTop11,
      TableTop12,
      TableTop13,
      TableTop14,
      TableTop15,
      TableTop16,
      TableTop17,
      TableTop18,
      TableTop19,
      TableTop20,
      TableTop21,
    ];
  } else if (value === 'chairs') {
    return [
      Chairs1,
      Chairs2,
      Chairs3,
      Chairs4,
      Chairs5,
      Chairs6,
      Chairs7,
      Chairs8,
      Chairs9,
    ];
  } else if (value === 'bar-chairs') {
    return [
      BarChairs1,
      BarChairs2,
      BarChairs3,
      BarChairs4,
      BarChairs5,
      BarChairs6,
    ];
  } else if (value === 'coffee-table') {
    return [CoffeeTable1, CoffeeTable2, CoffeeTable3, CoffeeTable4];
  }
};

const HotelBedData = value => {
  if (value === 'bed-base') {
    return [
      { cover: lb4301, title: 'LB-4301' },
      { cover: lb4302, title: 'LB-4302' },
      { cover: lb4303, title: 'LB-4303' },
      { cover: lb4304, title: 'LB-4304' },
      { cover: lb4305, title: 'LB-4305' },
      { cover: lb4306, title: 'LB-4306' },
      { cover: lb4307, title: 'LB-4307' },
      { cover: lb4308, title: 'LB-4308' },
      { cover: lb4309, title: 'LB-4309' },
      { cover: lb43012, title: 'LB-4301' },
      { cover: lb43022, title: 'LB-4302' },
      { cover: lb43032, title: 'LB-4303' },
    ];
  } else if (value === 'bed') {
    return [
      {
        cover: lb4201,
        title: 'LB-4201',
        info: `Its special sponge filling and voluminous structure and the high-weight viscose fabric used on the mattress create an ergonomic surface, while providing ideal sleep temperature with its dehumidification feature.`,
        hardness: `Medium, Hard`,
        weight: `60-85`,
        position: `Moving, Prone`,
      },
      {
        cover: lb4202,
        title: 'LB-4202',
        info: `The natural sleep movements that occur with the support and comfort provided by the pocket springs to your body during a good night's sleep allow the muscles to relax. The extra comfort-enhancing pad layer used in the upper layer, soft-textured knitted fabric and voluminous structure provide a feeling of sleep above the clouds.`,
        hardness: `Very Soft, Soft`,
        weight: `50-70, 50-75`,
        position: `Moving, Side`,
      },
      {
        cover: lb4203,
        title: 'LB-4203',
        info: `It supports your body from every point with its open-cell visco sponge filling, a high comfortable sleeping pad and the latest technology pocket spring system. It helps to reduce involuntary movements and joint pain during the night.`,
        hardness: `Hard`,
        weight: `70-110+`,
        position: `Supine, Prone`,
      },
      {
        cover: lb4204,
        title: 'LB-4204',
        info: ``,
        hardness: `Medium, Hard`,
        weight: `60-90, 70-90`,
        position: `Backstroke, Side`,
      },
      {
        cover: lb4205,
        title: 'LB-4205',
        info: `Jumbo jaker fabric used on the bed prevents the formation of bad odor. The pocket spring system, which moves independently of each other, maintains its first-day form for years with the soft sponge and filling material used in the mattress. The right choice for those looking for an economical and long-lasting mattress`,
        hardness: `Hard`,
        weight: `60-85, 60-90`,
        position: `Backstroke, Side`,
      },
      {
        cover: lb4206,
        title: 'LB-4206',
        info: `The high-density top layer used in the design of the bed increases the sleep quality and ensures that the bed is used for years without deformation. Its special quilting and full viscose fabric prevent bacteria formation by providing continuous air circulation.`,
        hardness: `Medium, Hard`,
        weight: `60-85, 60-90`,
        position: `Moving, Prone`,
      },
      {
        cover: lb4207,
        title: 'LB-4207',
        info: `The mattress, in which elastic memory foam and pocket spring technology are used together, has excellent pressure damping and support feature specially for the most sensitive area of the spine.`,
        hardness: `Soft`,
        weight: `60-85`,
        position: `Moving, Supine`,
      },
      {
        cover: lb4208,
        title: 'LB-4208',
        info: `Specially produced for users who like medium-hard mattresses, the mattress provides a dynamic start to the day with its high-weight knit fabric and soft sponge.`,
        hardness: `Very Hard, Hard`,
        weight: `70-110+`,
        position: `Mobile, Supine`,
      },
      {
        cover: lb4209,
        title: 'LB-4209',
        info: `It is designed for those who want to start the day fresh and experience the highest level of sleep.`,
        hardness: `Medium Hard`,
        weight: `60-85, 60-90`,
        position: `Moving, Side`,
      },
      {
        cover: lb4210,
        title: 'LB-4210',
        info: `It has high-end mattress technology specially designed for the person and has the ability to take shape according to the body.`,
        hardness: `Very Soft, Soft`,
        weight: `70-90, 75-90`,
        position: `Side, Prone`,
      },
      {
        cover: lb4211,
        title: 'LB-4211',
        info: `The shape of your body is in the memory of your mattress. The 8 cm visco sponge used in the mattress minimizes the pressure on the body.`,
        hardness: `Hard`,
        weight: `70-90, 75-90`,
        position: `Supine, Side, Prone`,
      },
    ];
  } else if (value === 'headboard') {
    return [
      { cover: lb4101, title: 'LB-4101' },
      { cover: lb4102, title: 'LB-4102' },
      { cover: lb4103, title: 'LB-4103' },

      { cover: lb41012, title: 'LB-4101' },
      { cover: lb41022, title: 'LB-4102' },
      { cover: lb41032, title: 'LB-4103' },

      { cover: lb41013, title: 'LB-4101' },
      { cover: lb41023, title: 'LB-4102' },
      { cover: lb41033, title: 'LB-4103' },
    ];
  }
};

const HotelEquipmentsData = value => {
  if (value === 'equipments') {
    return [
      {
        title: 'LNF-2151',
        cover: lnf2151,
        cooling: '42',
        dimension: '425 x 440 x 565',
        ambient: '+16 / +32 ˚C',
        storage: '+4 / +14 ˚C',
        refrigerant: 'NH3 + H2O',
        amount: 'less than1,82 g/L',
        energy: '0,95',
        electric: '75',
        voltage: '220-240 / 50-60',
        relative: '65',
        noise: '0',
        weight: '14',
      },
      {
        title: 'LNF-2152',
        cover: lnf2152,
        cooling: '32',
        dimension: '382 x 425 x 510',
        ambient: '+16 / +32 ˚C',
        storage: '+5 / +14 ˚C',
        refrigerant: 'NH3 + H2O',
        amount: 'less than1,82 g/L',
        energy: '0,9',
        electric: '75',
        voltage: '220-240 / 50-60',
        relative: '65',
        noise: '0',
        weight: '14,5',
      },
      {
        title: 'LNF-2153',
        cover: lnf2153,
        cooling: '15',
        dimension: '440 x 190 x 780',
        ambient: '+32 ˚C',
        storage: '+2 / +8 ˚C',
        refrigerant: 'R600',
        amount: '20',
        energy: '0,29',
        electric: '',
        voltage: '220-240 / 50',
        relative: '33',
        noise: '38 / 42',
        weight: '20',
      },
      {
        title: 'LNF-2154',
        cover: lnf2154,
        cooling: '20',
        dimension: '426 x 406 x 436',
        ambient: '+16 / +32 ˚C',
        storage: '+2 / +8 ˚C',
        refrigerant: 'R600',
        amount: '20',
        energy: '0,19 A++',
        electric: '',
        voltage: '220-240 /50',
        relative: '18',
        noise: '38 / 42',
        weight: '15,5',
      },
    ];
  } else if (value === 'equipments-2') {
    return [
      {
        title: 'TRUVA-2012',
        cover: truva2012,
        info: [
          ` • Dimensions :`,
          ` 225mm (h) x 400mm (w) x 455mm (d) `,
          ` • 4-6 digit code system `,
          ` • Power display status`,
          ` • Motorized System `,
          ` • Emergency master key, hand terminal `,
          ` • Black, Beige, Brown and Grey colour`,
          ` • Last 100 opening report `,
          `• 4 pcs 1.5V alkaline battery`,
          `• Batteries placed in door of the safe.`,
          `• Time and date setting`,
          `• Time delay before opening`,
          `• Warranty: 3 years / Made in Türkiye `,
        ],
      },
      {
        title: 'KSK-210',
        cover: ksk210,
        info: [
          `• 1 -1,2 L capacity, stainless steel `,
          `• 1.785 Watt (220-240 volt / 50-60 Hz)`,
          `• Automatic opening button`,
          `• Covered heating element (Otter Brand - Italy) `,
          `• Cordless with safety wire for hotel use`,
          `• Stainless steel flat bottom`,
          `• Rotation 360°, blue light indicator `,
          `• Auto steam shut off, boil dry`,
          `  protection Washable lime filter  `,
          `• Warranty : 2 years`,
          `• ORIGIN : P.R.C.`,
        ],
      },
    ];
  } else if (value === 't-time') {
    return [ttime36741, ttime36742, ttime36743];
  }
};

const BathroomData = value => {
  if (value === 'sink') {
    return [
      sink1075021,
      sink141900,
      sink147801,
      sink111200,
      sink137602,
      sink100600,
      sink138401,
      sink1419002,
    ];
  } else if (value === 'closet') {
    return [closet141900, closet141601, closet137502, closet101702];
  } else if (value === 'urinal') {
    return [
      rear138301,
      photocellBattery138301,
      photocellElectric138301,
      rear1383012,
    ];
  } else if (value === 'acrylic') {
    return [acrylic32002054, acrylic32002012, acrylic32002033];
  } else if (value === 'furniture') {
    return [furniture700613, furniture700608, furniture700413, furniture700412];
  }
};

export {
  HotelStorageData,
  HotelTablesChairsData,
  HotelBedData,
  HotelEquipmentsData,
  BathroomData,
};
